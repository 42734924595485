'use strict';

angular.module('kerp-forms.forms')
  .service('ESA1_MAPPINGS', ['FormFieldMappings', function (FormFieldMappings) {

    var fact2FormEmploymentStatuses = {
      'Yes': 'Yes, please tell us about this below.',
      'No': 'no, please send us your P45. Go to Part 6 About other benefits.'
    };

    this.mappings = function () {
      return FormFieldMappings.buildWithMappings({
        '6_1': 'lastName',
        '6_2': 'firstName',
        '6_4': 'title',
        '6_6': 'dateOfBirth',
        '6_7': 'nationalInsuranceNumber',
        '6_8': 'address.formatted',
        '6_10': 'phoneNumber',
        '6_12': 'alternativePhoneNumber',
        '7_4': 'maritalStatus',
        '7_5': 'maritalStatus',
        '7_6': 'maritalStatus',
        '7_7': 'maritalStatus',
        '7_8': 'maritalStatus',
        '7_9': 'maritalStatus',
        '11_1': 'income',
        '11_5': 'income',
        '11_6': 'income',
        '11_9': 'income'
      }).factToFieldConverters({
        '6_6': function (facts) {
          return this.ISOStringToDDMMYYYY(facts.dateOfBirth);
        },
        '7_4': function (facts) {
          if (facts.maritalStatus) {
            return !!(facts.maritalStatus.match(/married/gi));
          }
        },
        '7_5': function (facts) {
          if (facts.maritalStatus) {
            return !!(facts.maritalStatus.match(/divorced/gi));
          }
        },
        '7_6': function (facts) {
          if (facts.maritalStatus) {
            return !!(facts.maritalStatus.match(/single/gi));
          }
        },
        '7_7': function (facts) {
          if (facts.maritalStatus) {
            return !!(facts.maritalStatus.match(/separated/gi));
          }
        },
        '7_8': function (facts) {
          if (facts.maritalStatus) {
            return !!(facts.maritalStatus.match(/together/gi));
          }
        },
        '7_9': function (facts) {
          if (facts.maritalStatus) {
            return !!(facts.maritalStatus.match(/widowed/gi));
          }
        },
        '11_1': function (facts) {
          return fact2FormEmploymentStatuses[facts.income.employmentStatus];
        },
        '11_5': function (facts) {
          return this.stringToNumber(facts.income.employerDetails.hoursPerWeek);
        },
        '11_6': function (facts) {
          return this.stringToNumber(facts.income.employerDetails.frequency);
        },
        '11_9': function (facts) {
          return facts.income.employerDetails.name;
        }
      }).fieldToFactConverters({
        dateOfBirth: function (model) {
          return this.DDMMYYYYToISOString(model['6_6']);
        },
        maritalStatus: function (model) {
          var statuses = [];
          if (model['7_4']) {
            statuses.push('married');
          }
          if (model['7_5']) {
            statuses.push('divorced');
          }
          if (model['7_6']) {
            statuses.push('single');
          }
          if (model['7_7']) {
            statuses.push('separated');
          }
          if (model['7_8']) {
            statuses.push('living together');
          }
          if (model['7_9']) {
            statuses.push('widowed');
          }
          return statuses.join(', ');
        },
        income: function (model) {
          var employmentStatus = _.invert(fact2FormEmploymentStatuses)[model['11_1']];
          return {
            employmentStatus: employmentStatus,
            employerDetails: {
              name: model['11_9'],
              hoursPerWeek: model['11_5'],
              frequency: model['11_6']
            }
          };
        }
      });
    };
  }]);
