'use strict';

angular.module('kerp-forms.forms').service(
  'ealingCareAssessmentCalculator',

  [
    '$window',
    'calculationResultService',

    function (
      $window,
      calculationResultService
    ) {

      function saveCalcResults(careType, calcResults, model) {
        calculationResultService.saveCalcResults(careType, calcResults, model);
      }

      var constants = $window.kerpCfa.ealing.constants;

      this.recalculate = function (dirtyModel, cleanModel) {

        var recalculateCareTypes = Object.keys(constants.careTypes);
        var self = this;

        recalculateCareTypes.forEach(function (careType) {
          var calcResults = self.calculate(cleanModel, null, careType);

          saveCalcResults(careType, calcResults, dirtyModel);
          saveCalcResults(careType, calcResults, cleanModel);
        });
      };

      this.calculate = function (fields, isShortForm, careType) {
        return $window.kerpCfa.ealing.calculator(fields, isShortForm, careType);
      };
    }
  ]
);
