'use strict';

angular
  .module('kerp-forms.forms')
  .factory('ESA1_FORM', ['FormUI', 'htmlService', function (FormUI, htmlService) {
    var formUI = new FormUI();

    var esaForm = [
      {
        type: 'section',
        condition: 'model.page === 1',
        page: 1,
        items: [
          {
            type: 'template',
            templateUrl: 'modules/forms/scripts/services/forms/ESA1/templates/intro.html'
          },
          {
            key: '4_1',
            title: 'Do you get any special medical treatment?',
            type: 'radios',
            htmlClass: 'formField',
            description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/ESA1/templates/description4_1.html'),
            titleMap: [{value: 'no', name: 'No'}, {value: 'yes', name: 'Yes'}]
          },
          {
            key: '4_3',
            title: 'Are you employed by an employer, even if you are not working at the moment?',
            type: 'radios',
            description: 'Employment and Support Allowance can be paid whilst you have a contract of employment.',
            htmlClass: 'formField',
            titleMap: [{value: 'No', name: 'No'}, {value: 'Yes', name: 'Yes'}]
          },
          {
            type: 'section',
            condition: 'model["4_3"] === "Yes"',
            items: [
              {
                key: '4_5',
                placeholder: 'eg: 2',
                title: 'If you have more than one job, please tell us how many'
              }
            ]
          },
          {
            key: '4_6',
            title: ' Have any of your employers given you a form SSP1?',
            type: 'radios',
            htmlClass: 'formField',
            description: 'SSP1 is a form your employer may give you if they have been paying you Statutory Sick Pay but this is coming to an end.',
            titleMap: [
              {
                value: 'No - you may be able to get Statutory Sick Pay instread of Employment and Support Allowance. Ask your employer about Statutory Sick Pay before you fill in this form.',
                name: 'No'
              },
              {
                value: 'Yes - you must send us a separate form SSP1 for each job you do along with this form.',
                name: 'Yes'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 2',
        page: 2,
        items: [
          {
            type: 'help',
            htmlClass: 'divTitle text-center',
            helpvalue: '<div><h3 class=\'formPartTitle\'>Part 1: About your claim</h3></div>'
          },
          {
            key: '5_1',
            type: 'kerpdate',
            mode: 'month',
            htmlClass: 'formField',
            title: 'What date do you want to claim Employment and support Allowance from',
            pattern: '/^[0-9]{2}$/',
            required: true,
            validationMessage: 'You have to pick a Allowance start date',
            maxDate: new Date(),
            description: 'Consider putting an earlier date if you believe that you can get a backdated fit/sick note from your GP to cover this period. ESA can be backdated up to three months, if you meet all the entitlement conditions during that time.'
          },
          {
            key: '5_2',
            title: 'Do you want to claim income-related Employment and Support Allowance?',
            type: 'radios',
            htmlClass: 'formField',
            titleMap: [{
              value: 'No- Fill in the answer box under You on pages 2 to 24. Then go to page 45.',
              name: 'No'
            },
              {
                value: 'yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25.',
                name: 'Yes'
              }],
            description: 'Say yes to this if you and your partner are on a low income. This element of Employment Support Allowance is means-tested (relates to the amount of income and savings you have) but can help with mortgage interest payments.'
          },
          {
            key: '5_4',
            title: 'Do you have a partner?',
            type: 'radios',
            htmlClass: 'formField',
            titleMap: [
              {value: 'No, please go to Part 2', name: 'No'},
              {value: 'yes - does your partner, if you have one, agree to you making this claim?', name: 'Yes'}
            ],
            description: '\'partner\' means:<ul><li>a person you live with who is your husband, wife or civil partner</li><li>or a person you live with as if you are a married couple</li></ul>'
          },
          {
            key: '5_5',
            title: 'Does your partner agree to you making this claim?',
            type: 'radios',
            htmlClass: 'formField',
            titleMap: [
              {
                value: 'Yes - your partner may need to go to a work focused interview if you are claiming for them.',
                name: 'Yes'
              },
              {
                value: 'No - still tell us as much as you can about your partner. We will get in touch with you about this.',
                name: 'No'
              }
            ],
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?"'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 3',
        page: 3,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 2: About you and your partner (1/2)</h2></div>'
          },
          {
            type: "fieldset",
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '6_4',
                type: 'select',
                title: 'Title',
                "titleMap": [
                  {"value": "Mr", "name": "Mr"},
                  {"value": "Mrs", "name": "Mrs"},
                  {"value": "Miss", "name": "Miss"},
                  {"value": "Ms", "name": "Ms"}
                ]
              },
              {
                key: '6_1',
                title: 'Surname',
                name: 'surname',
                autocomplete: 'family-name'
              },
              {
                key: '6_2',
                title: 'Other name',
                name: 'firstname',
                autocomplete: 'given-name'
              },
              {
                key: '6_6',
                type: 'memorabledate',
                title: 'Date of birth',
                maxDate: new Date(),
                validationMessage: 'Your date of birth is incorrect'
              },
              {
                key: '6_7',
                title: 'National Insurance (NI) number',
                description: 'You can get this from a payslip, benefit letters or from tax papers.'
              },
              {
                key: '6_8',
                title: 'Address',
                type: 'textarea',
                name: 'address',
                autocomplete: 'street-address'
              },
              {
                key: '6_9',
                title: 'Address, if different in the last 3 years',
                type: 'textarea',
                description: 'If you have more previous addresses in the last 3 years then include them in part 21 other information.',
                name: 'address',
                autocomplete: 'street-address'
              },
              {
                key: '6_10',
                title: 'Mobile phone number',
                autocomplete: 'phone'
              },
              {
                key: '6_12',
                title: 'Daytime phone number',
                description: 'You only need to fill this out if it\'s different from your mobile number',
                autocomplete: 'phone'
              },
              {
                key: '6_13',
                title: 'Number type',
                type: 'radios',
                titleMap: [{value: 'work', name: 'Work'}, {value: 'home', name: 'Home'}],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '6_15',
                title: 'Nationality',
                description: 'For example, British'
              }]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '6_19',
                type: 'select',
                title: 'Title',
                "titleMap": [
                  {"value": "Mr", "name": "Mr"},
                  {"value": "Mrs", "name": "Mrs"},
                  {"value": "Miss", "name": "Miss"},
                  {"value": "Ms", "name": "Ms"}
                ]
              },
              {
                key: '6_16',
                title: 'Surname'
              },
              {
                key: '6_17',
                title: 'Other name'
              },
              {
                key: '6_21',
                type: 'memorabledate',
                title: 'Date of birth',
                maxDate: new Date(),
                validationMessage: 'Your partner\'s date of birth is incorrect'
              },
              {
                key: '6_22',
                title: 'National Insurance (NI) number',
                description: 'You can get this from a payslip, benefit letters or from tax papers.'
              },
              {
                key: '6_23',
                title: 'Address',
                description: 'You only need to include your partner\'s address if it\'s different from yours',
                type: 'textarea'
              },
              {
                key: '6_24',
                title: 'Address, if different in the last 3 years',
                type: 'textarea'
              },
              {
                key: '6_25',
                title: 'Mobile phone number'
              },
              {
                key: '6_27',
                title: 'Daytime phone number',
                description: 'You only need to fill this out if it\'s different from your partner\'s mobile number'
              },
              {
                key: '6_28',
                title: 'Number type',
                type: 'radios',
                titleMap: [{value: 'work', name: 'Work'}, {value: 'home', name: 'Home'}],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                key: '6_30',
                title: 'Nationality',
                description: 'For example, British'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 4',
        page: 4,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 2: About you and your partner (2/2)</h2></div>'
          },
          {
            type: "fieldset",
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },

              '7_1',
              '7_2',

              {
                key: '7_3',
                type: 'textarea',
                title: 'Please say where we can get in touch with you'
              },


              {
                type: "section",
                htmlClass: 'form-group schema-form-checkboxes',
                items: [
                  {
                    type: "help",
                    helpvalue: '<label>What is your marital or civil partnership status?</label>'
                  },
                  '7_4',
                  '7_5',
                  '7_6',
                  '7_7',
                  '7_8',
                  '7_9',
                  {
                    type: "help",
                    htmlClass: 'help-block success',
                    helpvalue: '<div>Tick the box which applies to you. If you tick more than one box you will need to say why in Part 21 Other information</div>'
                  }
                ]
              },


              {
                type: 'section',
                condition: 'model["7_9"] == true',
                items: [
                  {
                    key: '7_10',
                    title: 'Date became widowed or surviving civil partner',
                    type: 'memorabledate',
                    maxDate: new Date(),
                    validationMessage: 'The date is not valid'
                  }
                ]
              },
              {
                key: '7_11',
                title: 'Are you expecting a baby?',
                description: 'If you’re expecting a baby or have a child under 4 then you may qualify for healthy start vouchers and vitamins. Contact <a href="tel:08456076823">08456076823</a> or visit <a target="_blank" href="http://www.healthystart.nhs.uk">http://www.healthystart.nhs.uk</a> for an application leaflet.',
                type: 'radios',
                titleMap: [{value: 'no', name: 'No'}, {value: 'yes', name: 'Yes'}],
                validationMessage: 'This information is required'
              },
              {
                type: 'section',
                condition: 'model["7_11"] === "yes"',
                items: [{
                  key: '7_13',
                  title: 'What date is the baby due?',
                  type: 'kerpdate',
                  mode: 'month',
                  minDate: new Date(),
                  validationMessage: 'The date is not valid'
                }]
              },
              {
                key: '7_14',
                title: 'Have you had a baby in the 39 weeks before the date you are claiming from?',
                type: 'radios',
                titleMap: [{value: 'no', name: 'No'}, {value: 'yes', name: 'Yes'}],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                type: 'section',
                condition: 'model["7_14"] === "yes"',
                items: [{
                  key: '7_16',
                  title: 'What date was the baby born?',
                  type: 'memorabledate',
                  maxDate: new Date(),
                  validationMessage: 'The date is not valid'
                }]
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?"&& model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },

              '7_17',
              '7_18',

              {
                key: '7_19',
                type: 'textarea',
                fieldHtmlClass: 'noDescription',
                title: 'Please say where we can get in touch with your partner',
                description: null
              },
              {
                key: '7_20',
                type: 'checkboxes',
                title: 'What is your marital or civil partnership status?',
                "titleMap": [
                  {"value": "Married or civil partner", "name": "Married or civil partner"}
                ]
              },
              {
                key: '7_21',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {
                    "value": "Divorced or civil partnership dissolved",
                    "name": "Divorced or civil partnership dissolved"
                  }
                ]
              },
              {
                key: '7_22',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "single", "name": "Single"}
                ]
              },
              {
                key: '7_23',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "separated", "name": "Separated"}
                ]
              },
              {
                key: '7_24',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "Living together", "name": "Living together"}
                ]
              },
              {
                key: '7_25',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "widowed or surviving civil partner", "name": "Widowed or surviving civil partner"}
                ],
                description: 'Tick the box which applies to you. If you tick more than one box you will need to say why in part 21 other information'
              },
              {
                type: 'section',
                condition: 'model["7_25"].indexOf("widowed or surviving civil partner") !== -1',
                items: [
                  {
                    key: '7_26',
                    title: 'Date became widowed or surviving civil partner',
                    type: 'memorabledate',
                    maxDate: new Date(),
                    validationMessage: 'The date is not valid'
                  }
                ]
              },
              {
                key: '7_27',
                title: 'Are you expecting a baby?',
                type: 'radios',
                titleMap: [{value: 'no', name: 'No'}, {value: 'yes', name: 'Yes'}],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                type: 'section',
                condition: 'model["7_27"] === "yes"',
                items: [{
                  key: '7_29',
                  title: 'What date is the baby due?',
                  type: 'kerpdate',
                  mode: 'month',
                  minDate: new Date(),
                  validationMessage: 'The date is not valid'
                }]
              },
              {
                key: '7_30',
                title: 'Have you had a baby in the 39 weeks before the date you are claiming from',
                type: 'radios',
                titleMap: [{value: 'no', name: 'No'}, {value: 'yes', name: 'Yes'}],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              },
              {
                type: 'section',
                condition: 'model["7_30"] === "yes"',
                items: [{
                  key: '7_32',
                  title: 'What date was the baby born?',
                  type: 'memorabledate',
                  maxDate: new Date(),
                  validationMessage: 'The date is not valid'
                }]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 5',
        page: 5,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 3: About your illness or disability (1/2)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '8_1',
                title: 'What date did your illness or disability start?',
                description: 'Give a date that your illness or disability started. However this will not affect the start date of your claim if it is more than 3 months ago.',
                validationMessage: 'You have to fill in your illness start date',
                type: 'kerpdate',
                mode: 'month',
                maxDate: new Date()
              },
              {
                key: '8_2',
                type: 'textarea',
                title: 'Please give brief details of your illness or disability'
              }, {
                key: '8_3',
                type: 'text',
                title: 'Name of the doctor who signs your medical statements'
              }, {
                key: '8_4',
                type: 'textarea',
                title: 'Address of the doctor who signs your medical statements'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4 col-sm-2",
                    items: [{
                      key: "8_5",
                      title: 'Code',
                      placeholder: '01484'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-8 col-sm-6",
                    items: [{
                      key: "8_6",
                      title: 'Number',
                      placeholder: 'XXXXXX'
                    }]
                  }
                ]
              }, {
                key: '8_13',
                title: 'Are you getting Statutory Sick Pay',
                type: 'radios',
                htmlClass: 'formField',
                description: 'If you are still getting paid by your employer, your pay slip may say that it includes Statutory Sick Pay. Whilst getting Statutory Sick Pay you cannot be paid Employment Support Allowance. However if you are on a low income you may be entitled to Income Support.',
                titleMap: [{value: 'no', name: 'No'}, {
                  value: 'yes',
                  name: 'Yes - please send your current medical statement to us.'
                }],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              }, {
                key: '8_15',
                title: 'Are you entitled to Statutory Sick Pay',
                type: 'radios',
                htmlClass: 'formField',
                description: 'Your employer may be able to tell you this.',
                titleMap: [{
                  value: 'no',
                  name: 'No - ask your doctor for a medical statement from the 8th day of your illness or disability and send it to us.'
                },
                  {value: 'yes', name: 'Yes'}],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '8_7',
                title: 'What date did your partner\'s illness or disability start?',
                validationMessage: 'You must state when your partner\'s illness or disability started',
                type: 'kerpdate',
                mode: 'month',
                maxDate: new Date()
              },
              {
                key: '8_8',
                type: 'textarea',
                title: 'Please give brief details of your partner\'s illness or disability'
              }, {
                key: '8_9',
                type: 'text',
                title: 'Name of the doctor who signs your medical statements'
              }, {
                key: '8_10',
                type: 'textarea',
                title: 'Address of the doctor who signs your medical statements'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-2",
                    items: [{
                      key: "8_11",
                      title: 'Code',
                      placeholder: '01484'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-10 col-md-6",
                    items: [{
                      key: "8_12",
                      title: 'Number',
                      placeholder: 'XXXXXX'
                    }]
                  }
                ]
              }
            ]
          }]
      }, {
        type: 'section',
        condition: 'model.page === 6',
        page: 6,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 3: About your illness or disability (2/2)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'template',
                template: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3><p><a href="https://www.youtube.com/watch?v=Z3Qc40fZJlo" title="ESA1: Part 3 What does in-patient mean?">Watch this video to understand what in-patient means.</a></p></div>'
              },
              {
                key: '9_1',
                title: 'Have you been in hospital as an in-patient in the last 52 weeks?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["9_1"] === "yes"',
                items: [{
                  key: '9_3',
                  title: 'Name and address of the hospital',
                  type: 'textarea',
                  htmlClass: 'formField'
                }, {
                  key: '9_4',
                  title: 'Date you went in to hospital',
                  type: 'kerpdate',
                  mode: 'month',
                  htmlClass: 'formField'
                }, {
                  key: '9_5',
                  title: 'Have you come out of hospital?',
                  type: 'radios',
                  htmlClass: 'formField',
                  titleMap: [{value: 'no', name: 'No'},
                    {value: 'yes', name: 'Yes'}]
                }, {
                  type: 'section',
                  htmlClass: 'formField',
                  condition: 'model["9_5"] === "yes"',
                  items: [{
                    key: '9_7',
                    title: 'Date you came out of hospital',
                    type: 'kerpdate',
                    mode: 'month',
                    htmlClass: 'formField'
                  }]
                }]
              }, {
                key: '9_8',
                title: 'Are you due to go into hospital in the next 3 months?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["9_8"] === "yes"',
                items: [{
                  key: '9_10',
                  title: 'Date you are due to go into hospital',
                  validationMessage: 'The date is not valid',
                  type: 'kerpdate',
                  mode: 'month',
                  minDate: new Date()
                }]
              },
              {
                key: '9_11',
                title: 'Have you ever been registered or certified as blind or severely sight impaired with a local authority in England or Wales, or a regional or islands council in Scotland?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["9_11"] === "yes"',
                items: [{
                  key: '9_13',
                  title: 'Please tell us the name of the local authority or council',
                  type: 'text',
                  htmlClass: 'formField'
                }]
              },
              {
                key: '9_14',
                title: 'Has your local authority or council removed you from the register?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["9_14"] === "yes"',
                items: [{
                  key: '9_16',
                  title: 'Date your local authority removed you from the register',
                  validationMessage: 'The date is not valid',
                  type: 'kerpdate',
                  mode: 'month',
                  maxDate: new Date(),
                  htmlClass: 'formField'
                }]
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '9_17',
                title: 'Have you been in hospital as an in-patient in the last 52 weeks?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["9_17"] === "yes"',
                items: [{
                  key: '9_19',
                  title: 'Name and address of the hospital',
                  type: 'textarea',
                  htmlClass: 'formField'
                }, {
                  key: '9_20',
                  title: 'Date you went in to hospital',
                  type: 'kerpdate',
                  mode: 'month',
                  htmlClass: 'formField'
                }, {
                  key: '9_21',
                  title: 'Have you come out of hospital?',
                  type: 'radios',
                  htmlClass: 'formField',
                  titleMap: [{value: 'no', name: 'No'},
                    {value: 'yes', name: 'Yes'}]
                }, {
                  type: 'section',
                  htmlClass: 'formField',
                  condition: 'model["9_21"] === "yes"',
                  items: [{
                    key: '9_23',
                    title: 'Date you came out of hospital',
                    type: 'kerpdate',
                    mode: 'month',
                    htmlClass: 'formField'
                  }]
                }]
              }, {
                key: '9_24',
                title: 'Are you due to go into hospital in the next 3 months?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["9_24"] === "yes"',
                items: [{
                  key: '9_26',
                  title: 'Date you are due to go into hospital',
                  type: 'kerpdate',
                  mode: 'month',
                  validationMessage: 'The date is not valid',
                  minDate: new Date()
                }]
              },
              {
                key: '9_27',
                title: 'Have you ever been registered or certified as blind or severely sight impaired with a local authority in England or Wales, or a regional or islands council in Scotland?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["9_27"] === "yes"',
                items: [{
                  key: '9_29',
                  title: 'Please tell us the name of the local authority or council',
                  type: 'text',
                  htmlClass: 'formField'
                }]
              },
              {
                key: '9_30',
                title: 'Has your local authority or council removed you from the register?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["9_30"] === "yes"',
                items: [{
                  key: '9_32',
                  title: 'Date your local authority removed you from the register',
                  type: 'kerpdate',
                  mode: 'month',
                  validationMessage: 'The date is not valid',
                  maxDate: new Date()
                }]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 7',
        page: 7,
        items: [
          {
            type: 'template',
            template: '<div><h2 class=\'formPartTitle\'>Part 4: Special rules</h2><p><a href="https://www.youtube.com/watch?v=LKDKrddAB3Y" title="ESA1: Part 4 What are special rules?">Watch this video to understand special rules.</a></p></div>'
          }, {
            type: 'template',
            htmlClass: 'text-center alert alert-warning',
            template: '<div><b>Special rules</b> are for people who, because of their condition, are not expected to live longer than 6 months.<br />If you claim under special rules, <b>you will be able to get your benefit more quickly and easily.</b><p><a href="https://www.youtube.com/watch?v=QLivzxXepB8" title="ESA1: Part 4 How do I get a DS1500 report?">Watch this video about how to get a DS1500 report.</a></p><p><a href="https://www.youtube.com/watch?v=LlpdN4a7i9o" title="ESA1: Part 4 If you have to wait for a DS1500 report">Watch this video about what to do if you are waiting for a DS1500 report.</a></p></div>'
          }, {
            key: '10_1',
            title: 'Do you think that the special rules apply to you?',
            type: 'radios',
            htmlClass: 'formField',
            description: 'This applies to people who because of their illness are not expected to live longer than 6 months.<p>A DS1500 report is about your medical condition and is completed by a GP, consultant or specialist nurse if they believe you meet the special rules criteria. You will not have to pay for this report.</p>You can request a DS1500 report from:<ul><li>a receptionist at your doctors surgery</li><li>a nurse</li><li>a social worker</li></ul>Ask for the report in a sealed envelope if you do not want anyone to see it.<p>If you cannot get your DS1500 report in time, send your claim for for Employment and Support Allowance without it. When you receive your DS1500 report send it straightaway.</p>',
            titleMap: [{value: 'no - go to Part 5 About Work', name: 'No'},
              {
                value: 'yes - ask your doctor or specialist for a DS1500 report',
                name: 'Yes - ask your doctor or specialist for a DS1500 report'
              }]
          }, {
            type: 'section',
            condition: 'model["10_1"] === "yes - ask your doctor or specialist for a DS1500 report"',
            items: [{
              key: '10_3',
              title: 'Have you already asked for a DS1500 Report for your claim for Disability Living Allowance or Personal Independence Payment?',
              type: 'radios',
              htmlClass: 'formField',
              description: 'If the answer is yes, you do not need to get another DS1500 report. Send the DS1500 report with your claim for Disability Living Allowance or Personal Independence Payment.',
              titleMap: [{value: 'no', name: 'No'},
                {value: 'yes', name: 'Yes'}]
            }, {
              key: '10_5',
              title: 'Have you already sent the DS1500 Report with your claim for Disability Living Allowance or Personal Independence Payment?',
              type: 'radios',
              htmlClass: 'formField',
              titleMap: [{value: 'no', name: 'No'},
                {value: 'yes', name: 'Yes'}]
            }]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 8',
        page: 8,
        items: [
          {
            type: 'template',
            templateUrl: 'modules/forms/scripts/services/forms/ESA1/templates/workIntro.html'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '11_1',
                title: 'Are you working at the moment?',
                description: 'If you are temporarily absent from work due to illness, or you do voluntary work, then still tick <b>Yes</b>',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [
                  {
                    value: 'no, please send us your P45. Go to Part 6 About other benefits.',
                    name: 'No - please send your P45 with your claim.'
                  },
                  {value: 'Yes, please tell us about this below.', name: 'Yes'}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["11_1"] === "Yes, please tell us about this below."',
                items: [
                  {
                    key: '11_3',
                    title: 'Date the work started',
                    type: 'kerpdate',
                    mode: 'month',
                    validationMessage: 'The date is not valid',
                    maxDate: new Date()
                  }, {
                    key: '11_4',
                    title: 'If you are no longer working, date you last worked',
                    type: 'kerpdate',
                    mode: 'month',
                    description: 'State the last date you worked before your current period of illness or disability.',
                    validationMessage: 'The date is not valid',
                    maxDate: new Date()
                  }, {
                    key: '11_5',
                    title: 'Number of hours a week you usually work',
                    type: 'number'
                  }, {
                    key: '11_6',
                    title: 'Number of days a week you usually work',
                    type: 'number'
                  }, {
                    key: '11_7',
                    title: 'Number of hours a week you currently work',
                    type: 'number'
                  }, {
                    key: '11_8',
                    title: 'Number of days a week you currently work',
                    type: 'number'
                  }, {
                    key: '11_9',
                    title: 'Employer\'s name',
                    description: 'If you have more than one employer then include those in Part 21 Other information',
                    type: 'string'
                  }, {
                    key: '11_10',
                    title: 'Employer\'s address',
                    type: 'string'
                  }, {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-2",
                        items: [{
                          key: "11_11",
                          title: 'Code',
                          placeholder: '01484'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-10 col-md-6",
                        items: [{
                          key: "11_12",
                          title: 'Number',
                          placeholder: 'XXXXXX'
                        }]
                      }
                    ]
                  }, {
                    key: '11_13',
                    title: 'Job title',
                    type: 'string'
                  }, {
                    key: '11_14',
                    title: 'Clock, payroll or employee number',
                    type: 'string'
                  }
                ]
              }
            ]
          }, {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              }, {
                key: '11_15',
                title: 'Are you working at the moment?',
                description: 'If you are temporarily absent from work due to illness, or you do voluntary work, then still tick <b>Yes</b>',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [
                  {
                    value: 'No, go to Part 6 About other benefits.',
                    name: 'No - please send your P45 with your claim.'
                  },
                  {
                    value: 'Yes, please tell us about this below.',
                    name: 'Yes'
                  }
                ]
              }, {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["11_15"] === "Yes, please tell us about this below."',
                items: [
                  {
                    key: '11_17',
                    title: 'Date the work started',
                    type: 'kerpdate',
                    mode: 'month',
                    validationMessage: 'The date is not valid',
                    maxDate: new Date()
                  }, {
                    key: '11_18',
                    title: 'If you are no longer working, date you last worked',
                    type: 'kerpdate',
                    mode: 'month',
                    description: 'State the last date you worked before your current period of illness or disability.',
                    validationMessage: 'The date is not valid',
                    maxDate: new Date()
                  }, {
                    key: '11_19',
                    title: 'Number of hours a week you usually work',
                    type: 'number'
                  }, {
                    key: '11_20',
                    title: 'Number of days a week you usually work',
                    type: 'number'
                  }, {
                    key: '11_21',
                    title: 'Number of hours a week you currently work',
                    type: 'number'
                  }, {
                    key: '11_22',
                    title: 'Number of days a week you currently work',
                    type: 'number'
                  }, {
                    key: '11_23',
                    title: 'Employer\'s name',
                    description: 'If you have more than one employer then include those in Part 21 Other information',
                    type: 'string'
                  }, {
                    key: '11_24',
                    title: 'Employer\'s address',
                    type: 'string'
                  }, {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-2",
                        items: [{
                          key: "11_25",
                          title: 'Code',
                          placeholder: '01484'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-10 col-md-6",
                        items: [{
                          key: "11_26",
                          title: 'Number',
                          placeholder: 'XXXXXX'
                        }]
                      }
                    ]
                  }, {
                    key: '11_27',
                    title: 'Job title',
                    type: 'string'
                  }, {
                    key: '11_28',
                    title: 'Clock, payroll or employee number',
                    type: 'string'
                  }
                ]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 9',
        page: 9,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 5: About work (2/3)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              }, {
                key: '12_1',
                title: 'Will your employer keep paying you if you are off work because of an illness or disability?',
                description: 'Your employer should be able to tell you this and also confirm if the payment will include Statutory Sick Pay.',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_3',
                title: 'Do you get any money for expenses?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_5',
                title: 'Does your employer pay any money towards a pension for you?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_7',
                title: 'Is the work you do voluntary work?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_9',
                title: 'Can you choose whether or not to be paid for the work?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_11',
                title: 'Do you get anything else in return for working? For example, things like accommodation or food',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_13',
                title: 'Are you self-employed or a sub-contractor?',
                description: 'If yes, you will be sent a form B16 by post, to fill in and return',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_15',
                title: 'Do you work in a specialist occupation?',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/ESA1/templates/specialistOccupationDescription.html'),
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_17',
                title: 'Are you involved in a trade dispute?',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/ESA1/templates/tradeDisputeDescription.html'),
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }
            ]
          }, {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              }, {
                key: '12_19',
                title: 'Will your employer keep paying you if you are off work because of an illness or disability?',
                description: 'Your employer should be able to tell you this and also confirm if the payment will include SSP.',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_22',
                title: 'Do you get any money for expenses?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_23',
                title: 'Does your employer pay any money towards a pension for you?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_25',
                title: 'Is the work you do voluntary work?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_27',
                title: 'Can you choose whether or not to be paid for the work?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_29',
                title: 'Do you get anything else in return for working? For example, things like accommodation or food',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_31',
                title: 'Are you self-employed or a sub-contractor?',
                description: 'If yes, we will send you a form B16 to fill in and return to us',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_33',
                title: 'Do you work in a specialist occupation?',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/ESA1/templates/specialistOccupationDescription.html'),
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }, {
                key: '12_35',
                title: 'Are you involved in a trade dispute?',
                description: htmlService.interpolateTemplate('modules/forms/scripts/services/forms/ESA1/templates/tradeDisputeDescription.html'),
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 10',
        page: 10,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 5: About work (3/3)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                key: '13_1',
                title: 'Do you know when you will be well enough to work again?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              },
              {
                type: 'section',
                condition: 'model["13_1"] === "yes"',
                htmlClass: 'formField',
                items: [{
                  key: '13_3',
                  title: 'Tell us when you will be well enough to work',
                  type: 'kerpdate',
                  mode: 'month',
                  validationMessage: 'The date is not valid',
                  minDate: new Date()
                }]
              },
              {
                key: '13_4',
                title: 'Are you going to go back to work?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              },
              {
                type: 'section',
                condition: 'model["13_4"] === "yes"',
                htmlClass: 'formField',
                items: [{
                  key: '13_6',
                  title: 'What date will you go back to work?',
                  type: 'kerpdate',
                  mode: 'month',
                  validationMessage: 'The date is not valid',
                  minDate: new Date()
                }]
              },
              {
                key: '13_7',
                title: 'Did you work a night shift which included midnight on the date you last worked?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              },
              {
                type: 'section',
                condition: 'model["13_7"] ==="yes"',
                htmlClass: "row",
                items: [
                  {
                    type: 'help',
                    htmlClass: 'form-group',
                    helpvalue: '<div><label class="control-label">What date and time did you start the shift?</label></div>'
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-sm-5",
                    items: [{
                      key: "13_9",
                      notitle: true,
                      type: 'kerpdate',
                      mode: 'month',
                      validationMessage: 'The date is not valid',
                      maxDate: new Date()
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-md-6 minus-margin-top30",
                    items: [{
                      key: "13_10",
                      notitle: true,
                      type: 'kerptime',
                      feedback: false,//bug fix, without that the time picker is not displayed properly
                      validationMessage: 'The time is not valid'
                    }]
                  }
                ]
              },
              {
                type: 'section',
                condition: 'model["13_7"] ==="yes"',
                htmlClass: "row",
                items: [
                  {
                    type: 'help',
                    htmlClass: 'form-group',
                    helpvalue: '<div><label class="control-label">What date and time did you end the shift?</label></div>'
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-sm-5",
                    items: [{
                      key: "13_12",
                      notitle: true,
                      type: 'kerpdate',
                      mode: 'month',
                      validationMessage: 'The date is not valid',
                      maxDate: new Date()
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-md-6 minus-margin-top30",
                    items: [{
                      key: "13_13",
                      notitle: true,
                      feedback: false,//bug fix, without that the time picker is not displayed properly
                      type: 'kerptime',
                      validationMessage: 'The time is not valid'
                    }]
                  }
                ]
              },
              {
                key: '13_17',
                title: 'Will you go back to work on a night shift which includes midnight?',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no', name: 'No'},
                  {value: 'yes', name: 'Yes'}]
              },
              {
                type: 'section',
                condition: 'model["13_17"] ==="yes"',
                htmlClass: "row",
                items: [
                  {
                    type: 'help',
                    htmlClass: 'form-group',
                    helpvalue: '<div><label class="control-label">What date and time will you start the shift?</label></div>'
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-sm-5",
                    items: [{
                      key: "13_19",
                      notitle: true,
                      type: 'kerpdate',
                      mode: 'month',
                      validationMessage: 'The date is not valid',
                      minDate: new Date()
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-md-6 minus-margin-top30",
                    items: [{
                      key: "13_20",
                      notitle: true,
                      type: 'kerptime',
                      feedback: false,//bug fix, without that the time picker is not displayed properly
                      validationMessage: 'The time is not valid'
                    }]
                  }
                ]
              },
              {
                type: 'section',
                condition: 'model["13_17"] ==="yes"',
                htmlClass: "row",
                items: [
                  {
                    type: 'help',
                    htmlClass: 'form-group',
                    helpvalue: '<div><label class="control-label">What date and time will you end the shift?</label></div>'
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-sm-5",
                    items: [{
                      key: "13_22",
                      notitle: true,
                      type: 'kerpdate',
                      mode: 'month',
                      validationMessage: 'The date is not valid',
                      minDate: new Date()
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-6 col-md-6 minus-margin-top30",
                    items: [{
                      key: "13_23",
                      notitle: true,
                      type: 'kerptime',
                      feedback: false,//bug fix, without that the time picker is not displayed properly
                      validationMessage: 'The time is not valid'
                    }]
                  }
                ]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 11',
        page: 11,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 6: About other benefits (1/6)</h2></div>'
          },
          {
            type: 'help',
            htmlClass: 'alert alert-warning',
            helpvalue: '<div>This section asks about any social security benefits you are claiming now or have claimed in the past. <p> For example:<ul><li>Attendance Allowance</li><li>Bereavement Allowance, Bereavement Payment</li><li>Carer\'s Allowance</li><li>Disability Living Allowance</li><li>Incapacity Benefit</li><li>Income Support</li><li>Industrial Death Benefit, Industrial Injuries Disablement Benefit</li><li>Jobseeker\'s Allowance</li><li>Maternity Allowance</li><li>Motability or any other help with mobility problems</li><li>Pension Credit</li><li>Personal Independence Payment</li><li>Reduced Earnings Allowance</li><li>State Pension, Severe Disablement Allowance</li><li>Unemployability Supplement</li><li>Universal Credit</li><li>Widow\'s Benefit, Widowed Mother\'s Allowance, Widowed Parent\'s Allowance</li></ul>Make sure you include all social security benefits, even if they are not on this list. <p>You will be asked about Housing Benefit and Council Tax Reduction later.</p></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '14_1',
                title: 'Are you getting or waiting to hear about any social security benefits now',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no - go to page 12', name: 'No'},
                  {value: 'yes - please tell us about these benefits on page 11', name: 'Yes'}]
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '14_3',
                title: 'Are you getting or waiting to hear about any social security benefits now',
                type: 'radios',
                htmlClass: 'formField',
                titleMap: [{value: 'no - go to page 12', name: 'No'},
                  {value: 'yes - please tell us about these benefits on page 11', name: 'Yes'}]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 12',
        page: 12,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 6: About other benefits (2/6)</h2></div>'
          },
          {
            type: "section",
            htmlClass: "",
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3>Benefit 1</h3></div>'
              },
              {
                key: "15_1",
                title: 'Name of the benefit',
                placeholder: 'Name of the benefit',
                description: 'If you need to enter information about more than 3 benefits, please use Part 21 Other information.'
              },
              {
                key: "15_2",
                title: 'Who is getting the benefit',
                placeholder: 'Who is getting the benefit'
              },
              {
                key: "15_3",
                title: 'Reference number',
                placeholder: 'Reference number',
                description: 'This will be your National Insurance Number'
              },
              {
                key: "15_4",
                title: 'How much is paid?',
                placeholder: 'How much is paid?'
              },
              {
                key: '15_5',
                type: 'radios',
                title: 'How often is it paid?',
                "titleMap": [
                  {"value": "weekly", "name": "Weekly"},
                  {"value": "monthly", "name": "Monthly"},
                  {"value": "fortnightly", "name": "Fortnightly"},
                  {"value": "4 weekly", "name": "4 weekly"}
                ]
              },
              {
                key: "15_9",
                title: 'Other',
                htmlClass: 'sub-field',
                placeholder: 'Alternate payment frequency'
              },
              {
                key: "15_10",
                title: 'What day is it paid?'
              },
              {
                key: '15_11',
                type: 'radios',
                title: 'How is it paid?',
                "titleMap": [
                  {
                    "value": "direct into a bank or building society account",
                    "name": "Directly into a bank or building society account"
                  },
                  {"value": "by cheque", "name": "Other"}
                ]
              },
              {
                key: "15_13",
                title: 'Date of next payment',
                type: "kerpdate",
                mode: 'day',
                placeholder: 'What day is it paid?'
              },
              {
                key: '15_14',
                type: 'radios',
                title: 'Is any money being deducted from the benefit?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: "model['15_14'] === 'yes'",
                items: [
                  {
                    key: "15_16",
                    title: 'How much is being deducted?',
                    placeholder: 'How much is being deducted?'
                  },
                  {
                    key: "15_17",
                    title: 'What is it being deducted for?',
                    placeholder: 'What is it being deducted for?'
                  }
                ]
              },
              {
                type: 'section',
                condition: "!model.nbBenefit || model.nbBenefit === 1",
                htmlClass: "text-right",
                items: [{
                  type: 'actions',
                  items: [
                    {
                      type: 'button',
                      style: 'btn-default',
                      title: 'Add benefit',
                      onClick: "setPropertyInFormModel('nbBenefit',2)"
                    }
                  ]
                }]
              }
            ]
          },
          {
            type: 'section',
            condition: "model.nbBenefit >= 2",
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3>Benefit 2</h3></div>'
              },
              {
                key: "15_18",
                title: 'Name of the benefit',
                placeholder: 'Name of the benefit'
              },
              {
                key: "15_19",
                title: 'Who is getting the benefit',
                placeholder: 'Who is getting the benefit'
              },
              {
                key: "15_20",
                title: 'Reference number',
                placeholder: 'Reference number',
                description: 'This will be your National Insurance Number'
              },
              {
                key: "15_21",
                title: 'How much is paid?',
                placeholder: 'How much is paid?'
              },
              {
                key: '15_22',
                type: 'radios',
                title: 'How often is it paid?',
                "titleMap": [
                  {"value": "weekly", "name": "Weekly"},
                  {"value": "monthly", "name": "Monthly"},
                  {"value": "fortnightly", "name": "Fortnightly"},
                  {"value": "4 weekly", "name": "4 weekly"}
                ]
              },
              {
                key: "15_26",
                title: 'Other',
                htmlClass: 'sub-field',
                placeholder: 'Alternate payment frequency'
              },
              {
                key: "15_27",
                title: 'What day is it paid?'
              },
              {
                key: '15_28',
                type: 'radios',
                title: 'How is it paid?',
                "titleMap": [
                  {
                    "value": "direct into a bank or building society account",
                    "name": "Directly into a bank or building society account"
                  },
                  {"value": "by cheque", "name": "Other"}
                ]
              },
              {
                key: "15_30",
                title: 'Date of next payment',
                type: "kerpdate",
                mode: 'day',
                placeholder: 'What day is it paid?'
              },
              {
                key: '15_31',
                type: 'radios',
                title: 'Is any money being deducted from the benefit?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: "model['15_31'] === 'yes'",
                items: [
                  {
                    key: "15_33",
                    title: 'How much is being deducted?',
                    placeholder: 'How much is being deducted?'
                  },
                  {
                    key: "15_34",
                    title: 'What is being deducted for?',
                    placeholder: 'What is being deducted for?'
                  }
                ]
              },
              {
                type: 'section',
                condition: "model.nbBenefit === 2",
                htmlClass: "text-right",
                items: [{
                  type: 'actions',
                  items: [
                    {
                      type: 'button',
                      style: 'btn-default',
                      title: 'Add benefit',
                      onClick: "setPropertyInFormModel('nbBenefit',3)"
                    },
                    {
                      type: 'button',
                      style: 'btn-danger',
                      title: 'Remove benefit',
                      onClick: "setPropertyInFormModel('nbBenefit',1)"
                    }
                  ]
                }]
              }
            ]
          },
          {
            type: 'section',
            condition: "model.nbBenefit === 3",
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3>Benefit 3</h3></div>'
              },
              {
                key: "15_35",
                title: 'Name of the benefit',
                placeholder: 'Name of the benefit'
              },
              {
                key: "15_36",
                title: 'Who is getting the benefit',
                placeholder: 'Who is getting the benefit'
              },
              {
                key: "15_37",
                title: 'Reference number',
                placeholder: 'Reference number',
                description: 'This will be your National Insurance Number'
              },
              {
                key: "15_38",
                title: 'How much is paid?',
                placeholder: 'How much is paid?'
              },
              {
                key: '15_39',
                type: 'radios',
                title: 'How often is it paid?',
                "titleMap": [
                  {"value": "weekly", "name": "Weekly"},
                  {"value": "monthly", "name": "Monthly"},
                  {"value": "fortnightly", "name": "Fortnightly"},
                  {"value": "4 weekly", "name": "4 weekly"}
                ]
              },
              {
                key: "15_43",
                title: 'Other',
                htmlClass: 'sub-field',
                placeholder: 'Alternate payment frequency'
              },
              {
                key: "15_44",
                title: 'What day is it paid?'
              },
              {
                key: '15_45',
                type: 'radios',
                title: 'How is it paid?',
                "titleMap": [
                  {
                    "value": "direct into a bank or building society account",
                    "name": "Directly into a bank or building society account"
                  },
                  {"value": "by cheque", "name": "Other"}
                ]
              },
              {
                key: "15_47",
                title: 'Date of next payment',
                type: "kerpdate",
                mode: 'day',
                placeholder: 'What day is it paid?'
              },
              {
                key: '15_48',
                type: 'radios',
                title: 'Is any money being deducted from the benefit?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: "model['15_48'] === 'yes'",
                items: [
                  {
                    key: "15_50",
                    title: 'How much is being deducted?',
                    placeholder: 'How much is being deducted?'
                  },
                  {
                    key: "15_51",
                    title: 'What is being deducted for?',
                    placeholder: 'What is being deducted for?'
                  }
                ]
              },
              {
                type: "section",
                htmlClass: "text-right",
                items: [{
                  type: 'actions',
                  items: [
                    {
                      type: 'button',
                      style: 'btn-danger',
                      title: 'Remove benefit',
                      onClick: "setPropertyInFormModel('nbBenefit',2)"
                    }
                  ]
                }]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 13',
        page: 13,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 6: About other benefits (3/6)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'template',
                template: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3><p><a href="https://www.youtube.com/watch?v=e6toIybubh4" title="ESA1: Part 6 Have you ever claimed Carers Allowance?">Watch this video to understand how to answer the question about claiming Carers Allowance.</a></p></div>'
              },
              {
                key: '16_1',
                type: 'radios',
                title: 'Have you ever claimed Carer\'s Allowance?',
                description: "Tick Yes even if you were not paid any Carer's allowance.",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: "model['16_1'] === 'yes'",
                items: [
                  {
                    key: '16_3',
                    type: 'radios',
                    title: 'Has the Carer’s Allowance stopped in the last 3 months?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    key: '16_5',
                    title: 'Date of last claim or payment',
                    type: 'kerpdate',
                    mode: 'month',
                    htmlClass: 'formField'
                  },
                  {
                    key: '16_6',
                    title: 'Name of the person being cared for',
                    htmlClass: 'formField',
                    placeholder: 'Name of the person being cared for'
                  },
                  {
                    key: '16_7',
                    type: 'textarea',
                    title: 'Address of the person being cared for',
                    htmlClass: 'formField'
                  }
                ]
              },
              {
                key: '16_8',
                type: 'radios',
                title: 'Does anyone care for you on a regular basis?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: "model['16_8'] === 'yes'",
                items: [
                  {
                    key: '16_10',
                    title: 'What is their name and address?',
                    type: 'textarea',
                    htmlClass: 'formField'
                  },
                  {
                    key: '16_11',
                    type: 'radios',
                    title: 'Do they get Carer’s Allowance for caring for you?',
                    description: 'Tick Yes if they have claimed Carer\'s Allowance and are waiting to hear about it.',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '16_13',
                type: 'radios',
                title: 'Have you ever claimed Carer\'s Allowance?',
                description: "Tick Yes even if you were not paid any Carer's allowance.",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: "model['16_13'] === 'yes'",
                items: [
                  {
                    key: '16_15',
                    type: 'radios',
                    title: 'Has the Carer’s Allowance stopped in the last 3 months?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    key: '16_17',
                    title: 'Date of last claim or payment',
                    type: 'kerpdate',
                    mode: 'month',
                    htmlClass: 'formField'
                  },
                  {
                    key: '16_18',
                    title: 'Name of the person being cared for',
                    htmlClass: 'formField',
                    placeholder: 'Name of the person being cared for'
                  },
                  {
                    key: '16_19',
                    type: 'textarea',
                    title: 'Address of the person being cared for',
                    htmlClass: 'formField'
                  }
                ]
              },
              {
                key: '16_20',
                type: 'radios',
                title: 'Does anyone care for you on a regular basis?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: "model['16_20'] === 'yes'",
                items: [
                  {
                    key: '16_22',
                    title: 'What is their name and address?',
                    type: 'textarea',
                    htmlClass: 'formField'
                  },
                  {
                    key: '16_23',
                    type: 'radios',
                    title: 'Do they get Carer’s Allowance for caring for you?',
                    description: 'Tick Yes if they are waiting to hear about it',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 14',
        page: 14,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 6: About other benefits (4/6)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '17_1',
                type: 'radios',
                title: 'Do you or your partner or any of your children young persons or qualifying young persons who live in your household get Disability Living Allowance or Personal Independence Payment?',
                description: '\'Child\' means a person under the age of 16 who you are getting Child Benefit for.<br/>\'Qualifying young person\' means a person aged 16, 17, 18 or 19 who you are getting Child Benefit for.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: "model['17_1'] === 'yes'",
                items: [
                  {
                    key: '17_3',
                    type: 'radios',
                    title: 'Is the Disability Living Allowance for help with getting around?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: "model['17_3'] === 'yes'",
                    items: [{
                      key: '17_5',
                      type: 'radios',
                      title: 'What rate is paid?',
                      "titleMap": [
                        {"value": "lower rate", "name": "Lower rate"},
                        {"value": "higher rate", "name": "Higher rate"}
                      ]
                    }]
                  },
                  {
                    key: '17_7',
                    type: 'radios',
                    title: 'Is the Disability Living Allowance for help with personal care?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  }
                ]
              },
              {
                type: 'section',
                condition: "model['17_7'] === 'yes'",
                items: [{
                  key: '17_9',
                  type: 'radios',
                  title: 'What rate is paid?',
                  "titleMap": [
                    {"value": "lowest rate", "name": "Lowest rate"},
                    {"value": "middle rate", "name": "Middle rate"},
                    {"value": "highest rate", "name": "Highest rate"}
                  ]
                }]
              },
              {
                key: '17_12',
                type: 'radios',
                title: 'Do you get Attendance Allowance, Motability, War Pension Mobility, War pension Mobility supplement, or other help with mobility problems',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                key: '17_13',
                type: 'radios',
                title: 'Do you get Personal Independence Payment',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["17_13"] === "yes"',
                items: [
                  {
                    key: 'page18_1',
                    type: 'radios',
                    title: 'Is it for help with mobility?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes, what rate is paid?", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["page18_1"] === "yes, what rate is paid?"',
                    items: [{
                      key: 'page18_2',
                      type: 'radios',
                      title: 'What rate is paid?',
                      "titleMap": [
                        {"value": "standard rate", "name": "Standard rate"},
                        {"value": "Enhanced rate", "name": "Enhanced rate"}
                      ]
                    }]
                  },
                  {
                    key: 'page18_3',
                    type: 'radios',
                    title: 'Is it for help with daily living?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["page18_3"] === "yes"',
                    items: [{
                      key: 'page18_4',
                      type: 'radios',
                      title: 'What rate is paid?',
                      "titleMap": [
                        {"value": "Standard rate", "name": "Standard rate"},
                        {"value": "Enhanced rate", "name": "Enhanced rate"}
                      ]
                    }]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '17_14',
                type: 'radios',
                title: 'Does your partner get Disability Living Allowance or Personal Independence Payment?',
                description: '\'Child\' means a person under the age of 16 who you are getting Child Benefit for.<br/>\'Qualifying young person\' means a person ages 16, 17, 18 or 19 who you are getting Child Benefit for.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: "model['17_14'] === 'yes'",
                items: [
                  {
                    key: '17_16',
                    type: 'radios',
                    title: 'Is the Disability Living Allowance for help with getting around?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: "model['17_16'] === 'yes'",
                    items: [{
                      key: '17_18',
                      type: 'radios',
                      title: 'What rate is paid?',
                      "titleMap": [
                        {"value": "lower rate", "name": "Lower rate"},
                        {"value": "higher rate", "name": "Higher rate"}
                      ]
                    }]
                  },
                  {
                    key: '17_20',
                    type: 'radios',
                    title: 'Is the Disability Living Allowance for help with personal care?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: "model['17_20'] === 'yes'",
                    items: [{
                      key: '17_22',
                      type: 'radios',
                      title: 'What rate is paid?',
                      "titleMap": [
                        {"value": "lowest rate", "name": "Lowest rate"},
                        {"value": "middle rate", "name": "Middle rate"},
                        {"value": "highest rate", "name": "Highest rate"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '17_25',
                type: 'radios',
                title: 'Does your partner get Attendance Allowance or Motability or War Pension Mobility Supplement or other help with mobility problems?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                key: '17_26',
                type: 'radios',
                title: 'Does your partner get Personal Independence Payment',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["17_26"] === "yes"',
                items: [
                  {
                    key: 'page18_5',
                    type: 'radios',
                    title: 'Is it for help with mobility?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["page18_5"] === "yes"',
                    items: [{
                      key: 'page18_6',
                      type: 'radios',
                      title: 'What rate is paid?',
                      "titleMap": [
                        {"value": "Standard rate", "name": "Standard rate"},
                        {"value": "Enhanced rate", "name": "Enhanced rate"}
                      ]
                    }]
                  },
                  {
                    key: 'page18_7',
                    type: 'radios',
                    title: 'Is it for help with daily living?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["page18_7"] === "yes"',
                    items: [{
                      key: 'page18_8',
                      type: 'radios',
                      title: 'What rate is paid?',
                      "titleMap": [
                        {"value": "Standard rate", "name": "Standard rate"},
                        {"value": "Enhanced rate", "name": "Enhanced rate"}
                      ]
                    }]
                  }
                ]
              }
            ]
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>Children or qualifying young persons</h3></div>'
              },
              {
                key: '17_27',
                type: 'radios',
                title: 'Do any of your children, young persons or qualifying young persons who live in your household get Disability Living Allowance or Personal Independence Payment?',
                description: '\'Child\' means a person under the age of 16 who you are getting Child Benefit.<br />\'Qualifying young person\' means a person aged 16, 17, 18 or 19 who you are getting Child Benefit for.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["17_27"] === "yes"',
                items: [
                  {
                    key: '17_30',
                    type: 'radios',
                    title: 'Is the Disability Living Allowance for help with getting around?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: "model['17_30'] === 'yes'",
                    items: [{
                      key: '17_32',
                      type: 'radios',
                      title: 'What rate is paid?',
                      "titleMap": [
                        {"value": "lower rate", "name": "Lower rate"},
                        {"value": "higher rate", "name": "Higher rate"}
                      ]
                    }]
                  },
                  {
                    key: '17_34',
                    type: 'radios',
                    title: 'Is the Disability Living Allowance for help with personal care?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: "model['17_34'] === 'yes'",
                    items: [{
                      key: '17_36',
                      type: 'radios',
                      title: 'What rate is paid?',
                      "titleMap": [
                        {"value": "lowest rate", "name": "Lowest rate"},
                        {"value": "middle rate", "name": "Middle rate"},
                        {"value": "highest rate", "name": "Highest rate"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '17_39',
                type: 'radios',
                title: 'Do any of the children or qualifying young persons who live in your household get Attendance Allowance or Motability or War Pension Mobility Supplement or other help with mobility problems?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }, {
                type: 'section',
                condition: 'model["17_39"] === "yes"',
                items: [{
                  key: '17_41',
                  title: 'Who get the benefit?'
                }]
              },
              {
                key: '17_42',
                type: 'radios',
                title: 'Do any of the children or qualifying young persons who live in your household or your partner get Personal Independence Payment',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["17_42"] === "yes"',
                items: [
                  {
                    key: 'page18_9',
                    type: 'radios',
                    title: 'Is it for help with mobility?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["page18_9"] === "yes"',
                    items: [{
                      key: 'page18_10',
                      type: 'radios',
                      title: 'What rate is paid?',
                      "titleMap": [
                        {"value": "Standard rate", "name": "Standard rate"},
                        {"value": "Enhanced rate", "name": "Enhanced rate"}
                      ]
                    }]
                  },
                  {
                    key: 'page18_11',
                    type: 'radios',
                    title: 'Is it for help with daily living?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["page18_11"] === "yes"',
                    items: [{
                      key: 'page18_12',
                      type: 'radios',
                      title: 'What rate is paid?',
                      "titleMap": [
                        {"value": "lower rate", "name": "Standard rate"},
                        {"value": "higher rate", "name": "Enhanced rate"}
                      ]
                    }]
                  }
                ]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 15',
        page: 15,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 6: About other benefits (5/6)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '18_1',
                type: 'radios',
                title: 'Did you get War Widow\'s or War Widower\'s Pension',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["18_1"] === "yes"',
                items: [{
                  key: '18_3',
                  title: 'Reference number'
                }]
              }, {
                key: '18_4',
                type: 'radios',
                title: 'Did you get the return to Work Credit in the last 3 months?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["18_4"] === "yes"',
                items: [{
                  key: '18_5',
                  type: 'kerpdate',
                  mode: 'month',
                  title: 'Date of the last payment'
                }]
              },
              {
                key: '18_9',
                type: 'radios',
                title: 'Is anyone getting or has anyone just stopped getting, Child Benefit for you',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["18_9"] === "yes"',
                items: [{
                  key: '18_10',
                  title: 'Their surname'
                },
                  {
                    key: '18_11',
                    title: 'Their other names'
                  }, {
                    key: '18_16',
                    type: 'textarea',
                    title: 'Their address'
                  },
                  {
                    key: '18_17',
                    title: 'Their Child Benefit number'
                  }]
              }

            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '18_18',
                type: 'radios',
                title: 'Did you get War Widow\'s or War Widower\'s Pension',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["18_18"] === "yes"',
                items: [{
                  key: '18_19',
                  title: 'Reference number'
                }]
              },
              {
                key: '18_21',
                type: 'radios',
                title: 'Did you get the return to Work Credit in the last 3 months?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["18_20"] === "yes"',
                items: [{
                  key: '18_22',
                  type: 'kerpdate',
                  mode: 'day',
                  title: 'Date of the last payment'
                }]
              },
              {
                key: '18_25',
                type: 'radios',
                title: 'Is anyone getting, or has anyone just stopped getting, Child Benefit for you',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["18_25"] === "yes"',
                items: [{
                  key: '18_26',
                  title: 'Their surname'
                },
                  {
                    key: '18_27',
                    title: 'Their other names'
                  },
                  {
                    key: '18_32',
                    type: 'textarea',
                    title: 'Their address'
                  },
                  {
                    key: '18_33',
                    title: 'Their Child Benefit number'
                  }]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 16',
        page: 16,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 6: About other benefits (6/6)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '19_1',
                type: 'radios',
                title: 'Have you claimed any other benefits in the last 2 years?',
                description: 'If the claim was turned down, still tick Yes',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes. Tell us the last benefit you claimed below.", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["19_1"] === "yes. Tell us the last benefit you claimed below."',
                items: [
                  {
                    key: '19_3',
                    title: 'Name of the benefit'
                  },
                  {
                    key: '19_4',
                    title: 'Date of the last payment',
                    type: 'kerpdate',
                    mode: 'month'
                  },
                  {
                    key: '19_5',
                    title: 'Which benefit office dealt with the claim?'
                  },
                  {
                    key: '19_6',
                    type: 'radios',
                    title: 'Was your name or address different when you last claimed benefit?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes. Please tell us about this below.", "name": "Yes"}
                    ]
                  }, {
                    type: 'section',
                    condition: 'model["19_6"] === "yes"',
                    items: [{
                      key: '19_8',
                      title: 'Full name'
                    }, {
                      key: '19_9',
                      title: 'Address',
                      type: 'textarea'
                    }, {
                      key: '19_10',
                      type: 'kerpdate',
                      mode: 'year',
                      title: 'When did you move to your present address?'
                    }]
                  }
                ]
              }
            ]
          },
          {
            key: '19_11',
            type: 'radios',
            title: 'Is anyone getting extra money added to their benefit for you?',
            description: 'If the answer is yes, please enter the details in Part 21 Other information',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes. Please tell us about this in Part 22 Other information.", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '19_13',
                type: 'radios',
                title: 'Have you claimed any other benefits in the last 2 years?',
                description: 'If the claim was turned down, still tick Yes',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["19_13"] === "yes"',
                items: [
                  {
                    key: '19_15',
                    title: 'Name of the benefit'
                  },
                  {
                    key: '19_16',
                    title: 'Date of the last payment',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '19_17',
                    title: 'Which benefit office dealt with the claim?'
                  },
                  {
                    key: '19_18',
                    type: 'radios',
                    title: 'Was your name or address different when you last claimed benefit?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  }, {
                    type: 'section',
                    condition: 'model["19_18"] === "yes"',
                    items: [{
                      key: '19_20',
                      title: 'Full name'
                    }, {
                      key: '19_21',
                      title: 'Address',
                      type: 'textarea'
                    }, {
                      key: '19_22',
                      type: 'kerpdate',
                      mode: 'year',
                      title: 'When did you move to your present address?'
                    }]
                  }
                ]
              }, {
                key: '19_23',
                type: 'radios',
                title: 'Is anyone getting extra money added to their benefit for you?',
                description: 'If the answer is yes, please enter the details in Part 21 Other information',
                "titleMap": [
                  {"value": "yes. Please tell us about this in Part 22 Other information", "name": "No"},
                  {"value": "1", "name": "Yes"}
                ]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 17',
        page: 17,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 7: About time spent abroad (1/2)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div><div>Have you<ul><li>worked or claimed benefit outside the United Kingdom in the last 5 years, or</li><li>been a member, or in the family of a member of HM Armed Forces outside the United Kingdom in the last 12 weeks?</li></ul></div></div>'
              },
              {
                key: '20_1',
                type: 'radios',
                description: 'United Kingdom means England, Scotland, Wales and Northern Ireland.',
                notitle: true,
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["20_1"] === "yes"',
                items: [
                  {
                    type: 'help',
                    helpvalue: '<div><h3>Which countries did you go to, and when?</h3></div>'
                  },
                  {
                    key: '20_3',
                    title: 'Country'
                  },
                  {
                    key: '20_4',
                    title: 'From',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '20_5',
                    title: 'To',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '20_6',
                    title: 'Country'
                  },
                  {
                    key: '20_7',
                    title: 'From',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '20_8',
                    title: 'To',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '20_9',
                    type: 'radios',
                    title: 'Please tick the boxes that describes what you did while you were abroad?',
                    "titleMap": [
                      {"value": "Employed by a foreign employer", "name": "Employed by a foreign employer"},
                      {"value": "Employed by a UK employer", "name": "Employed by a UK employer"},
                      {"value": "Claimed UK benefit abroad", "name": "Claimed UK benefit abroad"},
                      {"value": "self-employed", "name": "Self-employed"},
                      {"value": "claimed foreign benefit", "name": "Claimed foreign benefit"},
                      {"value": "A member of HM Armed Forces", "name": "A member of HM Armed Forces"}
                    ]
                  },
                  {
                    key: '20_14',
                    type: 'radios',
                    title: "Were you abroad because someone in your family was in HM Armed Forces?",
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    key: '20_18',
                    title: 'What is their relationship to you?',
                    description: 'For example, your father or mother.'
                  }
                ]
              }
            ]
          }, {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div><div>Have you<ul><li>worked or claimed benefit outside the United Kingdom in the last 5 years, or</li><li>been a member, or in the family of a member of HM Armed Forces outside the United Kingdom in the last 12 weeks?</li></ul></div></div>'
              },
              {
                key: '20_19',
                type: 'radios',
                notitle: true,
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["20_19"] === "yes"',
                items: [
                  {
                    type: 'help',
                    helpvalue: '<div><h3>Which countries did you go to, and when?</h3></div>'
                  },
                  {
                    key: '20_21',
                    title: 'Country'
                  },
                  {
                    key: '20_22',
                    title: 'From',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '20_23',
                    title: 'To',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '20_24',
                    title: 'Country'
                  },
                  {
                    key: '20_25',
                    title: 'From',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '20_26',
                    title: 'To',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '20_27',
                    type: 'radio',
                    title: 'Please tick the boxes that describes what you did while you were abroad?',
                    "titleMap": [
                      {"value": "Employed by a foreign employer", "name": "Employed by a foreign employer"},
                      {"value": "Employed by a UK employer", "name": "Employed by a UK employer"},
                      {"value": "Claimed UK benefit abroad", "name": "Claimed UK benefit abroad"},
                      {"value": "self-employed", "name": "Self-employed"},
                      {"value": "claimed foreign benefit", "name": "Claimed foreign benefit"},
                      {"value": "A member of HM Armed Forces", "name": "A member of HM Armed Forces"}
                    ]
                  },
                  {
                    key: '20_32',
                    type: 'radios',
                    title: "Were you abroad because someone in your family was in HM Armed Forces?",
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    key: '20_36',
                    title: 'What is their relationship to you?'
                  }
                ]
              }

            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 18',
        page: 18,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 7: About time spent abroad (2/2)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '21_1',
                type: 'radios',
                title: "Are you exempt from paying UK Income tax?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                key: '21_3',
                type: 'radios',
                title: "Were you abroad for more than one year in total in the 5 years before you stopped paying UK income tax?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }
            ]
          }, {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '21_5',
                type: 'radios',
                title: "Are you exempt from paying UK Income tax?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                key: '21_7',
                type: 'radios',
                title: "Were you abroad for more than one year in total in the 5 years before you stopped paying UK income tax?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 19',
        page: 19,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 8: About statutory payments</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><div class="alert alert-warning">This section asks about any statutory payments you are getting from your employer, or have claimed in the past. For example:<ul><li>Statutory Adoption Pay</li><li>Statutory Maternity Pay</li><li>Statutory Paternity Pay</li><li>Statutory Sick Pay</li></ul></div></div>'
              },
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '22_1',
                type: 'radios',
                title: "Are you getting or waiting to hear about any statutory payments now?",
                description: "If you receive more than one Statutory Payment enter the details in Part 21 Other information",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["22_1"] === "yes"',
                items: [
                  {
                    key: '22_3',
                    title: 'Name of the statutory payment'
                  },
                  {
                    key: '22_4',
                    title: 'Who do you get your statutory payment from?'
                  },
                  {
                    type: 'help',
                    helpvalue: "<div>How much money do you get and how often?</div>",
                    htmlClass: "helpTitle"
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "22_5",
                          title: '£'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "22_6",
                          title: 'every',
                          type: 'number'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: '22_7',
                          type: 'select',
                          title: 'Frequency',
                          "titleMap": [
                            {"value": "weeks", "name": "Weeks"},
                            {"value": "months", "name": "Months"}
                          ]
                        }]
                      }
                    ]
                  },
                  {
                    key: "22_8",
                    title: "What day is it paid?"
                  }
                ]
              }, {
                key: '22_9',
                type: 'radios',
                title: "Have you ever had a statutory payment in the past?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["22_9"] === "yes"',
                items: [
                  {
                    key: '22_11',
                    title: 'Name of the statutory payment'
                  },
                  {
                    key: '22_12',
                    title: 'Who did you get your statutory payment from?'
                  },
                  {
                    type: 'help',
                    helpvalue: "<div>How much money did you get and how often?</div>",
                    htmlClass: "helpTitle"
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "22_13",
                          title: '£'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "22_14",
                          title: 'every'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: '22_15',
                          type: 'select',
                          title: 'Frequency',
                          "titleMap": [
                            {"value": "weeks", "name": "Weeks"},
                            {"value": "months", "name": "Months"}
                          ]
                        }]
                      }
                    ]
                  }, {
                    key: "22_16",
                    title: "What day was it paid?"
                  }
                ]
              }
            ]
          }, {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '22_17',
                type: 'radios',
                title: "Are you getting or waiting to hear about any statutory payments now?",
                description: "If you receive more than one Statutory Payment enter the details in Part 21 Other information",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["22_17"] === "yes"',
                items: [
                  {
                    key: '22_19',
                    title: 'Name of the statutory payment'
                  },
                  {
                    key: '22_20',
                    title: 'Who do you get your statutory payment from?'
                  },
                  {
                    type: 'help',
                    helpvalue: "<div>How much money do you get and how often?</div>",
                    htmlClass: "helpTitle"
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "22_21",
                          title: '£'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "22_22",
                          title: 'every',
                          type: 'number'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: '22_23',
                          type: 'select',
                          title: 'Frequency',
                          "titleMap": [
                            {"value": "weeks", "name": "Weeks"},
                            {"value": "months", "name": "Months"}
                          ]
                        }]
                      }
                    ]
                  }, {
                    key: "22_24",
                    type: "kerpdate",
                    mode: 'day',
                    title: "What day is it paid?"
                  }
                ]
              }, {
                key: '22_25',
                type: 'radios',
                title: "Have you ever had a statutory payment in the past?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["22_25"] === "yes"',
                items: [
                  {
                    key: '22_27',
                    title: 'Name of the statutory payment'
                  },
                  {
                    key: '22_28',
                    title: 'Who did you get your statutory payment from?'
                  },
                  {
                    type: 'help',
                    helpvalue: "<div>How much money did you get and how often?</div>",
                    htmlClass: "helpTitle"
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "22_29",
                          title: '£'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "22_30",
                          title: 'every',
                          type: 'number'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: '22_31',
                          type: 'select',
                          title: 'Frequency',
                          "titleMap": [
                            {"value": "weeks", "name": "Weeks"},
                            {"value": "months", "name": "Months"}
                          ]
                        }]
                      }
                    ]
                  }, {
                    key: "22_32",
                    title: "What day was it paid?"
                  }
                ]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 20',
        page: 20,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 9: About pensions (1/2)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'template',
                template: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3><p><a href="https://www.youtube.com/watch?v=sHt8GPtV_aw" title="ESA1: Part 9 What do I need?">Watch this video to find out about the documents you will need to complete Part 9 of the ESA1 form.</a></p></div>'
              },
              {
                key: '23_1',
                type: 'radios',
                title: "Are you getting or waiting to get a pension?",
                description: 'Pension means:<ul><li>An occupational pension</li><li>A personal pension</li><li>A retirement annuity contract</li><li>Payment from the Armed Forces Compensation Scheme</li><li>There is no need to list any State Pension here.</li></ul>Tick Yes if you get</li><ul></li><li>Regular pension payments</li><li>an annual compensation payment from a previous job</li><li>lump sum payments from an occupational or personal pension. These could be paid yearly.</li><li>Payments from the Pension Protection Fund</li><li>Financial Assistance Scheme payments</li></ul>',
                "titleMap": [
                  {"value": "no - go to Part 10 About permanent health insurance.", "name": "No"},
                  {
                    "value": "yes - please tell us about this below. If you have more than one pension, please tell us about them in Part 22 Other information.",
                    "name": "Yes"
                  }
                ]
              }
            ]
          }, {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '23_3',
                type: 'radios',
                title: "Is your partner getting or waiting to get a pension?",
                description: 'Pension means:<ul><li>An occupational pension</li><li>A personal pension</li><li>A retirement annuity contract</li><li>Payment from the Armed Forces Compensation Scheme</li><li>There is no need to list any State Pension here</li></ul>Tick Yes if you get</li><ul></li><li>Regular pension payments</li><li>an annual compensation payment from a previous job</li><li>lump sum payments from an occupational or personal pension. These could be paid yearly</li><li>Payments from the Pension Protection Fund</li><li>Financial Assistance Scheme payments</li></ul>',
                "titleMap": [
                  {"value": "no - Go to Part 10 About permanent health insurance.", "name": "No"},
                  {
                    "value": "yes - please tell us about this below. If your partner has more than one pension, please tell us about them in Part 22 Other information.",
                    "name": "Yes"
                  }
                ]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 21',
        page: 21,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 9: About pensions (2/2)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '23_5',
                type: 'checkboxes',
                title: 'What type of pension are you getting or waiting to get?',
                description: "If you have more than one pension, enter the details in Part 21 Other information",
                "titleMap": [
                  {"value": "personal pension", "name": "Personal pension"}
                ]
              },
              {
                key: '23_6',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {
                    "value": "Occupational, work or employee's pension",
                    "name": "Occupational, work or employee's pension"
                  }
                ]
              },
              {
                key: '23_7',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "Retirement annuity contract", "name": "Retirement annuity contract"}
                ]
              },
              {
                key: '23_8',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "Public service pension", "name": "Public service pension"}
                ]
              },
              {
                key: '23_9',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "Pension paid to you as a beneficiary", "name": "Pension paid to you as a beneficiary"}
                ]
              },
              {
                key: '24_1',
                type: 'textarea',
                title: 'Name and address of your pension provider'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    items: [{
                      type: 'help',
                      htmlClass: 'helpTitle col-sm-12',
                      helpvalue: '<div>Their phone number</div>'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-2",
                    items: [{
                      key: "24_2",
                      title: 'Code',
                      placeholder: '01484'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-10 col-md-6",
                    items: [{
                      key: "24_3",
                      title: 'Number',
                      placeholder: 'XXXXXX'
                    }]
                  }
                ]
              },
              {
                key: '24_4',
                title: 'Pension or policy reference number'
              },
              {
                type: "help",
                htmlClass: "helpTitle",
                helpvalue: "<div>How much is the pension before any deductions?</div>"
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_5",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_6",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '24_7',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"},
                        {"value": "years", "name": "Years"}
                      ]
                    }]
                  }
                ]
              },
              {
                type: "help",
                htmlClass: "helpTitle",
                helpvalue: "<div>How much is the pension after any deductions?</div>"
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_8",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_9",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '24_10',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"},
                        {"value": "years", "name": "Years"}
                      ]
                    }]
                  }
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>How much are the deductions and what are they for?</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_11",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-8",
                    items: [{
                      key: "24_12",
                      title: 'for'
                    }]
                  }
                ]
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_13",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-8",
                    items: [{
                      key: "24_14",
                      title: 'for'
                    }]
                  }
                ]
              },
              {
                key: '24_15',
                type: 'kerpdate',
                mode: 'month',
                title: 'When did the pension start, or when will it start?'
              },
              {
                key: '24_16',
                type: 'kerpdate',
                mode: 'month',
                title: 'Date of first payment'
              },
              {
                key: '24_17',
                type: 'radios',
                title: "Will the pension increase?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["24_17"] === "yes"',
                items: [
                  {
                    key: '24_19',
                    type: 'kerpdate',
                    mode: 'month',
                    title: 'Date of first payment after the increase'
                  }
                ]
              },

              {
                type: 'help',
                condition: 'model["24_17"] === "yes"',
                htmlClass: 'helpTitle',
                helpvalue: 'How much will your pension be after the increase?'
              },
              {
                type: "section",
                condition: 'model["24_17"] === "yes"',
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_20",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_21",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '24_22',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"},
                        {"value": "years", "name": "Years"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '24_23',
                type: 'radios',
                title: "Did you choose to take regular income from the pension scheme instead of buying an annuity?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["24_23"] === "yes"',
                items: [
                  {
                    key: '24_25',
                    type: 'radios',
                    title: "Was this the maximum income you could take?",
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  }
                ]
              },
              {
                key: '24_27',
                type: 'radios',
                title: "Did you inherit your pension?",
                description: 'Please send proof of your pension income with your claim for Employment and Support Allowance. For example:<ul><li>a letter of entitlement from your employer or the insurance company that pays the pension</li><li>a payment advice notice from your pension provider</li><li>a current wage slip showing details of your pension income</li></ul><ul>Send the original documents. Do not send us photocopies. You do not need to send proof of a payment from the Armed Forces Compensation Scheme.</li></ul>If you do not send proof of pension income, you may lose benefit.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '23_10',
                type: 'checkboxes',
                title: 'What type of pension are you getting or waiting to get?',
                description: "If you have more than one pension, enter the details in Part 21 Other information",
                "titleMap": [
                  {"value": "personal pension", "name": "Personal pension"}
                ]
              },
              {
                key: '23_11',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {
                    "value": "Occupational, work or employee's pension",
                    "name": "Occupational, work or employee's pension"
                  }
                ]
              },
              {
                key: '23_12',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "Retirement annuity contract", "name": "Retirement annuity contract"}
                ]
              },
              {
                key: '23_13',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "Public service pension", "name": "Public service pension"}
                ]
              },
              {
                key: '23_14',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "Pension paid to you as a beneficiary", "name": "Pension paid to you as a beneficiary"}
                ]
              },
              {
                key: '24_29',
                type: 'textarea',
                title: 'Name and address of your pension provider'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    items: [{
                      type: 'help',
                      htmlClass: 'helpTitle',
                      helpvalue: '<div>Their phone number</div>'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-2",
                    items: [{
                      key: "24_30",
                      title: 'Code',
                      placeholder: '01484'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-10 col-md-6",
                    items: [{
                      key: "24_31",
                      title: 'Number',
                      placeholder: 'XXXXXX'
                    }]
                  }
                ]
              },
              {
                key: '24_32',
                title: 'Pension or policy reference number'
              },
              {
                type: "help",
                htmlClass: "helpTitle",
                helpvalue: "<div>How much is the pension before any deductions?</div>"
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_33",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_34",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '24_35',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"},
                        {"value": "years", "name": "Years"}
                      ]
                    }]
                  }
                ]
              },
              {
                type: "help",
                htmlClass: "helpTitle",
                helpvalue: "<div>How much is the pension after any deductions?</div>"
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_36",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_37",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '24_38',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"},
                        {"value": "years", "name": "Years"}
                      ]
                    }]
                  }
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>How much are the deductions and what are they for?</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_39",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-8",
                    items: [{
                      key: "24_40",
                      title: 'for'
                    }]
                  }
                ]
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_41",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-8",
                    items: [{
                      key: "24_42",
                      title: 'for'
                    }]
                  }
                ]
              },
              {
                key: '24_43',
                type: 'kerpdate',
                mode: 'month',
                title: 'When did the pension start, or when will it start?'
              },
              {
                key: '24_44',
                type: 'kerpdate',
                mode: 'month',
                title: 'Date of first payment'
              },
              {
                key: '24_45',
                type: 'radios',
                title: "Will the pension increase?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["24_45"] === "yes"',
                items: [
                  {
                    key: '24_47',
                    type: 'kerpdate',
                    mode: 'month',
                    title: 'Date of first payment after the increase'
                  }
                ]
              },
              {
                type: 'help',
                condition: 'model["24_45"] === "yes"',
                htmlClass: 'helpTitle',
                title: 'How much will your pension be after the increase?'
              },
              {
                type: "section",
                condition: 'model["24_45"] === "yes"',
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_48",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "24_49",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '24_50',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"},
                        {"value": "years", "name": "Years"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '24_51',
                type: 'radios',
                title: "Did you choose to take regular income from the pension scheme instead of buying an annuity?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["24_51"] === "yes"',
                items: [
                  {
                    key: '24_53',
                    type: 'radios',
                    title: "Was this the maximum income you could take?",
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  }
                ]
              },
              {
                key: '24_55',
                type: 'radios',
                title: "Did you inherit your pension?",
                description: 'Please send proof of your pension income with your claim for Employment and Support Allowance. For example:<ul><li>a letter of entitlement from your employer or the insurance company that pays the pension</li><li>a payment advice notice from your pension provider</li><li>a current wage slip showing details of your pension income</li></ul><ul>Send the original documents. Do not send us photocopies. You do not need to send proof of a payment from the Armed Forces Compensation Scheme.</li></ul>If you do not send proof of pension income, you may lose benefit.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }
            ]
          }]
      },
      {
        type: 'section',
        condition: 'model.page === 22',
        page: 22,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 10: About permanent health insurance</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '25_1',
                type: 'radios',
                title: "Are you waiting to hear about any permanent health insurance payment?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                key: '25_5',
                type: 'radios',
                title: "Do you get a permanent health insurance payment?",
                description: 'If you have more than one permanent health insurance payment, enter the details in Part 21 Other information.',
                "titleMap": [
                  {"value": "no, go to part 11 education, training and apprenticeship", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["25_5"] === "yes"',
                items: [
                  {
                    key: '25_9',
                    type: 'textarea',
                    title: 'Name and address of the employer paying the permanent health insurance payment'
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        items: [{
                          type: 'help',
                          htmlClass: 'helpTitle',
                          helpvalue: '<div>Their phone number</div>'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-2",
                        items: [{
                          key: "25_10",
                          title: 'Code',
                          placeholder: '01484'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-10 col-md-6",
                        items: [{
                          key: "25_11",
                          title: 'Number',
                          placeholder: 'XXXXXX'
                        }]
                      }
                    ]
                  },
                  {
                    key: '25_15',
                    type: 'radios',
                    title: "Has your contract of employment ended with this employer?",
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["25_15"] === "yes"',
                    items: [
                      {
                        key: '25_17',
                        title: 'When did it end?',
                        mode: 'month',
                        type: 'kerpdate'
                      }
                    ]
                  },
                  {
                    key: '25_21',
                    type: 'radios',
                    title: "Have you contributed more than half the premiums towards any permanent health insurance payment?",
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  }
                ]
              }
            ]
          }, {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '25_3',
                type: 'radios',
                title: "Are you waiting to hear about any permanent health insurance payment?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                key: '25_7',
                type: 'radios',
                title: "Do you get a permanent health insurance payment?",
                description: 'If you have more than one permanent health insurance payment, enter the details in Part 21 Other information.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["25_7"] === "yes"',
                items: [
                  {
                    key: '25_12',
                    type: 'textarea',
                    title: 'Name and address of the employer paying the permanent health insurance payment'
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        items: [{
                          type: 'help',
                          htmlClass: 'helpTitle',
                          helpvalue: '<div>Their phone number</div>'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-2",
                        items: [{
                          key: "25_13",
                          title: 'Code',
                          placeholder: '01484'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-10 col-md-6",
                        items: [{
                          key: "25_14",
                          title: 'Number',
                          placeholder: 'XXXXXX'
                        }]
                      }
                    ]
                  },
                  {
                    key: '25_18',
                    type: 'radios',
                    title: "Has your contract of employment ended with this employer?",
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["25_18"] === "yes"',
                    items: [
                      {
                        key: '25_20',
                        title: 'When did it end?',
                        mode: 'month',
                        type: 'kerpdate'
                      }
                    ]
                  },
                  {
                    key: '25_25',
                    type: 'radios',
                    title: 'Have you contributed more than half the premiums towards any permanent health insurance payment?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 23',
        page: 23,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 11: Education, training and apprenticeship</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '26_1',
                type: 'radios',
                description: 'Tick Yes if you are still doing the course',
                title: "Have you done a course of education, training or apprenticeship in the last 4 years?",
                "titleMap": [
                  {"value": "no, go to part 12 where you live", "name": "No"},
                  {"value": "yes, please tell us about this below", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["26_1"] === "yes, please tell us about this below"',
                items: [
                  {
                    key: '26_3',
                    type: 'radios',
                    title: "What was the course?",
                    titleMap: [
                      {"value": "education", "name": "Education"},
                      {"value": "training", "name": "Training"},
                      {"value": "apprenticeship", "name": "Apprenticeship"}
                    ]
                  },
                  {
                    key: '26_6',
                    title: 'Name of course, training scheme or apprenticeship'
                  },
                  {
                    key: '26_7',
                    title: 'Name and address of school, training centre, college or university',
                    type: 'textarea'
                  },
                  {
                    key: '26_8',
                    title: 'Number of hours a week',
                    type: 'number'
                  },
                  {
                    type: 'help',
                    helpvalue: '<div>Start and official end date</div>',
                    htmlClass: 'helpTitle'
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-2 col-md-1",
                        items: [{
                          type: 'help',
                          helpvalue: '<div>from</div>',
                          htmlClass: 'helpTitle'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-10 col-md-5",
                        items: [{
                          key: "26_9",
                          notitle: true,
                          mode: 'month',
                          type: 'kerpdate'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-2 col-md-1",
                        items: [{
                          type: 'help',
                          helpvalue: '<div>to</div>',
                          htmlClass: 'helpTitle'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-10 col-md-5",
                        items: [{
                          key: "26_10",
                          notitle: true,
                          mode: 'month',
                          type: 'kerpdate'
                        }]
                      }
                    ]
                  },
                  {
                    key: '26_11',
                    type: 'radios',
                    title: 'Do you have a final examination date?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    htmlClass: 'formField',
                    condition: 'model["26_11"] === "yes"',
                    items: [
                      {
                        key: '26_13',
                        type: 'kerpdate',
                        mode: 'month',
                        title: 'What is this date?'
                      }
                    ]
                  },
                  {
                    key: '26_14',
                    type: 'radios',
                    title: 'Are you eligible for a student loan or grant',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    key: '26_16',
                    type: 'radios',
                    title: 'Are you getting a student loan or grant?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    htmlClass: 'formField',
                    condition: 'model["26_16"] === "yes"',
                    items: [
                      {
                        key: '26_18',
                        title: 'What is the reference number?'
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '26_19',
                type: 'radios',
                description: 'Tick Yes if your partner is still doing the course',
                title: "Have you done a course of education, training or apprenticeship in the last 4 years?",
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["26_19"] === "yes"',
                items: [
                  // TODO: PDF model allows multiple choices here which should be invalid
                  {
                    key: '26_21',
                    type: 'checkboxes',
                    title: 'What was the course?',
                    "titleMap": [
                      {"value": "Education", "name": "Education"}
                    ]
                  },
                  {
                    key: '26_22',
                    notitle: true,
                    type: 'checkboxes',
                    "titleMap": [
                      {"value": "Training", "name": "Training"}
                    ]
                  },
                  {
                    key: '26_23',
                    notitle: true,
                    type: 'checkboxes',
                    "titleMap": [
                      {"value": "Apprenticeship", "name": "Apprenticeship"}
                    ]
                  },
                  {
                    key: '26_24',
                    title: 'Name of course, training scheme or apprenticeship'
                  },
                  {
                    key: '26_25',
                    title: 'Name and address of school, training centre, college or university',
                    type: 'textarea'
                  },
                  {
                    key: '26_26',
                    title: 'Number of hours a week',
                    type: 'number'
                  },
                  {
                    type: 'help',
                    helpvalue: '<div>Start and official end date</div>',
                    htmlClass: 'helpTitle'
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-2 col-md-1",
                        items: [{
                          type: 'help',
                          helpvalue: '<div>from</div>',
                          htmlClass: 'helpTitle'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-10 col-md-5",
                        items: [{
                          key: "26_27",
                          notitle: true,
                          mode: 'month',
                          type: 'kerpdate'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-2 col-md-1",
                        items: [{
                          type: 'help',
                          helpvalue: '<div>to</div>',
                          htmlClass: 'helpTitle'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-10 col-md-5",
                        items: [{
                          key: "26_28",
                          notitle: true,
                          mode: 'month',
                          type: 'kerpdate'
                        }]
                      }
                    ]
                  },
                  {
                    key: '26_29',
                    type: 'radios',
                    title: 'Do you have a final examination date?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    htmlClass: 'formField',
                    condition: 'model["26_29"] === "yes"',
                    items: [
                      {
                        key: '26_31',
                        type: 'kerpdate',
                        mode: 'month',
                        title: 'What is this date?'
                      }
                    ]
                  },
                  {
                    key: '26_32',
                    type: 'radios',
                    title: 'Are you eligible for a student loan or grant',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    key: '26_34',
                    type: 'radios',
                    title: 'Are you getting a student loan or grant?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    htmlClass: 'formField',
                    condition: 'model["26_34"] === "yes"',
                    items: [
                      {
                        key: '26_36',
                        title: 'What is the reference number?'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 24',
        page: 24,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 12: Where you live (1/2)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '27_1',
                type: 'radios',
                title: 'Do you live with parents, relatives or friends as part of their family?',
                description: 'For example, parent, friend or relative.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["27_1"] === "yes"',
                items: [
                  {
                    key: '27_3',
                    title: 'Full name of the head of the household'
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-3 col-md-2",
                        items: [{
                          key: '27_4',
                          type: 'select',
                          title: 'Title',
                          "titleMap": [
                            {"value": "Mr", "name": "Mr"},
                            {"value": "Mrs", "name": "Mrs"},
                            {"value": "Miss", "name": "Miss"},
                            {"value": "Ms", "name": "Ms"},
                            {"value": "Prof", "name": "Prof"},
                            {"value": "Rev", "name": "Rev"}
                          ]
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-9 col-md-10",
                        items: [{
                          key: "27_5",
                          title: "Other title"
                        }]
                      }
                    ]
                  },
                  {
                    key: "27_6",
                    title: "Relationship to you"
                  },
                  {
                    key: '27_7',
                    type: 'radios',
                    title: 'Do you share the rent or mortgage for the place where you live with anyone else?',
                    description: 'If you just share with your partner, tick No',
                    "titleMap": [
                      {"value": "No", "name": "No"},
                      {"value": "Yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    htmlClass: 'formField',
                    condition: 'model["27_7"] === "Yes"',
                    items: [
                      {
                        key: '27_9',
                        type: 'textarea',
                        title: 'Names of the people you share with'
                      }
                    ]
                  }
                ]
              },
              {
                key: '27_19',
                type: 'radios',
                title: 'Do you rent your home from a council?',
                description: 'Tick Yes<ul><li>If the council is paying for you to stay in bed and breakfast, or a hotel</li><li>If you do not pay rent because you get Housing Benefit</li></ul>',
                "titleMap": [
                  {"value": "No", "name": "No"},
                  {"value": "Yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["27_19"] === "Yes"',
                items: [
                  {
                    key: '27_21',
                    type: 'textarea',
                    title: 'Name and address of the council'
                  }

                ]
              },
              {
                key: '27_25',
                type: 'radios',
                title: 'Do you pay a private landlord, landlady or housing association for the place where you live?',
                description: 'Tick Yes if you<ul><li>Just pay for the place where you live</li><li>Pay for meals as well as the place where you live</li><li>Live in a hotel, guest house or hostel</li></ul>',
                "titleMap": [
                  {"value": "No", "name": "No"},
                  {"value": "Yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["27_25"] === "Yes"',
                items: [
                  {
                    key: '27_27',
                    type: 'textarea',
                    title: 'Please tell us their name and address'
                  }
                ]
              }
            ]
          }, {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '27_10',
                type: 'radios',
                title: 'Do you live with parents, relatives or friends as part of their family?',
                description: 'For example, parent, friend or relative.',
                "titleMap": [
                  {"value": "No", "name": "No"},
                  {"value": "Yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["27_10"] === "Yes"',
                items: [
                  {
                    key: '27_12',
                    title: 'Full name of the head of the household'
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-3 col-md-2",
                        items: [{
                          key: '27_13',
                          type: 'select',
                          title: 'Title',
                          "titleMap": [
                            {"value": "Mr", "name": "Mr"},
                            {"value": "Mrs", "name": "Mrs"},
                            {"value": "Miss", "name": "Miss"},
                            {"value": "Ms", "name": "Ms"},
                            {"value": "Prof", "name": "Prof"},
                            {"value": "Rev", "name": "Rev"}
                          ]
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-9 col-md-10",
                        items: [{
                          key: "27_14",
                          title: "Other title"
                        }]
                      }
                    ]
                  },
                  {
                    key: "27_15",
                    title: "Relationship to you"
                  },
                  {
                    key: '27_16',
                    type: 'radios',
                    title: 'Do you share the rent or mortgage for the place where you live with anyone else?',
                    description: 'If you just share with your partner, tick No',
                    "titleMap": [
                      {"value": "No", "name": "No"},
                      {"value": "Yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    htmlClass: 'formField',
                    condition: 'model["27_16"] === "Yes"',
                    items: [
                      {
                        key: '27_18',
                        type: 'textarea',
                        title: 'Names of the people you share with'
                      }
                    ]
                  }
                ]
              },
              {
                key: '27_22',
                type: 'radios',
                title: 'Do you rent your home from a council?',
                description: 'Tick Yes<ul><li>If the council is paying for you to stay in bed and breakfast, or a hotel</li><li>If you do not pay rent because you get Housing Benefit</li></ul>',
                "titleMap": [
                  {"value": "No", "name": "No"},
                  {"value": "Yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["27_22"] === "Yes"',
                items: [
                  {
                    key: '27_24',
                    type: 'textarea',
                    title: 'Name and address of the council'
                  }

                ]
              },
              {
                key: '27_28',
                type: 'radios',
                title: 'Do you pay a private landlord, landlady or housing association for the place where you live?',
                description: 'Tick Yes if you<ul><li>Just pay for the place where you live</li><li>Pay for meals as well as the place where you live</li><li>Live in a hotel, guest house or hostel</li></ul>',
                "titleMap": [
                  {"value": "No", "name": "No"},
                  {"value": "Yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["27_28"] === "Yes"',
                items: [
                  {
                    key: '27_30',
                    type: 'textarea',
                    title: 'Please tell us their name and address'
                  }
                ]
              }
            ]
          }
        ]
      }, {
        type: 'section',
        condition: 'model.page === 25',
        page: 25,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 12: Where you live (2/2)</h2></div>'
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '28_1',
                type: 'radios',
                title: 'Do you pay any service charges for the place where you live?',
                description: 'For example, cleaning and maintenance of stairs and hallways.',
                "titleMap": [
                  {"value": "No", "name": "No"},
                  {"value": "Yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["28_1"] === "Yes"',
                items: [
                  {
                    type: 'help',
                    helpvalue: "<div>How much money do you pay and how often?</div>",
                    htmlClass: "helpTitle"
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "28_3",
                          title: '£'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "28_4",
                          title: 'every',
                          type: 'number'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: '28_5',
                          type: 'select',
                          title: 'Frequency',
                          "titleMap": [
                            {"value": "weeks", "name": "Weeks"},
                            {"value": "months", "name": "Months"},
                            {"value": "years", "name": "Years"}
                          ]
                        }]
                      }
                    ]
                  }
                ]
              },
              {
                key: '28_11',
                type: 'radios',
                title: 'Is the place where you live a Crown tenancy or under a long term agreement?',
                description: 'Long term agreement means a tenancy agreement which is for more than 21 years.',
                "titleMap": [
                  {"value": "No", "name": "No"},
                  {"value": "Yes", "name": "Yes"}
                ]
              },
              {
                key: '28_15',
                type: 'radios',
                title: 'Are you already getting, waiting to hear about or intending to claim Housing Benefit or apply for a Council Tax reduction, or both?',
                description: 'Housing Benefit and Council Tax Reduction do not affect the amount of Employment and Support Allowance you can get.',
                "titleMap": [
                  {"value": "No", "name": "No"},
                  {"value": "Yes", "name": "Yes"}
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>Did you claim Housing Benefit or apply for a reduction in your Council Tax with a previous claim for<ul><li>Jobseeker’s Allowance</li><li>Income Support, or</li><li>Employment and Support Allowance?</li></ul></div>'
              },
              {
                key: '28_23',
                type: 'radios',
                notitle: true,
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["28_23"] === "yes"',
                items: [
                  {
                    key: '28_25',
                    type: 'radios',
                    title: "Did you get an extra 4 weeks payment for your rent or council tax reduction when you started work after your previous claim",
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?" && model["5_2"] === "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '28_6',
                type: 'radios',
                title: 'Do you pay any service charges for the place where you live?',
                description: 'For example, cleaning and maintenance of stairs and hallways.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["28_6"] === "yes"',
                items: [
                  {
                    type: 'help',
                    helpvalue: "<div>How much money do you pay and how often?</div>",
                    htmlClass: "helpTitle"
                  },
                  {
                    type: "section",
                    htmlClass: "row",
                    items: [
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "28_8",
                          title: '£'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: "28_9",
                          title: 'every',
                          type: 'number'
                        }]
                      },
                      {
                        type: "section",
                        htmlClass: "col-xs-4",
                        items: [{
                          key: '28_10',
                          type: 'select',
                          title: 'Frequency',
                          "titleMap": [
                            {"value": "weeks", "name": "Weeks"},
                            {"value": "months", "name": "Months"},
                            {"value": "years", "name": "Years"}
                          ]
                        }]
                      }
                    ]
                  }
                ]
              },
              {
                key: '28_13',
                type: 'radios',
                title: 'Is the place where you live a Crown tenancy or under a long term agreement?',
                description: 'Long term agreement means a tenancy agreement which is for more than 21 years.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                key: '28_17',
                type: 'radios',
                title: 'Are you already getting, waiting to hear about or intending to claim Housing Benefit or apply for a Council Tax reduction, or both?',
                description: 'Housing Benefit and Council Tax Reduction do not affect the amount of Employment and Support Allowance you can get.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>Did you claim Housing Benefit or apply for a reduction in your Council Tax with a previous claim for<ul><li>Jobseeker’s Allowance</li><li>Income Support, or</li><li>Employment and Support Allowance?</li></ul></div>'
              },
              {
                key: '28_27',
                type: 'radios',
                notitle: true,
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["28_27"] === "yes"',
                items: [
                  {
                    key: '28_29',
                    type: 'radios',
                    title: "Did you get an extra 4 weeks payment for your rent or council tax reduction when you started work after your previous claim",
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 26',
        page: 26,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2>What to do now</h2><br /></div>'
          },
          {
            type: 'help',
            htmlClass: 'lead',
            helpvalue: '<div><b>If you are claiming contribution-based Employment and Support Allowance</b><br /><br />If you are sure you do <b>not</b> want to claim income-related Employment and Support Allowance, go to <b>Part 20 How we pay you</b>. If you are not sure, read the <b>Notes</b> at the front of this form to help you decide.<br />If you are still not sure, claim income-related Employment and Support Allowance anyway. Please answer all the questions in Part 13 to Part 19 of the form that apply to you and your partner, if you have one.</div>'
          },
          {
            type: 'help',
            htmlClass: 'lead',
            helpvalue: '<div><b>If you are claiming income-related Employment and Support Allowance</b><br /><br />If you ticked <b>Yes</b> on <b>page 1</b> to claim income-related Employment and Support Allowance, we need more information. Please go to <b>Part 13 About children and qualifying young persons</b> on the next page<br />Answer all the questions in Part 13 to Part 19 of the form that apply to you and your partner, if you have one.</div>'
          },
          {
            type: 'help',
            htmlClass: 'alert alert-success',
            helpvalue: '<div>If you do not claim income-related Employment and Support Allowance now, but then ask for it at a later date, we will normally only pay it from that later date.</div>'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 27',
        page: 27,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 13: About children and qualifying young persons</h2></div>'
          }, {
            key: '30_1',
            type: 'radios',
            title: "Do you have any children or qualifying young persons living permanently in your household who are dependent on you?",
            description: "Do not include<ul><li>foster children</li><li>children or qualifying young persons who are boarded out with you while they wait to be adopted</li></ul><ul>Use Part 21 Other information<li>If you have more than 8 children or qualifying young persons</li><li>If you have any children or qualifying young persons who normally live with you but are in boarding school or local authority care</li></ul>‘Child’ - means a person aged under 16 who you are getting Child Benefit for.<br />'Qualifying young person’ means a person aged 16, 17, 18 or 19 who you are getting Child Benefit for.",
            "titleMap": [
              {"value": "No", "name": "No"},
              {"value": "Yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["30_1"] === "Yes"',
            items: [
              {
                type: 'fieldset',
                htmlClass: 'formField',
                items: [
                  {
                    key: '30_3',
                    title: 'Surname'
                  },
                  {
                    key: '30_4',
                    title: 'Other names'
                  },
                  {
                    key: '30_5',
                    type: 'memorabledate',
                    title: 'Date of birth',
                    validationMessage: 'The date of birth is incorrect'
                  },
                  {
                    key: '30_6',
                    type: 'radios',
                    title: 'Male or female',
                    "titleMap": [
                      {"value": "male", "name": "M"},
                      {"value": "female", "name": "F"}
                    ]
                  },
                  {
                    key: '30_8',
                    title: 'Relation to you',
                    description: 'For example, son, daughter, niece, grandson, stepdaughter or none.'
                  },
                  {
                    key: '30_9',
                    title: 'Relation to your partner'
                  },
                  {
                    key: '30_10',
                    type: 'radios',
                    title: 'Are you getting or have you claimed Child Benefit for this child or qualifying young person?',
                    "titleMap": [
                      {"value": "yes", "name": "Yes"},
                      {"value": "no", "name": "No"}
                    ]
                  },
                  {
                    key: '30_12',
                    type: 'radios',
                    title: 'Does the child or qualifying young person have a parent or parents who live somewhere else?',
                    description: 'If yes, ask for a leaflet about child maintenance options if you are claiming benefits. You can get it from Jobcentre Plus',
                    "titleMap": [
                      {"value": "yes", "name": "Yes"},
                      {"value": "no", "name": "No"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: '!model.nbChild || model.nbChild === 1',
                    items: [{
                      type: 'actions',
                      items: [
                        {
                          type: 'button',
                          style: 'btn-default',
                          title: 'I have another child',
                          onClick: "setPropertyInFormModel('nbChild',2)"
                        }
                      ]
                    }]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model.nbChild === 2',
            items: [
              {
                type: 'fieldset',
                htmlClass: 'formField',
                items: [
                  {
                    key: '30_14',
                    title: 'Surname'
                  },
                  {
                    key: '30_15',
                    title: 'Other names'
                  },
                  {
                    key: '30_16',
                    type: 'memorabledate',
                    title: 'Date of birth',
                    validationMessage: 'The date of birth is incorrect'
                  },
                  {
                    key: '30_17',
                    type: 'radios',
                    title: 'Male or female',
                    "titleMap": [
                      {"value": "male", "name": "M"},
                      {"value": "female", "name": "F"}
                    ]
                  },
                  {
                    key: '30_19',
                    title: 'Relation to you'
                  },
                  {
                    key: '30_20',
                    title: 'Relation to your partner'
                  },
                  {
                    key: '30_21',
                    type: 'radios',
                    title: 'Are you getting or have you claimed Child Benefit for this child or qualifying young person?',
                    "titleMap": [
                      {"value": "yes", "name": "Yes"},
                      {"value": "no", "name": "No"}
                    ]
                  },
                  {
                    key: '30_23',
                    type: 'radios',
                    title: 'Does the child or qualifying young person have a parent or parents who live somewhere else?',
                    "titleMap": [
                      {"value": "yes", "name": "Yes"},
                      {"value": "no", "name": "No"}
                    ]
                  },
                  {
                    type: 'actions',
                    items: [
                      {
                        type: 'button',
                        style: 'btn-danger',
                        title: 'I have less children',
                        onClick: "setPropertyInFormModel('nbChild',1)"
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 28',
        page: 28,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 14: About bank and building society account, saving and property (1/3)</h2></div>'
          },
          {
            type: 'help',
            htmlClass: 'helpTitle',
            helpvalue: '<div>Do you or your partner have any of the following in savings?</div>'
          },
          {
            type: 'help',
            htmlClass: 'help-block',
            helpvalue: '<div>This section is about savings that you or your partner have.<ul><li>Savings mean all money, savings, investments and property in the United Kingdom (UK) or abroad which belong to you or your partner.</li><li>UK means England, Scotland, Wales and Northern Ireland.</li></ul><p>Please tick No or Yes for every item in the list. Tell us about accounts even if they are not in credit.</p></div>'
          },
          {
            key: '31_1',
            type: 'radios',
            title: 'Bank accounts, including current accounts',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_1"] === "yes"',
            items: [{
              key: '31_3',
              title: 'Amount - £'
            }]
          },
          {
            key: '31_4',
            type: 'radios',
            title: 'Building society accounts, including current accounts',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_4"] === "yes"',
            items: [{
              key: '31_6',
              title: 'Amount - £'
            }]
          },
          {
            key: '31_7',
            type: 'radios',
            title: 'Post Office accounts',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_7"] === "yes"',
            items: [{
              key: '31_9',
              title: 'Amount - £'
            }]
          },
          {
            key: '31_10',
            type: 'radios',
            title: 'National Saving & Investments accounts',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_10"] === "yes"',
            items: [{
              key: '31_12',
              title: 'Amount - £'
            }]
          },
          {
            key: '31_13',
            type: 'radios',
            title: 'National Saving Certificates',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_13"] === "yes"',
            items: [
              {
                key: '31_15',
                title: 'Amount - £'
              },
              {
                key: '31_16',
                title: 'Number of units'
              },
              {
                key: '31_17',
                title: 'Issue number'
              },
              {
                key: '31_18',
                title: 'Purchase price - £'
              }]
          },
          {
            key: '31_19',
            type: 'radios',
            title: 'Premium bonds',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_19"] === "yes"',
            items: [
              {
                key: '31_21',
                title: 'Amount - £'
              },
              {
                key: '31_22',
                title: 'Number of units'
              },
              {
                key: '31_23',
                title: 'Issue number'
              },
              {
                key: '31_24',
                title: 'Purchase price - £'
              }]
          },
          {
            key: '31_25',
            type: 'radios',
            title: 'Income Bonds or Capital Bonds',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_25"] === "yes"',
            items: [
              {
                key: '31_27',
                title: 'Amount - £'
              },
              {
                key: '31_28',
                title: 'Number of units'
              },
              {
                key: '31_29',
                title: 'Name'
              }]
          },
          {
            key: '31_30',
            type: 'radios',
            title: 'Unit Trusts',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_30"] === "yes"',
            items: [{
              key: '31_32',
              title: 'Amount - £'
            }]
          },
          {
            key: '31_33',
            type: 'radios',
            title: 'ISAs, PEPs and other investments',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_33"] === "yes"',
            items: [{
              key: '31_35',
              title: 'Amount - £'
            }]
          },
          {
            key: '31_36',
            type: 'radios',
            title: 'Government Stock',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_36"] === "yes"',
            items: [{
              key: '31_38',
              title: 'Amount - £'
            }]
          },
          {
            key: '31_39',
            type: 'radios',
            title: 'Money or property held in trust',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_39"] === "yes"',
            items: [{
              key: '31_41',
              title: 'Amount - £'
            }]
          },
          {
            key: '31_42',
            type: 'radios',
            title: 'Cash',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_42"] === "yes"',
            items: [{
              key: '31_44',
              title: 'Amount - £'
            }]
          },
          {
            key: '31_45',
            type: 'radios',
            title: 'A lump sum personal injury payment in last 52 weeks',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_45"] === "yes"',
            items: [{
              key: '31_47',
              title: 'Amount - £'
            }, {
              key: '31_48',
              title: 'Date you got the payment',
              mode: 'month',
              type: 'kerpdate'
            }]
          },
          {
            key: '31_49',
            type: 'radios',
            title: 'Insurance for repairs or possessions',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_49"] === "yes"',
            items: [{
              key: '31_51',
              title: 'Amount - £'
            }]
          },
          {
            key: '31_52',
            type: 'radios',
            title: 'Money from the sale of a home',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["31_52"] === "yes"',
            items: [{
              key: '31_54',
              title: 'Amount - £'
            }]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 29',
        page: 29,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 14: About bank and building society account, saving and property (2/3)</h2></div>'
          },
          {
            type: 'help',
            htmlClass: 'helpTitle',
            helpvalue: '<div>Do you or your partner have any of the following?</div>'
          },
          {
            key: '32_1',
            type: 'radios',
            title: 'Money set aside for essential repairs',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["32_1"] === "yes"',
            items: [{
              key: '32_3',
              title: 'Amount - £'
            }]
          },
          {
            key: '32_4',
            type: 'radios',
            title: 'Outstanding money from the Social Fund',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["32_4"] === "yes"',
            items: [{
              key: '32_6',
              title: 'Amount - £'
            }]
          },
          {
            key: '32_7',
            type: 'radios',
            title: 'Other money from the benefits owed to you',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["32_7"] === "yes"',
            items: [{
              key: '32_9',
              title: 'Amount - £'
            }]
          },
          {
            key: '32_10',
            type: 'radios',
            title: 'World War II compensation payment',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["32_10"] === "yes"',
            items: [{
              key: '32_12',
              title: 'Amount - £'
            }]
          },
          {
            key: '32_13',
            type: 'radios',
            title: 'Far Eastern Prisoners of War compensation payment',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["32_13"] === "yes"',
            items: [{
              key: '32_15',
              title: 'Amount - £'
            }]
          },
          {
            key: '32_16',
            type: 'radios',
            title: 'State Pension lump sum',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["32_16"] === "yes"',
            items: [{
              key: '32_18',
              title: 'Amount - £'
            }]
          },
          {
            key: '32_19',
            type: 'radios',
            title: 'Money from a trust fund',
            description: 'Do not tell us about payments from:<ul><li>The Macfarlane Trust</li><li>The Eileen Trust</li><li>The Skipton Fund</li><li>The Fund</li><li>MFET Ltd</li><li>The Caxton Foundation</li></ul>',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["32_19"] === "yes"',
            items: [{
              key: '32_21',
              title: 'Amount - £'
            }]
          },
          {
            key: '32_22',
            type: 'radios',
            title: 'Any Other money',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["32_22"] === "yes"',
            items: [{
              key: '32_24',
              title: 'Amount - £'
            }]
          },
          {
            key: '32_25',
            type: 'radios',
            title: 'Shares',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["32_25"] === "yes"',
            items: [{
              key: '32_27',
              title: 'Amount - £'
            },
              {
                key: '32_28',
                title: 'Number of units',
                type: 'number'
              },
              {
                key: '32_29',
                title: 'Name of company',
                description: 'Enter details of any other shares in <b>Part 21 Any Other information.</b>'
              }]
          },
          {
            key: '32_30',
            title: 'How much are the savings worth in total?',
            description: 'Add together all the amounts from savings entered in Part 14 to work this out.'
          },
          {
            key: '32_31',
            type: 'radios',
            title: 'Do you and your partner’s savings add up to £5,500 or more?',
            description: 'If the answer is yes, please send proof of your savings with this form.<br />For example:<br />a recent bank statement or a savings book updated within the last month.',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            key: '32_33',
            type: 'radios',
            title: 'Have your savings been more than £5,500 during the last 6 months?',
            description: 'If the answer is yes, please send proof of your savings with this form.<br />For example:<br />a recent bank statement or a savings book updated within the last six months.',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 30',
        page: 30,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 14: About bank and building society account, saving and property (3/3)</h2></div>'
          },
          {
            key: '33_1',
            type: 'radios',
            title: 'Do you live in a care home?',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["33_1"] === "yes"',
            items: [
              {
                key: '33_3',
                type: 'radios',
                title: 'Do you and your partner’s savings add up to £9,500 or more?',
                description: 'Please send proof of your savings with this form. For example, a recent bank statement or a savings book updated within the last month.',
                "titleMap": [
                  {
                    "value": "If yes, please send us proof of your savings with this form. For example, a recent bank statement or a savings book updated within the last month.",
                    "name": "Yes"
                  },
                  {"value": "no", "name": "No"}
                ]
              }
            ]
          },
          {
            key: '33_5',
            type: 'radios',
            title: 'Apart from the home you live in, do you or your partner own or jointly own any other property or land in the UK or abroad?',
            description: 'Tick <b>Yes</b> if the property or land is<ul><li>on a mortgage <b>or</b></li><li>jointly owned.</li></ul>',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["33_5"] === "yes"',
            items: [
              {
                key: '33_7',
                type: 'radios',
                title: 'What is this property?',
                "titleMap": [
                  {"value": "property", "name": "Property"},
                  {"value": "land", "name": "Land"}
                ]
              },
              {
                key: '33_9',
                title: 'What is the address of the property or land?'
              },
              {
                key: '33_10',
                title: 'What is the property or land used for?'
              },
              {
                key: '33_11',
                title: 'What was the original purpose for buying it?'
              },
              {
                key: '33_12',
                title: 'Who does this property or land belong to?'
              },
              {
                key: '33_13',
                type: 'radios',
                title: 'Is the property or land up for sale?',
                "titleMap": [
                  {"value": "yes", "name": "Yes"},
                  {"value": "no", "name": "No"}
                ]
              }
            ]
          },
          {
            key: '33_15',
            type: 'radios',
            title: 'Have you or your partner sold any property other than where you lived during the last 6 months?',
            description: 'If yes, please send proof of the sale of this property or land',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 31',
        page: 31,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 15: About other money coming in (1/4)</h2></div>'
          },
          {
            type: 'help',
            htmlClass: 'help-block',
            helpvalue: '<div>This section asks about any other money you or your partner have coming in.<br/>For example, you must enter details if you receive any of the following<ul><li>fostering fees or allowances</li><li>prison discharge grant</li><li>any training allowance</li><li>Guardian\'s Allowance</li><li>Child Benefit</li><li>Child Tax Credit</li><li>Working Tax Credit</li><li>War Pension</li><li>War Widow’s, or Widower’s, Pension</li><li>payments from the Pension Protection Fund</li><li>Financial Assistance Scheme payments</li><li>sick pay from an employer</li><li>benefits, allowances and pensions not from social security</li><li>student grants or loans</li><li>money from a mortgage protection policy</li><li>money from a charity or benevolent fund</li><li>any other money coming in</li></ul></div>'
          }, {
            key: '34_1',
            type: 'radios',
            title: 'Do you, your partner or anyone else you are claiming Employment and Support Allowance for, have any other money coming in?',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["34_1"] === "yes"',
            items: [
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>Money 1</div>'
              },
              {
                key: '34_3',
                title: 'Who gets this money?'
              },
              {
                key: '34_4',
                title: 'Where does the money come from?'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "34_5",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "34_6",
                      title: 'Every'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '34_7',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '34_8',
                title: 'What day is it paid?'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>Money 2</div>'
              },
              {
                key: '34_9',
                title: 'Who gets this money?'
              },
              {
                key: '34_10',
                title: 'Where does the money come from?'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "34_11",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "34_12",
                      title: 'Every'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '34_13',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '34_14',
                title: 'What day is it paid?'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 32',
        page: 32,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 15: About other money coming in (2/4)</h2></div>'
          },
          {
            key: '35_1',
            type: 'radios',
            title: 'Does anyone owe any money to you, your partner or anyone else you are claiming Employment and Support Allowance for?',
            description: 'For example:<ul><li>arrears of maintenance</li><li>money lent to someone</li></ul>',
            "titleMap": [
              {"value": "yes, please tell us about this below.", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["35_1"] === "yes, please tell us about this below."',
            items: [
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>Money 1</div>'
              },
              {
                key: '35_3',
                title: 'Who is owed this money?'
              },
              {
                key: '35_4',
                title: 'How much money are they owed?'
              },
              {
                key: '35_5',
                title: 'What is this money for?'
              },
              {
                key: '35_6',
                title: 'When do you expect the money to be paid?',
                mode: 'month',
                type: 'kerpdate'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>Money 2</div>'
              },
              {
                key: '35_7',
                title: 'Who is owed this money?'
              },
              {
                key: '35_8',
                title: 'How much money are they owed?'
              },
              {
                key: '35_9',
                title: 'What is this money for?'
              },
              {
                key: '35_10',
                title: 'When do you expect the money to be paid?',
                mode: 'month',
                type: 'kerpdate'
              }
            ]
          },
          {
            key: '35_11',
            type: 'radios',
            title: 'Do you, your partner or anyone else you are claiming Employment and Support Allowance for, get maintenance payments?',
            description: 'Enter details about maintenance paid<ul><li>voluntarily<li>because of a written agreement</li><li>because of a court order</li><li>because of a child maintenance assessment</li></ul>',
            "titleMap": [
              {"value": "yes, please tell us about this below.", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["35_11"] === "yes, please tell us about this below."',
            items: [
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>Money 1</div>'
              },
              {
                key: '35_13',
                title: 'Who gets this money?'
              },
              {
                key: '35_14',
                title: 'Who is this money for?'
              },
              {
                key: '35_15',
                title: 'Who is paying you this money?'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>How much money do you get, and how often?</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "35_16",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "35_17",
                      title: 'Every'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '35_18',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '35_19',
                title: 'What day is it paid?'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>Money 2</div>'
              },
              {
                key: '35_20',
                title: 'Who gets this money?'
              },
              {
                key: '35_21',
                title: 'Who is this money for?'
              },
              {
                key: '35_22',
                title: 'Who is paying you this money?'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>How much money do you get, and how often?</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "35_23",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "35_24",
                      title: 'Every'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '35_25',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '35_26',
                title: 'What day is it paid?'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 33',
        page: 33,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 15: About other money coming in (3/4)</h2></div>'
          },
          {
            key: '36_1',
            type: 'radios',
            title: 'Do you or your partner get any payments from a credit insurance policy?',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["36_1"] === "yes"',
            items: [
              {
                key: '36_3',
                type: 'textarea',
                title: 'What items, if any, were bought using the credit that you had?'
              },
              {
                key: '36_4',
                type: 'textarea',
                title: 'Which of these items are covered by the insurance policy?'
              },
              {
                key: '36_5',
                type: 'radios',
                title: 'Who does the insurance company make the payments to?',
                "titleMap": [
                  {"value": "direct to the supplier", "name": "Direct to the supplier"},
                  {"value": "to you or your partner", "name": "To you or your partner"},
                  {"value": "to the credit company", "name": "To the credit company"}
                ]
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>How much is paid, and how often?</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "36_8",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "36_9",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '36_10',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '36_11',
                type: 'kerpdate',
                mode: 'month',
                title: 'When did the payments start?'
              },
              {
                key: '36_12',
                type: 'kerpdate',
                mode: 'month',
                title: 'When will the payments end?',
                description: 'You must send full details of what the insurance company has paid you.<br>Use Part 21 Other information to enter details about any other money coming in.'
              },
              {
                key: '36_13',
                type: 'radios',
                title: 'Do you or your partner hold any bank accounts, investments or property, in this country or abroad, which belong to someone else?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["36_13"] === "yes"',
                items: [
                  {
                    key: '36_15',
                    type: 'radios',
                    title: 'Are these bank accounts, investments or property in your or your partner\'s name',
                    description: 'If the answer is Yes, use Part 21 Other information to enter these details.',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "If yes, please tell us about them in Part 22 Other information.", "name": "Yes"}
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 34',
        page: 34,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 15: About other money coming in (4/4)</h2></div>'
          },
          {
            key: '37_1',
            type: 'radios',
            title: 'Does anyone pay you, your partner, or anyone else you are claiming Employment and Support Allowance for, to rent rooms or property?',
            description: 'For example:<ul><li>boarders</li><li>lodgers</li><li>tenants</li><li>subtenants</li></ul>',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["37_1"] === "yes"',
            items: [
              {
                type: "help",
                htmlClass: 'helpTitle',
                helpvalue: "<div>Rent 1</div>"
              },
              {
                key: "37_3",
                title: "Who pays the rent?"
              },
              {
                key: "37_4",
                title: "Who do they pay?"
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>How much do they pay, and how often?</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "37_5",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "37_6",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '37_7',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: "37_8",
                title: 'What day is it paid'
              },
              {
                key: '37_9',
                type: 'radios',
                title: 'Tick here if the money they pay includes any money for heating or meals',
                "titleMap": [
                  {"value": "heating", "name": "Heating"},
                  {"value": "meals", "name": "Meals"}
                ]
              },
              {
                type: "help",
                htmlClass: 'helpTitle',
                helpvalue: "<div>Rent 2</div>"
              },
              {
                key: "37_11",
                title: "Who pays the rent?"
              },
              {
                key: "37_12",
                title: "Who do they pay?"
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "37_13",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "37_14",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '37_15',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: "37_16",
                title: 'What day is it paid'
              },
              {
                key: '37_17',
                type: 'radios',
                title: 'Tick here if the money they pay includes any money for heating or meals',
                "titleMap": [
                  {"value": "Heating", "name": "Heating"},
                  {"value": "Meals", "name": "Meals"}
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 35',
        page: 35,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 16: About other people who live with you (1/2)</h2></div>'
          },
          {
            type: 'help',
            htmlClass: 'help-block',
            helpvalue: '<div>This section is for details of any other people who live in the same household as you.<br />This information is needed to make sure we work out your housing costs correctly.<br />Enter details of:<ul><li>relatives, if they live in your household</li><li>boarders and lodgers</li><li>friends</li><li>anyone else who lives in your household.</li></ul>Do not enter details about:<ul><li>members of your immediate family, if you live with them in <b>their</b> household</li><li>people who just share a hall or bathroom or toilet with you, or who live in a separate flat or bedsit in the same house</li><li>other residents, if you live in a care home</li><li>foster children, or children or qualifying young persons boarded out with you while they wait to be adopted.</li></ul><b>Use Part 21 Other information if you need to enter details about more than 4 people.</b></div>'
          },
          {
            key: '38_1',
            type: 'radios',
            title: 'Do any other people live in your household who you have not already told us about?',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["38_1"] === "yes"',
            items: [
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Person 1</div>'
              },
              {
                key: '38_3',
                title: 'Full name'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-3 col-md-2",
                    items: [{
                      key: '38_4',
                      type: 'select',
                      title: 'Title',
                      "titleMap": [
                        {"value": "Mr", "name": "Mr"},
                        {"value": "Mrs", "name": "Mrs"},
                        {"value": "Miss", "name": "Miss"},
                        {"value": "Ms", "name": "Ms"},
                        {"value": "Prof", "name": "Prof"},
                        {"value": "Rev", "name": "Rev"}
                      ]
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-9 col-md-10",
                    items: [{
                      key: "38_5",
                      title: "Other title"
                    }]
                  }
                ]
              },
              {
                key: '38_6',
                title: 'Date of birth',
                type: 'memorabledate',
                validationMessage: 'The date of birth is incorrect'
              },
              {
                key: '38_7',
                title: 'Relationship to you'
              },
              {
                key: '38_8',
                type: 'radios',
                title: 'Do they work for 16 hours or more a week?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Person 2</div>'
              },
              {
                key: '38_10',
                title: 'Full name'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-3 col-md-2",
                    items: [{
                      key: '38_11',
                      type: 'select',
                      title: 'Title',
                      "titleMap": [
                        {"value": "Mr", "name": "Mr"},
                        {"value": "Mrs", "name": "Mrs"},
                        {"value": "Miss", "name": "Miss"},
                        {"value": "Ms", "name": "Ms"},
                        {"value": "Prof", "name": "Prof"},
                        {"value": "Rev", "name": "Rev"}
                      ]
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-9 col-md-10",
                    items: [{
                      key: "38_12",
                      title: "Other title"
                    }]
                  }
                ]
              },
              {
                key: '38_13',
                type: 'memorabledate',
                title: 'Date of birth',
                validationMessage: 'The date of birth is incorrect'
              },
              {
                key: '38_14',
                title: 'Relationship to you'
              },
              {
                key: '38_15',
                type: 'radios',
                title: 'Do they work for 16 hours or more a week?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Person 3</div>'
              },
              {
                key: '38_17',
                title: 'Full name'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-3 col-md-2",
                    items: [{
                      key: '38_18',
                      type: 'select',
                      title: 'Title',
                      "titleMap": [
                        {"value": "Mr", "name": "Mr"},
                        {"value": "Mrs", "name": "Mrs"},
                        {"value": "Miss", "name": "Miss"},
                        {"value": "Ms", "name": "Ms"},
                        {"value": "Prof", "name": "Prof"},
                        {"value": "Rev", "name": "Rev"}
                      ]
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-9 col-md-10",
                    items: [{
                      key: "38_19",
                      title: "Other title"
                    }]
                  }
                ]
              },
              {
                key: '38_20',
                type: 'memorabledate',
                title: 'Date of birth',
                validationMessage: 'The date of birth is incorrect'
              },
              {
                key: '38_21',
                title: 'Relationship to you'
              },
              {
                key: '38_22',
                type: 'radios',
                title: 'Do they work for 16 hours or more a week?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Person 4</div>'
              },
              {
                key: '38_24',
                title: 'Full name'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-3 col-md-2",
                    items: [{
                      key: '38_25',
                      type: 'select',
                      title: 'Title',
                      "titleMap": [
                        {"value": "Mr", "name": "Mr"},
                        {"value": "Mrs", "name": "Mrs"},
                        {"value": "Miss", "name": "Miss"},
                        {"value": "Ms", "name": "Ms"},
                        {"value": "Prof", "name": "Prof"},
                        {"value": "Rev", "name": "Rev"}
                      ]
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-9 col-md-10",
                    items: [{
                      key: "38_26",
                      title: "Other title"
                    }]
                  }
                ]
              },
              {
                key: '38_27',
                type: 'memorabledate',
                title: 'Date of birth',
                validationMessage: 'The date of birth is incorrect'
              },
              {
                key: '38_28',
                title: 'Relationship to you'
              },
              {
                key: '38_29',
                type: 'radios',
                title: 'Do they work for 16 hours or more a week?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 36',
        page: 36,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 16: About other people who live with you (2/2)</h2></div>'
          },
          {
            type: 'help',
            htmlClass: 'help-block',
            helpvalue: '<div>This section asks if any of the people living with you have any money coming in: You do not have to answer these questions, but if they do not have much money coming in, you may get more Employment and Support Allowance. Include information about:<ul><li>earnings</li><li>social security benefits</li><li>any other money they have coming in.</li></ul>If they have earnings, tell us the amount before tax, National Insurance and any other money has been taken off. Do <b>not</b> include income from:<ul><li>The Macfarlane Trust</li><li>The Eileen Trust</li><li>The Skipton Fund</li><li>The Fund</li><li>MFET Ltd</li><li>The Caxton Foundation.</li></ul></div>'
          },
          {
            type: 'help',
            helpvalue: '<div>Person 1</div>',
            htmlClass: 'alert alert-info'
          },
          {
            key: '39_1',
            type: 'radios',
            title: 'Do they have any money coming in?',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["39_1"] === "yes"',
            items: [
              {
                key: '39_3',
                title: 'Where does the money come from?',
                description: 'This could be wages, a pension or benefits.'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>How much is coming in, and how often?</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "39_4",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "39_5",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '39_6',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"},
                        {"value": "years", "name": "Years"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '39_7',
                type: 'radios',
                title: 'Does this person usually live with you?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["39_7"] === "no"',
                items: [
                  {
                    key: '39_8',
                    type: 'textarea',
                    title: 'Where do they usually live?'
                  }
                ]
              },
              {
                key: '39_9',
                type: 'kerpdate',
                mode: 'month',
                title: 'What date did you start sharing accommodation?'
              },
              {
                key: '39_10',
                title: 'Why did you start to share accommodation?'
              },
              {
                key: '39_11',
                title: 'How long do you expect this to continue?'
              },
              {
                key: '39_12',
                title: 'Why do you think this is?'
              },
              {
                key: 'page39_13',
                type: 'radios',
                title: 'Has the person shared accommodation with you in the past?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }
            ]
          },
          {
            type: 'help',
            helpvalue: '<div>Person 2</div>',
            htmlClass: 'alert alert-info'
          },
          {
            key: '39_14',
            type: 'radios',
            title: 'Do they have any money coming in?',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["39_14"] === "yes"',
            items: [
              {
                key: '39_16',
                title: 'Where does the money come from?',
                description: 'This could be wages, a pension or benefits.'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>How much is coming in, and how often?</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "39_17",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "39_18",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '39_19',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"},
                        {"value": "years", "name": "Years"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '39_20',
                type: 'radios',
                title: 'Does this person usually live with you?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["39_20"] === "no"',
                items: [
                  {
                    key: '39_22',
                    type: 'textarea',
                    title: 'Where do they usually live?'
                  }
                ]
              },
              {
                key: '39_23',
                type: 'kerpdate',
                mode: 'month',
                title: 'What date did you start sharing accommodation?'
              },
              {
                key: '39_24',
                title: 'Why did you start to share accommodation?'
              },
              {
                key: '39_25',
                title: 'How long do you expect this to continue?'
              },
              {
                key: '39_26',
                title: 'Why do you think this is?'
              },
              {
                key: 'page39_27',
                type: 'radios',
                title: 'Has the person shared accommodation with you in the past?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }
            ]
          },
          {
            type: 'help',
            helpvalue: '<div>Person 3</div>',
            htmlClass: 'alert alert-info'
          },
          {
            key: '40_1',
            type: 'radios',
            title: 'Do they have any money coming in?',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["40_1"] === "yes"',
            items: [
              {
                key: '40_3',
                title: 'Where does the money come from?',
                description: 'This could be wages, a pension or benefits.'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>How much is coming in, and how often?</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "40_4",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "40_5",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '40_6',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"},
                        {"value": "years", "name": "Years"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '40_7',
                type: 'radios',
                title: 'Does this person usually live with you?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["39_7"] === "no"',
                items: [
                  {
                    key: '40_8',
                    type: 'textarea',
                    title: 'Where do they usually live?'
                  }
                ]
              },
              {
                key: '40_9',
                type: 'kerpdate',
                mode: 'month',
                title: 'What date did you start sharing accommodation?'
              },
              {
                key: '40_10',
                title: 'Why did you start to share accommodation?'
              },
              {
                key: '40_11',
                title: 'How long do you expect this to continue?'
              },
              {
                key: '40_12',
                title: 'Why do you think this is?'
              },
              {
                key: 'page40_13',
                type: 'radios',
                title: 'Has the person shared accommodation with you in the past?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }
            ]
          },
          {
            type: 'help',
            helpvalue: '<div>Person 4</div>',
            htmlClass: 'alert alert-info'
          },
          {
            key: '40_14',
            type: 'radios',
            title: 'Do they have any money coming in?',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["40_14"] === "yes"',
            items: [
              {
                key: '40_16',
                title: 'Where does the money come from?',
                description: 'This could be wages, a pension or benefits.'
              },
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>How much is coming in, and how often?</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "40_17",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "40_18",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '40_19',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"},
                        {"value": "years", "name": "Years"}
                      ]
                    }]
                  }
                ]
              },
              {
                key: '40_20',
                type: 'radios',
                title: 'Does this person usually live with you?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["40_20"] === "no"',
                items: [
                  {
                    key: '40_22',
                    type: 'textarea',
                    title: 'Where do they usually live?'
                  }
                ]
              },
              {
                key: '40_23',
                type: 'kerpdate',
                mode: 'month',
                title: 'What date did you start sharing accommodation?'
              },
              {
                key: '40_24',
                title: 'Why did you start to share accommodation?'
              },
              {
                key: '40_25',
                title: 'How long do you expect this to continue?'
              },
              {
                key: '40_26',
                title: 'Why do you think this is?'
              },
              {
                key: 'page40_27',
                type: 'radios',
                title: 'Has the person shared accommodation with you in the past?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }
            ]
          },
          {
            key: '40_15',
            type: 'radios',
            title: 'Are any of these people you have told us about married to each other or living together as if they are married, or civil partners?',
            description: 'We call these people \'partners\'<br>Use Part 21 Other information if you need enter details about more than 4 people',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["40_15"] === "yes"',
            items: [
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-sm-5 col-xs-4",
                    items: [{
                      key: "page40_16",
                      notitle: true
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-2 col-xs-4",
                    items: [{
                      type: "help",
                      helpvalue: "<div>is the partner of</div>"
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-5 col-xs-4",
                    items: [
                      {
                        key: "40_30",
                        notitle: true
                      }
                    ]
                  }
                ]
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-sm-5 col-xs-4",
                    items: [{
                      key: "page40_17",
                      notitle: true
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-2 col-xs-4",
                    items: [{
                      type: "help",
                      helpvalue: "<div>is the partner of</div>"
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-sm-5 col-xs-4",
                    items: [
                      {
                        key: "page40_32",
                        notitle: true
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 37',
        page: 37,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 17: Owning your home (1/2)</h2></div>'
          },
          {
            key: '41_1', //TODO go to Part 18
            type: 'radios',
            title: 'Do you or your partner own your own home?',
            description: 'If the home is on a mortgage or loan, or if it is leasehold or freehold, tick Yes.',
            "titleMap": [
              {"value": "no, go to Part 18 Living in a care home.", "name": "No"},
              {
                "value": "yes, make sure you fill in form HCTB1 to claim Council Tax Benefit and send it to the council.",
                "name": "Yes"
              }
            ]
          },
          {
            type: 'section',
            condition: 'model["41_1"] === "yes, make sure you fill in form HCTB1 to claim Council Tax Benefit and send it to the council."',
            items: [
              {
                key: '41_3',
                type: 'radios',
                title: 'Do you or your partner have a mortgage or loan on your home?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model["41_3"] === "yes" && model["41_1"] === "yes, make sure you fill in form HCTB1 to claim Council Tax Benefit and send it to the council."',
            items: [
              {
                key: '41_5',
                type: 'checkboxes',
                title: 'Whose name is the mortgage or loan in?',
                "titleMap": [
                  {"value": "yours", "name": "Yours"}
                ]
              },
              {
                key: '41_6',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "your partner's", "name": "Your partner's"}
                ]
              },
              {
                key: '41_7',
                notitle: true,
                type: 'checkboxes',
                "titleMap": [
                  {"value": "both", "name": "Both"}
                ]
              },
              {
                key: '41_10',
                title: 'When was it taken out?',
                mode: 'month',
                type: 'kerpdate'
              },
              {
                key: '41_11',
                type: 'radios',
                title: 'Is the mortgage or home loan for anything apart from buying the place where you live?',
                description: 'For example, a piece of land, a car, home improvements or repairs.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["41_11"] === "yes"',
                items: [{
                  key: '41_13',
                  type: 'textarea',
                  title: 'What is it for?'
                }]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model["41_1"] === "yes, make sure you fill in form HCTB1 to claim Council Tax Benefit and send it to the council." && model["41_3"] === "yes"',
            items: [{
              key: '41_14',//TODO go to Part 18
              type: 'radios',
              title: 'Do you or your partner have a second mortgage, a home improvement loan or loan for repairs?',
              "titleMap": [
                {"value": "no", "name": "No"},
                {"value": "yes", "name": "Yes"}
              ]
            }]
          },
          {
            type: 'section',
            condition: 'model["41_14"] === "yes" && model["41_1"] === "yes, make sure you fill in form HCTB1 to claim Council Tax Benefit and send it to the council."',
            items: [
              {
                key: '41_16',
                type: 'radios',
                title: 'Whose name is the mortgage or loan in?',
                "titleMap": [
                  {"value": "yours", "name": "Yours"},
                  {"value": "your partner's", "name": "Your partner's"},
                  {"value": "both", "name": "Both"}
                ]
              },
              {
                key: '41_21',
                title: 'When was it taken out?',
                mode: 'month',
                type: 'kerpdate'
              },
              {
                key: '41_22',
                type: 'radios',
                title: 'Is the mortgage or home loan for anything apart from buying the place where you live?',
                description: 'For example, a piece of land, a car, home improvements or repairs.',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "If yes, please tell us what it is for.", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["41_22"] === "If yes, please tell us what it is for."',
                items: [{
                  key: '41_24',
                  type: 'textarea',
                  title: 'What is it for?'
                }]
              }
            ]
          },
          {
            type: 'section',
            condition: 'model["41_1"] === "yes, make sure you fill in form HCTB1 to claim Council Tax Benefit and send it to the council." && model["41_3"] === "yes"',
            items: [{
              key: '41_25',
              type: 'radios',
              title: 'Are any of your loans secured on the property?',
              "titleMap": [
                {"value": "no", "name": "No"},
                {"value": "Yes", "name": "Yes"}
              ]
            }]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 38',
        page: 38,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 17: Owning your home (2/2)</h2></div>'
          },
          {
            key: '42_1',
            type: 'radios',
            title: 'Was your original mortgage taken out before October 1995?',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["42_1"] === "yes"',
            items: [
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Original mortgage</div>'
              },
              {
                key: '42_3',
                title: 'Who was the mortgage or home loan lender?'
              },
              {
                key: '42_4',
                title: 'Whose name was the mortgage or home loan in?'
              },
              {
                key: '42_5',
                title: 'When was it taken out?',
                mode: 'month',
                type: 'kerpdate'
              },
              {
                key: '42_6',
                type: 'radios',
                title: 'Which address was this mortgage or home loan for?',
                "titleMap": [
                  {"value": "current address", "name": "Current address"},
                  {"value": "previous address", "name": "Previous address"}
                ]
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Remortgage or home loan 1</div>'
              },
              {
                key: '42_8',
                title: 'Who was the mortgage or home loan lender?'
              },
              {
                key: '42_9',
                title: 'Whose name was the mortgage or home loan in?'
              },
              {
                key: '42_10',
                title: 'When was it taken out?',
                mode: 'month',
                type: 'kerpdate'
              },
              {
                key: '42_11',
                type: 'radios',
                title: 'Which address was this mortgage or home loan for?',
                "titleMap": [
                  {"value": "current address", "name": "Current address"},
                  {"value": "previous address", "name": "Previous address"}
                ]
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Remortgage or home loan 2</div>'
              },
              {
                key: '42_13',
                title: 'Who was the mortgage or home loan lender?'
              },
              {
                key: '42_14',
                title: 'Whose name was the mortgage or home loan in?'
              },
              {
                key: '42_15',
                title: 'When was it taken out?',
                mode: 'month',
                type: 'kerpdate'
              },
              {
                key: '42_16',
                type: 'radios',
                title: 'Which address was this mortgage or home loan for?',
                description: 'Use Part 21 other information if you or your partner have more than 2 remortgages or home loans.',
                "titleMap": [
                  {"value": "current address", "name": "Current address"},
                  {"value": "previous address", "name": "Previous address"}
                ]
              }
            ]
          },
          {
            key: '42_18',
            type: 'radios',
            title: 'Do you or your partner have an insurance policy to pay the mortgage or home loan if you become unemployed or ill?',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["42_18"] === "yes"',
            items: [
              {
                key: '42_20',
                type: 'radios',
                title: 'Have you made a claim on the insurance policy?',
                "titleMap": [
                  {"value": "yes", "name": "Yes"},
                  {"value": "no", "name": "No"}
                ]
              }
            ]
          },
          {
            key: '42_22',
            type: 'radios',
            title: 'Is any part of the place where you live rated as a business',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            key: '42_24',
            type: 'radios',
            title: 'Do you or your partner pay ground rent?',
            description: 'Please send us proof of your ground rent. For example, your lease.',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["42_24"] === "yes"',
            items: [
              {
                type: 'help',
                htmlClass: 'helpTitle',
                helpvalue: '<div>How much is paid, and how often?</div>'
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "42_26",
                      title: '£'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: "42_27",
                      title: 'every',
                      type: 'number'
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-4",
                    items: [{
                      key: '42_28',
                      type: 'select',
                      title: 'Frequency',
                      "titleMap": [
                        {"value": "weeks", "name": "Weeks"},
                        {"value": "months", "name": "Months"}
                      ]
                    }]
                  }
                ]
              }
            ]
          },
          {
            key: '42_29',
            type: 'radios',
            title: 'Is your or your partner\'s home leasehold?',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["42_29"] === "yes"',
            items: [
              {
                key: '42_31',
                type: 'radios',
                title: 'When the lease was first granted, was it for more than 21 years?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              }
            ]
          },
          {
            key: '42_33',
            title: 'How many rooms are there in your home?',
            description: 'Do not count the kitchen, hall, bathroom or toilet.',
            type: 'number'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 39',
        page: 39,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 18: Living in a care home</h2></div>'
          },
          {
            type: "fieldset",
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '43_1',
                type: 'radios',
                title: 'Do you live in a care home?',
                "titleMap": [
                  {"value": "no", "name": "No"},
                  {"value": "yes", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["43_1"] === "yes"',
                items: [
                  {
                    key: '43_5',
                    type: 'radios',
                    title: 'Are you paying for this care out of your savings?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    key: '43_9',
                    type: 'radios',
                    title: 'Are you living in a care home temporarily?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    htmlClass: 'formField',
                    condition: 'model["43_9"] === "yes"',
                    items: [
                      {
                        key: '43_13',
                        title: 'How long do you expect to stay?'
                      }
                    ]
                  },
                  {
                    key: '43_15',
                    type: 'radios',
                    title: 'Are your friends or family paying for this care?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    key: '43_18',
                    type: 'radios',
                    title: 'Did you ever own your home before you moved to where you live now?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  }
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?"',
                items: [
                  {
                    type: 'help',
                    helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
                  },
                  {
                    key: '43_3',
                    type: 'radios',
                    title: 'Does your partner live in a care home?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    htmlClass: 'formField',
                    condition: 'model["43_3"] === "yes"',
                    items: [
                      {
                        key: '43_7',
                        type: 'radios',
                        title: 'Is your partner paying for this care out of your savings?',
                        "titleMap": [
                          {"value": "no", "name": "No"},
                          {"value": "yes", "name": "Yes"}
                        ]
                      },
                      {
                        key: '43_11',
                        type: 'radios',
                        title: 'Is your partner living in a care home temporarily?',
                        "titleMap": [
                          {"value": "no", "name": "No"},
                          {"value": "yes", "name": "Yes"}
                        ]
                      },

                      {
                        type: 'section',
                        htmlClass: 'formField',
                        condition: 'model["43_11"] === "yes"',
                        items: [
                          {
                            key: '43_14',
                            title: 'How long does your partner expect to stay?'
                          }
                        ]
                      },


                      {
                        key: '43_19',
                        type: 'radios',
                        title: 'Did you or your partner ever own your home before you moved to where you live now?',
                        "titleMap": [
                          {"value": "no", "name": "No"},
                          {"value": "yes", "name": "Yes"}
                        ]
                      }
                    ]
                  }
                ]
              },
              {
                type: 'section',
                condition: 'model["43_18"] === "yes" || model["43_19"] === "yes"',
                htmlClass: 'formField',
                items: [
                  {
                    type: 'help',
                    helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>Both of you</h3></div>'
                  },
                  {
                    key: '43_20',
                    type: 'radios',
                    title: 'Who owned the home?',
                    "titleMap": [
                      {"value": "you", "name": "You"},
                      {"value": "your partner", "name": "Your partner"},
                      {"value": "both of you", "name": "Both of you"}
                    ]
                  },
                  {
                    key: '43_23',
                    type: 'radios',
                    title: 'Has it been sold?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["43_23"] === "yes"',
                    items: [
                      {
                        key: '43_25',
                        title: 'When was it sold?',
                        mode: 'month',
                        type: 'kerpdate'
                      },
                      {
                        key: '43_26',
                        title: 'How much was it sold for - £'
                      },
                      {
                        key: '43_27',
                        type: 'radios',
                        title: 'If it has not been sold, does anyone live there?',
                        description: 'If the answer is yes, the Department for Work and Pensions will write to you about this.',
                        "titleMap": [
                          {"value": "no", "name": "No"},
                          {"value": "yes", "name": "Yes"}
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 40',
        page: 40,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 19: Special circumstances (1/5)</h2></div>'
          },
          {
            type: "fieldset",
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '44_1',
                type: 'radios',
                title: 'Have you separated from a person who used to be your partner in the last 6 months?',
                description: 'Use Part 21 Other information if you need to enter details about more than one person.',
                "titleMap": [
                  {"value": "no, go to page 42", "name": "No"},
                  {"value": "yes, please tell us about this below.", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["44_1"] === "yes, please tell us about this below."',
                items: [
                  {
                    key: '44_3',
                    title: 'Surname'
                  },
                  {
                    key: '44_4',
                    title: 'Other names'
                  },
                  {
                    key: '44_5',
                    title: 'Address',
                    type: 'textarea'
                  },
                  {
                    key: '44_6',
                    type: 'memorabledate',
                    title: 'Date of birth',
                    validationMessage: 'The date of birth is incorrect'
                  },
                  {
                    key: '44_7',
                    title: 'National Insurance (NI) number, if you know it'
                  },
                  {
                    key: '44_8',
                    title: 'When did you separate?',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '44_9',
                    type: 'radios',
                    title: 'Is this separation temporary?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["44_9"] === "yes"',
                    items: [
                      {
                        key: '44_11',
                        title: 'Reason for the separation and how long you expect it to last',
                        type: 'textarea'
                      }
                    ]
                  },
                  {
                    key: '44_12',
                    type: 'radios',
                    title: 'Has this person gone abroad?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["44_12"] === "yes"',
                    items: [
                      {
                        key: '44_14',
                        type: 'radios',
                        title: 'Have they gone abroad permanently?',
                        "titleMap": [
                          {"value": "no", "name": "No"},
                          {"value": "yes", "name": "Yes"}
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?"',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '44_16',
                type: 'radios',
                title: 'Have you separated from a person who used to be your partner in the last 6 months?',
                description: 'Use Part 21 Other information if you need to enter details about more than one person.',
                "titleMap": [
                  {"value": "no, go to page 42", "name": "No"},
                  {"value": "yes, please tell us about this below.", "name": "Yes"}
                ]
              },
              {
                type: 'section',
                condition: 'model["44_16"] === "yes, please tell us about this below."',
                items: [
                  {
                    key: '44_18',
                    title: 'Surname'
                  },
                  {
                    key: '44_19',
                    title: 'Other names'
                  },
                  {
                    key: '44_20',
                    title: 'Address',
                    type: 'textarea'
                  },
                  {
                    key: '44_21',
                    type: 'memorabledate',
                    title: 'Date of birth',
                    validationMessage: 'The date of birth is incorrect'
                  },
                  {
                    key: '44_22',
                    title: 'National Insurance (NI) number, if you know it'
                  },
                  {
                    key: '44_23',
                    title: 'When did you separate?',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '44_24',
                    type: 'radios',
                    title: 'Is this separation temporary?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["44_24"] === "yes"',
                    items: [
                      {
                        key: '44_26',
                        title: 'Reason for the separation and how long you expect it to last',
                        type: 'textarea'
                      }
                    ]
                  },
                  {
                    key: '44_27',
                    type: 'radios',
                    title: 'Has this person gone abroad?',
                    "titleMap": [
                      {"value": "no", "name": "No"},
                      {"value": "yes", "name": "Yes"}
                    ]
                  },
                  {
                    type: 'section',
                    condition: 'model["44_27"] === "yes"',
                    items: [
                      {
                        key: '44_29',
                        type: 'radios',
                        title: 'Have they gone abroad permanently?',
                        "titleMap": [
                          {"value": "no", "name": "No"},
                          {"value": "yes", "name": "Yes"}
                        ]
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 41',
        page: 41,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 19: Special circumstances (2/5)</h2></div>'
          },
          {
            key: '45_1',
            type: 'radios',
            title: 'Will the person who used to be your partner keep paying anything towards the rent or mortgage, or any household bills??',
            description: 'Use Part 21 Other information if you need to enter details about more payments.',
            "titleMap": [
              {"value": "no", "name": "No"},
              {"value": "yes", "name": "Yes"}
            ]
          },
          {
            type: 'section',
            condition: 'model["45_1"] === "yes"',
            items: [
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Payment 1</div>'
              },
              {
                key: '45_3',
                title: 'What is this payment for?'
              },
              {
                key: '45_4',
                title: 'How much do you expect to get?'
              },
              {
                key: '45_5',
                title: 'When will you get this payment?',
                mode: 'month',
                type: 'kerpdate'
              },
              {
                key: '45_6',
                title: 'How often will this be paid?'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Payment 2</div>'
              },
              {
                key: '45_7',
                title: 'What is this payment for?'
              },
              {
                key: '45_8',
                title: 'How much do you expect to get?'
              },
              {
                key: '45_9',
                title: 'When will you get this payment?',
                mode: 'month',
                type: 'kerpdate'
              },
              {
                key: '45_10',
                title: 'How often will this be paid?'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Payment 3</div>'
              },
              {
                key: '45_11',
                title: 'What is this payment for?'
              },
              {
                key: '45_12',
                title: 'How much do you expect to get?'
              },
              {
                key: '45_13',
                title: 'When will you get this payment?',
                mode: 'month',
                type: 'kerpdate'
              },
              {
                key: '45_14',
                title: 'How often will this be paid?'
              },
              {
                type: 'help',
                htmlClass: 'alert alert-info',
                helpvalue: '<div>Payment 4</div>'
              },
              {
                key: '45_15',
                title: 'What is this payment for?'
              },
              {
                key: '45_16',
                title: 'How much do you expect to get?'
              },
              {
                key: '45_17',
                title: 'When will you get this payment?',
                mode: 'month',
                type: 'kerpdate'
              },
              {
                key: '45_18',
                title: 'How often will this be paid?'
              }
            ]
          },
          {
            key: '45_19',
            type: 'radios',
            title: 'If the person who used to be your partner is still paying towards your mortgage, who do they make payments to?',
            "titleMap": [
              {"value": "to you", "name": "Direct to you"},
              {"value": "direct to your lender", "name": "Direct to your lender"}
            ]
          },
          {
            key: '45_21',
            type: 'radios',
            title: 'Has the person who used to be your partner stopped paying you money?',
            "titleMap": [
              {"value": "yes, please tell us about the last payment you received.", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["45_21"] === "yes, please tell us about the last payment you received."',
            items: [
              {
                key: '45_23',
                title: 'What was this payment for?'
              },
              {
                key: '45_24',
                title: 'How much did you get - £'
              },
              {
                key: '45_25',
                title: 'When was it paid?',
                mode: 'month',
                type: 'kerpdate'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 42',
        page: 42,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 19: Special circumstances (3/5)</h2></div>'
          },
          {
            type: "fieldset",
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '46_1',
                type: 'radios',
                title: 'If a UK national, do you or your partner have the right of abode in the UK?',
                description: '<p><b>Everyone must answer these questions, if you do not your claim may be delayed.</b><br>United Kingdom means England, Scotland, Wales and Northern Ireland.</br></p>For example, you have the right of abode in the UK<ul><li>if you are a British citizen.</li></ul>By the right of abode we mean you<ul><li>are free from immigration control, and</li><li>do not need the permission of an immigration officer to enter the UK, and</li><li>can live and work in the UK without restriction</li></ul>',
                "titleMap": [
                  {"value": "Yes", "name": "Yes"},
                  {"value": "No", "name": "No"}
                ]
              },
              {
                key: '46_3',
                type: 'radios',
                title: 'At any time, have you or your partner come to live or returned to live in the United Kingdom (UK) from abroad?',
                description: 'Please send passport or immigration documents for the people you tell us about below with this form, or you can bring the passport or documents to your local Jobcentre Plus.<br>You can find the phone number and address on the advert in the business numbers section of the phone book. Look under Jobcentre Plus.<br>',
                "titleMap": [
                  {"value": "yes, please tell us about this below.", "name": "Yes"},
                  {"value": "no", "name": "No"}
                ]
              },
              {
                type: 'section',
                condition: 'model["46_3"] === "yes, please tell us about this below."',
                items: [
                  {
                    key: '46_5',
                    title: 'What is your nationality'
                  },
                  {
                    key: '46_6',
                    title: 'Which country have you come from?'
                  },
                  {
                    key: '46_7',
                    title: 'What date did you last come to the UK?',
                    mode: 'month',
                    type: 'kerpdate'
                  },
                  {
                    key: '46_10',
                    type: 'radios',
                    title: 'Was this to work in the UK?',
                    "titleMap": [
                      {"value": "yes", "name": "Yes"},
                      {"value": "no", "name": "No"}
                    ]
                  },
                  {
                    key: '46_12',
                    type: 'radios',
                    title: 'Has the Home Office put a limit on how long you can stay in the UK?',
                    "titleMap": [
                      {"value": "yes", "name": "Yes"},
                      {"value": "no", "name": "No"}
                    ]
                  }
                ]
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?"',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '46_15',
                type: 'radios',
                title: 'If a UK national, do you or your partner have the right of abode in the UK?',
                "titleMap": [
                  {"value": "yes", "name": "Yes"},
                  {"value": "no", "name": "No"}
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 43',
        page: 43,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 19: Special circumstances (4/5)</h2></div>'
          },
          {
            type: "fieldset",
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '47_1',
                type: 'radios',
                title: 'Does your passport say no recourse to public funds?',
                "titleMap": [
                  {"value": "yes", "name": "Yes"},
                  {"value": "no", "name": "No"}
                ]
              },
              {
                key: '47_3',
                title: 'If you have lived in the UK before, when did you last leave the UK?',
                mode: 'year',
                type: 'kerpdate'
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?"',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '47_4',
                type: 'radios',
                title: 'Does your passport say no recourse to public funds?',
                "titleMap": [
                  {"value": "yes", "name": "Yes"},
                  {"value": "No", "name": "No"}
                ]
              },
              {
                key: '47_6',
                title: 'If you have lived in the UK before, when did you last leave the UK?',
                mode: 'year',
                type: 'kerpdate'
              }
            ]
          },
          {
            type: 'fieldset',
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About both of you</h3></div>'
              },
              {
                key: '47_7',
                type: 'radios',
                title: 'Have you or your partner come to the UK under the Family Reunion Scheme?',
                "titleMap": [
                  {"value": "yes", "name": "Yes"},
                  {"value": "no", "name": "No"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["47_7"] === "no"',
                items: [
                  {
                    key: '47_9',
                    type: 'radios',
                    title: 'Have you or your partner come to the UK under a sponsorship undertaking?',
                    description: 'A sponsorship undertaking is a form that a relative must sign to say that they will pay for your living expenses if you settle in the UK. You can find out more by visiting <a target="_blank" href="http://www.gov.uk/browse/citizenship">www.gov.uk/browse/citizenship</a><p>A sponsorship undertaking is not the same as the Family Reunion Scheme.</p>',
                    "titleMap": [
                      {"value": "yes", "name": "Yes"},
                      {"value": "no", "name": "No"}
                    ]
                  },
                  {
                    type: 'section',
                    htmlClass: 'formField',
                    condition: 'model["47_9"] === "yes"',
                    items: [
                      {
                        key: '47_11',
                        title: 'Who is being sponsored?'
                      },
                      {
                        key: '47_12',
                        title: 'Name of the sponsor'
                      },
                      {
                        key: '47_13',
                        title: 'Address of the sponsor',
                        type: 'textarea'
                      },
                      {
                        key: '47_14',
                        title: 'Home Office reference number'
                      },
                      {
                        key: '47_15',
                        title: 'What date did the sponsor sign the sponsorship undertaking?',
                        description: 'Use Part 21 Other information if more than one sponsor signed the sponsorship undertaking. The DWP may get in touch with you for more information.'
                      }
                    ]
                  }
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 44',
        page: 44,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 19: Special circumstances (5/5)</h2></div>'
          },
          {
            type: 'help',
            htmlClass: 'help-block',
            helpvalue: '<div>Please answer all of these questions, even if you think they do not apply to you.</div>'
          },
          {
            type: "fieldset",
            htmlClass: 'formField',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About you</h3></div>'
              },
              {
                key: '48-1',
                type: 'radios',
                title: 'Are you or your partner an asylum seeker?',
                "titleMap": [
                  {"value": "yes", "name": "Yes"},
                  {"value": "no", "name": "No"}
                ]
              },
              {
                key: '48-5',
                type: 'radios',
                title: 'Did you first apply for asylum before 3 April 2000?',
                description: 'If you are still an asylum seeker, you will not usually be entitled to benefit. But you may be entitled to get help from the Home Office. Send proof of the asylum application with this form. Or you can bring the proof to your local Jobcentre Plus.',
                "titleMap": [
                  {
                    "value": "yes, send us proof of the asylum application with this form. Or you can bring the proof to your local Jobcentre Plus.",
                    "name": "Yes"
                  },
                  {
                    "value": "no, if you are still an asylum seeker, you will not usually be entitled to benefit. But you may be entitled to get help from the Home Office",
                    "name": "No"
                  }
                ]
              },
              {
                key: '48-9',
                type: 'radios',
                title: 'Have you or your partner recently had a successful decision on your asylum application?',
                description: 'Send proof of the decision with this form. Or you can bring the proof to your local Jobcentre Plus.',
                "titleMap": [
                  {"value": "yes", "name": "Yes"},
                  {"value": "no", "name": "No"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["48-9"] === "yes"',
                items: [
                  {
                    key: '47--11',
                    title: 'What was the date when you got the successful decision of your asylum application?',
                    mode: 'month',
                    type: 'kerpdate'
                  }
                ]
              },
              {
                key: '48--9',
                type: 'radios',
                title: 'Have you or your partner been supported by the Home Office while waiting for a decision on your asylum application?',
                description: 'Send details of any support given to you by the Home Office. For example, a letter from the Home Office which is about these things.',
                "titleMap": [
                  {
                    "value": "yes, send us details of any support given to you by the Home Office. For example, a leter from the Home Office which tells us about these things",
                    "name": "Yes"
                  },
                  {"value": " No, please go to Part 20", "name": "No"}
                ]
              }
            ]
          },
          {
            type: 'section',
            htmlClass: 'formField',
            condition: 'model["5_4"] === "yes - does your partner, if you have one, agree to you making this claim?"',
            items: [
              {
                type: 'help',
                helpvalue: '<div><h3 class=\'formPartTitle alert alert-info\'>About your partner</h3></div>'
              },
              {
                key: '48-3',
                type: 'radios',
                title: 'Are you or your partner an asylum seeker?',
                "titleMap": [
                  {"value": "yes", "name": "Yes"},
                  {"value": "no", "name": "No"}
                ]
              },
              {
                key: '48-7',
                type: 'radios',
                title: 'Did you first apply for asylum before 3 April 2000?',
                description: 'If you are still an asylum seeker, you will not usually be entitled to benefit. But you may be entitled to get help from the Home Office. Send proof of the asylum application with this form. Or you can bring the proof to your local Jobcentre Plus.',
                "titleMap": [
                  {
                    "value": "yes, send us proof of the asylum application with this form. Or you can bring the proof to your local Jobcentre Plus",
                    "name": "Yes"
                  },
                  {
                    "value": "no, if you are still an asylum seeker, you will not usually be entitled to benefit. But you may be entitled to get help from the Home Office.",
                    "name": "No"
                  }
                ]
              },
              {
                key: '48--11',
                type: 'radios',
                title: 'Have you or your partner recently had a successful decision on your asylum application?',
                description: 'Send proof of the decision with this form. Or you can bring the proof to your local Jobcentre Plus.',
                "titleMap": [
                  {
                    "value": "yes, send us proof of the decision with this form. Or you can bring the proof to your local Jobcentre Plus",
                    "name": "Yes"
                  },
                  {"value": "no", "name": "No"}
                ]
              },
              {
                type: 'section',
                htmlClass: 'formField',
                condition: 'model["48--11"] === "yes, send us proof of the decision with this form. Or you can bring the proof to your local Jobcentre Plus"',
                items: [
                  {
                    key: '47--13',
                    title: 'What was the date when you got the successful decision of your asylum application?',
                    mode: 'month',
                    type: 'kerpdate'
                  }
                ]
              },
              {
                key: '48-11',
                type: 'radios',
                title: 'Have you or your partner been supported by the Home Office while waiting for a decision on your asylum application?',
                description: 'Send details of any support given to you by the Home Office. For example, a letter from the Home Office which is about these things.',
                "titleMap": [
                  {
                    "value": "Yes, send us details of any support given to you by the Home Office. For example, a letter from the Home Office which tells us about these things",
                    "name": "Yes"
                  },
                  {"value": "no, please go to Part 20", "name": "No"}
                ]
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 45',
        page: 45,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 20: How we pay you (1/2)</h2></div>'
          },
          {
            type: 'help',
            htmlClass: 'help-block',
            helpvalue: '<div><b>Your money will normally be paid into an account.<br /></b><br />Many banks and building societies will let you collect your money at the post office.<br /><br />The Department for Work and Pensions (DWP) will tell you when they will make the first payment and how much it will be for.<br /><br />They will tell you if the amount they pay into the account is going to change.<br /><br /><b>Finding out how much they have paid into the account<br /></b><br />You can check your payments on account statements. The statements may show your National Insurance (NI) number next to any payments they have made. If you think a payment is wrong, get in touch with the office that pays you straight away.<br /><br /><b>If they pay you too much money<br /></b><br />If they pay you too much money they have the right to take back any money they pay that you are not entitled to. This may be because of the way the payment system works.<br /><br />For example, you may give some information which means you are entitled to less money. Sometimes they may not be able to change the amount they have already paid you. This means they will have paid you money that you are not entitled to.<br /><br /><b>They will contact you before they take back any money.</b></div>'
          },
          {
            type: 'help',
            htmlClass: 'help-block',
            helpvalue: '<div></b><br /><h3>What to do now</h3><br />Give the details of the account you want to use on the next page. By giving your account details you<ul><li>agree that the DWP will pay your money into an account, and</li><li>understand what you have been told about being paid too much money</li></ul>If you are going to open an account, contact the DWP with your account details as soon as you get them.<br />Fill in the rest of this form. You do not have to wait until you have opened an account.</div>'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 46',
        page: 46,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 20: How we pay you (2/2)</h2></div>'
          },
          {
            type: 'help',
            htmlClass: 'help-block',
            helpvalue: '<div>About the account you want to use<ul><li>You can use an account in your name, or a joint account.</li><li>You can use someone else’s account if the terms and conditions of their account allow this, and they agree to let you use their account, and you are sure they will use your money in the way you tell them.</li><li>You can use a credit union account. You must give the credit union’s account details. Your credit union will be able to help you with this.</li><li>If you are an appointee or a legal representative acting on behalf of the claimant, the account should be in your name only.</li></ul></div>'
          },
          {
            type: 'help',
            helpvalue: '<div><label>Please tell us your account details below.</label></div>'
          },
          {
            type: 'help',
            htmlClass: 'help-block',
            helpvalue: '<div>It is very important you fill in all the boxes correctly, including the building society roll or reference number, if you have one. <br />If you tell us the wrong account details your payment may be delayed or you may lose money.<br />You can find the account details on your cheque book or bank statements.<br />If you do not know the account details, ask the bank or building society.</div>'
          },
          {
            key: '50_1',
            title: 'Name of the account holder',
            description: 'Please write the name of the account holder exactly as it is shown on the chequebook or statement.'
          },
          {
            key: '50_2',
            title: 'Full name of bank or building society'
          },
          {
            type: 'help',
            htmlClass: 'helpTitle',
            helpvalue: '<div>Sort code</div>'
          },
          {
            type: "section",
            htmlClass: "row",
            items: [
              {
                type: "section",
                htmlClass: "col-xs-4 col-sm-2",
                items: [{
                  key: "50_3",
                  notitle: true,
                  placeholder: '12'
                }]
              },
              {
                type: "section",
                htmlClass: "col-xs-4 col-sm-2",
                items: [{
                  key: "50_4",
                  notitle: true,
                  placeholder: '34'
                }]
              },
              {
                type: "section",
                htmlClass: "col-xs-4 col-sm-2",
                items: [{
                  key: "50_5",
                  notitle: true,
                  placeholder: '56'
                }]
              }
            ]
          },
          {
            type: 'help',
            htmlClass: 'help-block',
            helpvalue: '<div>Please tell us all 6 numbers, for example:12-34-56</div>'
          },
          {
            key: '50_6',
            title: 'Account number',
            validationMessage: 'Account numbers have at least 8 digits'
          },
          {
            key: '50_7',
            title: 'Building society roll or reference number',
            description: 'If you are using a building society account you may need to enter a roll or reference number. This may be made up of letters and numbers, and may be up to 18 characters long. If you are not sure if the account has a roll or reference number, ask the building society.'
          },
          {
            key: '50_8',
            type: 'checkboxes',
            title: 'You may get other benefits and entitlements we do not pay into an account. If you want us to pay them into the account above, please tick this box ',
            "titleMap": [
              {"value": "1", "name": ""}
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 47',
        page: 47,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 21: Other Information</h2></div>'
          },
          {
            key: '51_2',
            title: 'Space to give more information',
            type: 'textarea',
            description: 'Please use this space to tell us anything else you think we might need to know.<br />If there is not enough space, please use a separate sheet of paper. Make sure that you<ul><li>tell us who the information is about, and</li><li>put your full name and National Insurance number on each sheet of paper, and</li><li>sign and date each sheet that you use</li></ul>'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 48',
        page: 48,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 22: Filling in this form for someone else (1/2)</h2></div>'
          },
          {
            key: '52_1',
            type: 'radios',
            title: 'Are you filling in this form for someone else?',//todo go to part 23 if response is no
            "titleMap": [
              {"value": "yes, please tell us about yourself below.", "name": "Yes"},
              {"value": "no, please go to Part 23Declaration", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["52_1"] === "yes, please tell us about yourself below."',
            items: [
              {
                key: '52_3',
                title: "Surname"
              },
              {
                key: '52_4',
                title: "Other names"
              },
              {
                key: '52_5',
                title: "Any other surnames you have been known by"
              },
              {
                type: "section",
                htmlClass: "row",
                items: [
                  {
                    type: "section",
                    htmlClass: "col-xs-3 col-md-2",
                    items: [{
                      key: '52_6',
                      type: 'select',
                      title: 'Title',
                      "titleMap": [
                        {"value": "Mr", "name": "Mr"},
                        {"value": "Mrs", "name": "Mrs"},
                        {"value": "Miss", "name": "Miss"},
                        {"value": "Ms", "name": "Ms"},
                        {"value": "Prof", "name": "Prof"},
                        {"value": "Rev", "name": "Rev"}
                      ]
                    }]
                  },
                  {
                    type: "section",
                    htmlClass: "col-xs-9 col-md-10",
                    items: [{
                      key: "52_7",
                      title: "Other title"
                    }]
                  }
                ]
              },
              {
                key: '52_8',
                type: 'memorabledate',
                title: 'Date of birth',
                validationMessage: 'The date of birth is incorrect'
              },
              {
                key: '52_9',
                title: 'National Insurance (NI) number',
                description: 'You can get this from a payslip, benefit letters or from tax papers.'
              },
              {
                key: '52_10',
                title: "Address",
                type: 'textarea'
              },
              {
                key: '52_11',
                title: "Mobile phone number"
              },
              {
                key: '52_13',
                title: "Daytime phone number if different"
              },
              {
                key: '52_15',
                title: 'Number type',
                type: 'radios',
                titleMap: [{value: 'work', name: 'Work'}, {value: 'home', name: 'Home'}, {
                  value: 'mobile',
                  name: 'Mobile'
                }],
                style: {selected: 'btn-primary', unselected: 'btn-default'}
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 49',
        page: 49,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 22: Filling in this form for someone else (2/2)</h2></div>'
          },
          {
            key: '53-1',
            type: 'radios',
            title: 'Are you signing this form for someone else?',
            description: 'Even though you can fill in this form for another adult, they must still sign it themselves unless one or more of the following apply.',
            "titleMap": [
              {"value": "yes", "name": "Yes"},
              {"value": "no", "name": "No"}
            ]
          },
          {
            type: 'section',
            condition: 'model["53-1"] === "yes"',
            items: [
              {
                key: '53-3',
                title: 'I have Power of Attorney for them',
                description: 'Please send your power of attorney document or certified copy with this claim form. Remember to sign the Declaration at Part 23.'
              },
              {
                key: '53-4',
                title: 'I am a receiver or deputy for them under a Court of Protection Order, or in Scotland a tutor, curator or guardian appointed in terms of the law.',
                description: 'Please send the relevant document or certified copy with this claim form. Remember to sign the Declaration at Part 23.'
              },
              {
                key: '53-5',
                title: 'The Department for Work and Pensions has already appointed me to get their beneﬁts and to deal with letters about their beneﬁts.',
                description: 'All letters about this claim will be sent directly to you.'
              },
              {
                key: '53-6',
                title: 'They cannot manage their own affairs because of a mental illness or a mental disability.',
                description: 'The Department for Work and Pensions may appoint you to get their benefits and to deal with letters about their benefits, and will get in touch with you about this.'
              },
              {
                key: '53-7',
                title: 'They are so ill or disabled they ﬁnd it impossible to sign for themselves.',
                description: 'The Department for Work and Pensions will get in touch with you about this.'
              },
              {
                key: '53-8',
                title: 'If the person does not know you are signing this form for them, please tell us why',
                type: 'textarea'
              }
            ]
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 50',
        page: 50,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 23: Declaration</h2></div>'
          },
          {
            key: 'Signature',
            title: 'Signature',
            type: 'textarea'
          },
          {
            key: '54_2',
            title: 'Date',
            mode: 'year',
            type: 'kerpdate'
          },
          {
            key: '54_3',
            title: 'Please tick this box if someone filled in this form for you'
          }
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 51',
        page: 51,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 24: What to do now</h2></div>'
          },
          {
            type: 'help',
            helpvalue: '<div>Check that you have answered all the questions on this form that apply to you and your partner, if you have one. Check that you have given us ALL your account details in Part 20 How we pay you if you want to be paid directly into your account.<br />Check that you have signed and dated this form.<br />Check that you have sent us all the documents we have asked for.<br />Use the checklist below. <br /><br />Proof of identity<br />It is important that we can be sure of your identity when you claim Employment and Support Allowance. <br />We may need to ask you more questions about this. We may also need to see official documents that help prove your identity.<br />A National Insurance number is not proof of identity.<br /><br />Even if you do not have all the documents we ask for, send this form back to us straight away. Send us the documents you do not have later.<br />You may lose benefit if you do not provide original documents within one month of the date that your claim form was sent to you.</div>'
          },


          {
            type: 'help',
            helpvalue: '<br /><br /><div><h2 class=\'formPartTitle\'>Your documents</h2></div>'
          },


          {
            type: 'help',
            helpvalue: '<label>About you and your partner</label>'
          },

          '55_1',

          '55_3',
          {
            type: 'help',
            helpvalue: '<label>About money</label>'
          },
          '55_5',
          '55_7',
          '55_9',
          '55_11',
          '55_13',
          {
            type: 'help',
            helpvalue: '<label>About illness or disability</label>'
          },
          '55_15',
          '55_17',
          '55_19',
          {
            type: 'help',
            helpvalue: '<label>About work, education or training</label>'
          },
          '55_21',
          '55_23',
          '55_25',


          {
            type: 'help',
            helpvalue: '<br /><br /><div><h2 class=\'formPartTitle\'>Your partner\'s documents</h2></div>'
          },


          {
            type: 'help',
            helpvalue: '<label>About you and your partner</label>'
          },

          '55_2',

          '55_4',
          {
            type: 'help',
            helpvalue: '<label>About money</label>'
          },
          '55_6',
          '55_8',
          '55_10',
          '55_12',
          '55_14',
          {
            type: 'help',
            helpvalue: '<label>About illness or disability</label>'
          },
          '55_16',
          '55_18',
          '55_20',
          {
            type: 'help',
            helpvalue: '<label>About work, education or training</label>'
          },
          '55_22',
          '55_24',
          '55_26'
        ]
      },
      {
        type: 'section',
        condition: 'model.page === 52',
        page: 52,
        items: [
          {
            type: 'help',
            helpvalue: '<div><h2 class=\'formPartTitle\'>Part 25: Where to send your form and documents</h2></div>'
          },
          {
            type: "help",
            helpvalue: '<div><p>To find out where to send your claim, please click <a target="_blank" href="http://los.direct.gov.uk/">here</a></p></div>'
          },
          {
            type: 'template',
            template: '<div><h2 class=\'formPartTitle\'>Part 26: What happens next</h2><p><a href="https://www.youtube.com/watch?v=qpj3hXQniTE" title="ESA1: Part 25 Final check">Watch this before you submit your ESA1 form.</a></p></div>'
          },
          {
            type: 'template',
            template: '<div>If you are entitled to Employment and Support Allowance we will write to tell you how your benefit has been worked out and how you will be paid. If you are not entitled to Employment and Support Allowance we will write to tell you why and what to do if you disagree with the decision. If you have claimed Housing Benefit or applied for a reduction in Council Tax, your local council will get in touch with you. We will not be able to deal with your claim and may have to send your claim form back to you if<ul><li>you have not answered all the questions on this form that apply to you and your partner, if you have one, or</li><li>you have not provided all the documents we have asked for.</li></ul><p><a href="https://www.youtube.com/watch?v=BWbO5tO2yvo" title="ESA1: Part 25 What happens next?">Watch this video to find out what to expect after you submit your form.</a></p></div>'
          }
        ]
      },
      {
        type: 'section',
        condition: 'areFormNavigationButtonsVisible()',
        htmlClass: '',
        items: [{
          type: 'actions',
          htmlClass: 'formPaginationButtons',
          items: [
            {type: 'button', style: 'btn-default', title: 'Previous', onClick: 'prev()'},
            {type: 'button', style: 'btn-primary', title: 'Next', onClick: 'next()'}
          ]
        }]
      }
    ];
    formUI.setForm(esaForm);
    formUI.getHiddenPages = function (model) {
      var hiddenPages = [];
      if (model['5_2'] === 'No- Fill in the answer box under You on pages 2 to 24. Then go to page 45.') {
        for (var i = 26; i < 45; i++) {
          hiddenPages.push(i);
        }
      }
      if (model['11_1'] === 'no, please send us your P45. Go to Part 6 About other benefits.') {
        hiddenPages.push(9);
        hiddenPages.push(10);
      }
      if (model['14_1'] === 'no - go to page 12') {
        hiddenPages.push(12);
      }
      if (model['23_1'] === 'no - go to Part 10 About permanent health insurance.') {
        hiddenPages.push(21);
      }
      if (model['38_1'] === 'no') {
        hiddenPages.push(36);
      }
      if (model['44_1'] === 'no, go to page 42') {
        hiddenPages.push(41);
      }
      if (model['52_1'] === 'no, please go to Part 23Declaration') {
        hiddenPages.push(49);
      }
      return hiddenPages;
    };

    formUI.transformPdfModel = function (pdfModel) {
      // fix an error in the PDF: in "Part 6: About other benefits" (page 11), 3 benefits are defined with a payment
      // frequency for each (weekly, monthly, etc.). For the first two benefits, these options are exclusive, but
      // for the third benefit you can choose multiple frequencies (because the fields have different names). In the PDF
      // form we present them as exclusive options (radio buttons), but this causes the wrong values to be stored in the
      // model, the code below transforms the model values to those expected by the PDF

      var pdfKeys = {
        'fortnightly': '15_40',
        'monthly': '15_41',
        '4 weekly': '15_42'
      };

      var benefit3PaymentFrequency = pdfModel['15_39'];

      if (pdfKeys.hasOwnProperty(benefit3PaymentFrequency)) {
        delete pdfModel['15_39'];
        var replacementModelKey = pdfKeys[benefit3PaymentFrequency];
        pdfModel[replacementModelKey] = benefit3PaymentFrequency;

        console.log("Replaced PDF model key 15_39 with", replacementModelKey);
      }
    };
    return formUI;

  }]);
