function KirkleesCareAssessmentCalculator(
  $window,
  calculationResultService
) {

  const { constants, calculator } = $window.kerpCfa.kirklees;


      function saveCalcResults(careType, calcResults, model) {
        calculationResultService.saveCalcResults(careType, calcResults, model);
      }

      this.recalculate = function (dirtyModel, cleanModel, isShortForm, careType) {

        var recalculateCareTypes = careType ? [careType] : Object.keys(constants.careTypes);
        var self = this;

        recalculateCareTypes.forEach(function (careType) {
          var calcResults = self.calculate(cleanModel, isShortForm, careType);

          saveCalcResults(careType, calcResults, dirtyModel);
          saveCalcResults(careType, calcResults, cleanModel);
        });
      };

      this.calculate = function (fields, isShortForm, careType) {
        return calculator(fields, isShortForm, careType);
      };
}

KirkleesCareAssessmentCalculator.$inject = [
  '$window',
  'calculationResultService',
];

angular.module('kerp-forms.forms').service('kirkleesCareAssessmentCalculator', KirkleesCareAssessmentCalculator);
