'use strict';

angular
  .module('kerp-forms.forms')
  .factory('ESA1_SCHEMA', ['fieldDefinitionService', '$translate', function (fieldDefinitionService, $translate) {

    var requiredValidationMessage = $translate.instant('forms.validation.required');
    var positiveNumberValidationMessage = $translate.instant('forms.validation.positiveNumber');

    var positiveNumberValidation = {
      type: 'number',
      minimum: 0,
      validationMessage: positiveNumberValidationMessage
    };

    var weekOfDay = {
      type: "string",
      'enum': ["Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday", "Sunday"]
    };

    var schema = {
      type: 'object',
      properties: {
        "4_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ],
          validationMessage: requiredValidationMessage
        },
        "4_3": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ],
          validationMessage: requiredValidationMessage
        },
        "4_5": {
          type: "number",
          minimum: 0,
          validationMessage: "Please enter the number of jobs you have using digits (e.g. 2)"
        },
        "4_6": {
          type: "string",
          'enum': [
            "No - you may be able to get Statutory Sick Pay instread of Employment and Support Allowance. Ask your employer about Statutory Sick Pay before you fill in this form.",
            "Yes - you must send us a separate form SSP1 for each job you do along with this form."
          ],
          validationMessage: requiredValidationMessage
        },
        "5_1": {
          type: "string",
          validationMessage: requiredValidationMessage
        },
        "5_2": {
          type: "string",
          'enum': [
            "No- Fill in the answer box under You on pages 2 to 24. Then go to page 45.",
            "yes - fill in the answer boxes under You and Your partner on pages 2 to 24. Then go to page 25."
          ],
          validationMessage: requiredValidationMessage
        },
        "5_4": {
          type: "string",
          'enum': [
            "No, please go to Part 2",
            "yes - does your partner, if you have one, agree to you making this claim?"
          ],
          validationMessage: requiredValidationMessage
        },
        "5_5": {
          type: "string",
          'enum': [
            "Yes - your partner may need to go to a work focused interview if you are claiming for them.",
            "No - still tell us as much as you can about your partner. We will get in touch with you about this."
          ]
        },
        "6_4": {
          type: "string",
          validationMessage: requiredValidationMessage
        },
        "6_1": {
          type: "string",
          minLength: 2,
          validationMessage: "Surname must be at least 2 letters long"
        },
        "6_2": {
          type: "string"
        },
        "6_6": {
          type: "string",
          validationMessage: requiredValidationMessage
        },
        "6_7": fieldDefinitionService.NINO.fullNumber(),
        "6_8": {
          type: "string",
          validationMessage: "You have to fill in your address"
        },
        "6_9": {
          type: "string"
        },
        "6_10": fieldDefinitionService.phone.fullNumber(),
        "6_12": {
          type: 'string',
          pattern: '^[\\+0-9 ]{10,15}$',
          validationMessage: 'Your phone number is not valid'
        },
        "6_13": {
          type: "string",
          'enum': [
            "work",
            "home"
          ]
        },
        "6_15": {
          type: "string",
          validationMessage: "You have to fill in your nationality"
        },
        "6_19": {
          type: "string",
          validationMessage: requiredValidationMessage
        },
        "6_16": {
          type: "string",
          minLength: 2,
          validationMessage: "Your partner's surname must contain at least 2 letters"
        },
        "6_17": {
          type: "string"
        },
        "6_21": {
          type: "string",
          validationMessage: "Your partner's surname must contain at least 2 letters"
        },
        "6_22": fieldDefinitionService.NINO.fullNumber(),
        "6_23": {
          type: "string",
          validationMessage: "You have to fill in your partner's address"
        },
        "6_24": {
          type: "string"
        },
        "6_25": {
          type: "number",
          validationMessage: "You have to fill in your partner mobile number"
        },
        "6_27": {
          type: "number"
        },
        "6_28": {
          type: "string",
          'enum': [
            "work",
            "home"
          ]
        },
        "6_30": {
          type: "string",
          validationMessage: "You have to fill in your partner nationality"
        },
        "7_1": {
          type: "boolean",
          title: "If you are homeless but have a temporary address, even if this changes from day-to-day, please tick this box."
        },
        "7_2": {
          type: "boolean",
          title: "If you are homeless and have nowhere to live at all, please tick this box."
        },
        "7_3": {
          type: "string"
        },
        "7_4": {
          type: "boolean",
          title: "Married or civil partner"
        },
        "7_5": {
          type: "boolean",
          title: "Divorced or civil partnership dissolved"
        },
        "7_6": {
          type: "boolean",
          title: "Single"
        },
        "7_7": {
          type: "boolean",
          title: "Separated"
        },
        "7_8": {
          type: "boolean",
          title: "Living together"
        },
        "7_9": {
          type: "boolean",
          title: "Widowed or surviving civil partner"
        },
        "7_10": {
          type: "string"
        },
        "7_11": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ],
          validationMessage: "Your partner's surname must contain at least 2 letters"
        },
        "7_13": {
          type: "string"
        },
        "7_14": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "7_16": {
          type: "string"
        },
        "7_17": {
          type: "boolean",
          title: "If your partner is homeless but have a temporary address, even if this changes from day-to-day, please tick this box."
        },
        "7_18": {
          type: "boolean",
          title: "If your partner is homeless and have nowhere to live at all, please tick this box."
        },
        "7_19": {
          type: "string"
        },
        "7_20": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Married or civil partner"
            ]
          }
        },
        "7_21": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Divorced or civil partnership dissolved"
            ]
          }
        },
        "7_22": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "single"
            ]
          }
        },
        "7_23": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "separated"
            ]
          }
        },
        "7_24": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Living together"
            ]
          }
        },
        "7_25": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "widowed or surviving civil partner"
            ]
          }
        },
        "7_26": {
          type: "string"
        },
        "7_27": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "7_29": {
          type: "string"
        },
        "7_30": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "7_32": {
          type: "string"
        },
        "8_1": {
          type: "string"
        },
        "8_2": {
          type: "string"
        },
        "8_3": {
          type: "string"
        },
        "8_4": {
          type: "string"
        },
        "8_5": fieldDefinitionService.phone.areaCode(),
        "8_6": fieldDefinitionService.phone.localNumber(),
        "8_13": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "8_15": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "8_7": {
          type: "string"
        },
        "8_8": {
          type: "string"
        },
        "8_9": {
          type: "string"
        },
        "8_10": {
          type: "string"
        },
        "8_11": fieldDefinitionService.phone.areaCode(),
        "8_12": fieldDefinitionService.phone.localNumber(),
        "9_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "9_3": {
          type: "string"
        },
        "9_4": {
          type: "string"
        },
        "9_5": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "9_7": {
          type: "string"
        },
        "9_8": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "9_10": {
          type: "string"
        },
        "9_11": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "9_13": {
          type: "string"
        },
        "9_14": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "9_16": {
          type: "string"
        },
        "9_17": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "9_19": {
          type: "string"
        },
        "9_20": {
          type: "string"
        },
        "9_21": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "9_23": {
          type: "string"
        },
        "9_24": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "9_26": {
          type: "string"
        },
        "9_27": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "9_29": {
          type: "string"
        },
        "9_30": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "9_32": {
          type: "string"
        },
        "10_1": {
          type: "string",
          'enum': [
            "no - go to Part 5 About Work",
            "yes - ask your doctor or specialist for a DS1500 report"
          ]
        },
        "10_3": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "10_5": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "11_1": {
          type: "string",
          'enum': [
            "no, please send us your P45. Go to Part 6 About other benefits.",
            "Yes, please tell us about this below."
          ]
        },
        "11_3": {
          type: "string"
        },
        "11_4": {
          type: "string"
        },
        "11_5": positiveNumberValidation,
        "11_6": positiveNumberValidation,
        "11_7": positiveNumberValidation,
        "11_8": positiveNumberValidation,
        "11_9": {
          type: "string"
        },
        "11_10": {
          type: "string"
        },
        "11_11": fieldDefinitionService.phone.areaCode(),
        "11_12": fieldDefinitionService.phone.localNumber(),
        "11_13": {
          type: "string"
        },
        "11_14": {
          type: "string"
        },
        "11_15": {
          type: "string",
          'enum': [
            "No, go to Part 6 About other benefits.",
            "Yes, please tell us about this below."
          ]
        },
        "11_17": {
          type: "string"
        },
        "11_18": {
          type: "string"
        },
        "11_19": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "11_20": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "11_21": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "11_22": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "11_23": {
          type: "string"
        },
        "11_24": {
          type: "string"
        },
        "11_25": fieldDefinitionService.phone.areaCode(),
        "11_26": fieldDefinitionService.phone.localNumber(),
        "11_27": {
          type: "string"
        },
        "11_28": {
          type: "string"
        },
        "12_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_3": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_5": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_7": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_9": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_11": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_13": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_15": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_17": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_19": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_22": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_23": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_25": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_27": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_29": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_31": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_33": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "12_35": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "13_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "13_3": {
          type: "string"
        },
        "13_4": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "13_6": {
          type: "string"
        },
        "13_7": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "13_9": {
          type: "string"
        },
        "13_10": {
          type: "string"
        },
        "13_12": {
          type: "string"
        },
        "13_13": {
          type: "string"
        },
        "13_17": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "13_19": {
          type: "string"
        },
        "13_20": {
          type: "string"
        },
        "13_22": {
          type: "string"
        },
        "13_23": {
          type: "string"
        },
        "14_1": {
          type: "string",
          'enum': [
            "no - go to page 12",
            "yes - please tell us about these benefits on page 11"
          ]
        },
        "14_3": {
          type: "string",
          'enum': [
            "no - go to page 12",
            "yes - please tell us about these benefits on page 11"
          ]
        },
        "15_1": {
          type: "string"
        },
        "15_2": {
          type: "string"
        },
        "15_3": fieldDefinitionService.NINO.fullNumber(),
        "15_4": positiveNumberValidation,
        "15_5": {
          type: "string",
          'enum': [
            "weekly",
            "monthly",
            "fortnightly",
            "4 weekly"
          ]
        },
        "15_9": {
          type: "string"
        },
        "15_10": weekOfDay,
        "15_11": {
          type: "string",
          'enum': [
            "direct into a bank or building society account",
            "by cheque"
          ]
        },
        "15_13": {
          type: "string"
        },
        "15_14": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "15_16": {
          type: "number"
        },
        "15_17": {
          type: "string"
        },
        "15_18": {
          type: "string"
        },
        "15_19": {
          type: "string"
        },
        "15_20": fieldDefinitionService.NINO.fullNumber(),
        "15_21": positiveNumberValidation,
        "15_22": {
          type: "string",
          'enum': [
            "weekly",
            "monthly",
            "fortnightly",
            "4 weekly"
          ]
        },
        "15_26": {
          type: "string"
        },
        "15_27": weekOfDay,
        "15_28": {
          type: "string",
          'enum': [
            "direct into a bank or building society account",
            "by cheque"
          ]
        },
        "15_30": {
          type: "string"
        },
        "15_31": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "15_33": {
          type: "string"
        },
        "15_34": {
          type: "string"
        },
        "15_35": {
          type: "string"
        },
        "15_36": {
          type: "string"
        },
        "15_37": fieldDefinitionService.NINO.fullNumber(),
        "15_38": positiveNumberValidation,
        "15_39": {
          type: "string",
          'enum': [
            "weekly",
            "monthly",
            "fortnightly",
            "4 weekly"
          ]
        },
        "15_43": {
          type: "string"
        },
        "15_44": weekOfDay,
        "15_45": {
          type: "string",
          'enum': [
            "direct into a bank or building society account",
            "by cheque"
          ]
        },
        "15_47": {
          type: "string"
        },
        "15_48": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "15_50": {
          type: "string"
        },
        "15_51": {
          type: "string"
        },
        "16_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "16_3": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "16_5": {
          type: "string"
        },
        "16_6": {
          type: "string"
        },
        "16_7": {
          type: "string"
        },
        "16_8": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "16_10": {
          type: "string"
        },
        "16_11": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "16_13": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "16_15": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "16_17": {
          type: "string"
        },
        "16_18": {
          type: "string"
        },
        "16_19": {
          type: "string"
        },
        "16_20": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "16_22": {
          type: "string"
        },
        "16_23": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_3": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_5": {
          type: "string",
          'enum': [
            "lower rate",
            "higher rate"
          ]
        },
        "17_7": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_9": {
          type: "string",
          'enum': [
            "lowest rate",
            "middle rate",
            "highest rate"
          ]
        },
        "17_12": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_13": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "page18_1": {
          type: "string",
          'enum': [
            "no",
            "yes, what rate is paid?"
          ]
        },
        "page18_2": {
          type: "string",
          'enum': [
            "standard rate",
            "Enhanced rate"
          ]
        },
        "page18_3": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "page18_4": {
          type: "string",
          'enum': [
            "standard rate",
            "Enhanced rate"
          ]
        },
        "17_14": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_16": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_18": {
          type: "string",
          'enum': [
            "lower rate",
            "higher rate"
          ]
        },
        "17_20": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_22": {
          type: "string",
          'enum': [
            "lowest rate",
            "middle rate",
            "highest rate"
          ]
        },
        "17_25": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_26": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "page18_5": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "page18_6": {
          type: "string",
          'enum': [
            "standard rate",
            "Enhanced rate"
          ]
        },
        "page18_7": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "page18_8": {
          type: "string",
          'enum': [
            "standard rate",
            "Enhanced rate"
          ]
        },
        "17_27": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_30": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_32": {
          type: "string",
          'enum': [
            "lower rate",
            "higher rate"
          ]
        },
        "17_34": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_36": {
          type: "string",
          'enum': [
            "lowest rate",
            "middle rate",
            "highest rate"
          ]
        },
        "17_39": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "17_41": {
          type: "string"
        },
        "17_42": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "page18_9": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "page18_10": {
          type: "string",
          'enum': [
            "Standard rate",
            "Enhanced rate"
          ]
        },
        "page18_11": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "page18_12": {
          type: "string",
          'enum': [
            "lower rate",
            "higher rate"
          ]
        },
        "18_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "18_3": {
          type: "string"
        },
        "18_4": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "18_5": {
          type: "string"
        },
        "18_9": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "18_10": {
          type: "string"
        },
        "18_11": {
          type: "string"
        },
        "18_16": {
          type: "string"
        },
        "18_17": {
          type: "string"
        },
        "18_18": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "18_19": {
          type: "string"
        },
        "18_21": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "18_22": {
          type: "string"
        },
        "18_25": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "18_26": {
          type: "string"
        },
        "18_27": {
          type: "string"
        },
        "18_32": {
          type: "string"
        },
        "18_33": {
          type: "string"
        },
        "19_1": {
          type: "string",
          'enum': [
            "no",
            "yes. Tell us the last benefit you claimed below."
          ]
        },
        "19_3": {
          type: "string"
        },
        "19_4": {
          type: "string"
        },
        "19_5": {
          type: "string"
        },
        "19_6": {
          type: "string",
          'enum': [
            "no",
            "yes. Please tell us about this below."
          ]
        },
        "19_8": {
          type: "string"
        },
        "19_9": {
          type: "string"
        },
        "19_10": {
          type: "string"
        },
        "19_11": {
          type: "string",
          'enum': [
            "no",
            "yes. Please tell us about this in Part 22 Other information."
          ]
        },
        "19_13": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "19_15": {
          type: "string"
        },
        "19_16": {
          type: "string"
        },
        "19_17": {
          type: "string"
        },
        "19_18": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "19_20": {
          type: "string"
        },
        "19_21": {
          type: "string"
        },
        "19_22": {
          type: "string"
        },
        "19_23": {
          type: "string",
          'enum': [
            "yes. Please tell us about this in Part 22 Other information",
            "1"
          ]
        },
        "20_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "20_3": {
          type: "string"
        },
        "20_4": {
          type: "string"
        },
        "20_5": {
          type: "string"
        },
        "20_6": {
          type: "string"
        },
        "20_7": {
          type: "string"
        },
        "20_8": {
          type: "string"
        },
        "20_9": {
          type: "string",
          'enum': [
            "Employed by a foreign employer",
            "Employed by a UK employer",
            "Claimed UK benefit abroad",
            "self-employed",
            "claimed foreign benefit",
            "A member of HM Armed Forces"
          ]
        },
        "20_14": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "20_18": {
          type: "string"
        },
        "20_19": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "20_21": {
          type: "string"
        },
        "20_22": {
          type: "string"
        },
        "20_23": {
          type: "string"
        },
        "20_24": {
          type: "string"
        },
        "20_25": {
          type: "string"
        },
        "20_26": {
          type: "string"
        },
        "20_27": {
          type: "string"
        },
        "20_32": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "20_36": {
          type: "string"
        },
        "21_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "21_3": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "21_5": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "21_7": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "22_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "22_3": {
          type: "string"
        },
        "22_4": {
          type: "string"
        },
        "22_5": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "22_6": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "22_7": {
          type: "string"
        },
        "22_8": weekOfDay,
        "22_9": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "22_11": {
          type: "string"
        },
        "22_12": {
          type: "string"
        },
        "22_13": {
          type: "number"
        },
        "22_14": {
          type: "number"
        },
        "22_15": {
          type: "string"
        },
        "22_16": weekOfDay,
        "22_17": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "22_19": {
          type: "string"
        },
        "22_20": {
          type: "string"
        },
        "22_21": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "22_22": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "22_23": {
          type: "string"
        },
        "22_24": {
          type: "string"
        },
        "22_25": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "22_27": {
          type: "string"
        },
        "22_28": {
          type: "string"
        },
        "22_29": {
          type: "string"
        },
        "22_30": {
          type: "string"
        },
        "22_31": {
          type: "string"
        },
        "22_32": weekOfDay,
        "23_1": {
          type: "string",
          'enum': [
            "no - go to Part 10 About permanent health insurance.",
            "yes - please tell us about this below. If you have more than one pension, please tell us about them in Part 22 Other information."
          ]
        },
        "23_3": {
          type: "string",
          'enum': [
            "no - Go to Part 10 About permanent health insurance.",
            "yes - please tell us about this below. If your partner has more than one pension, please tell us about them in Part 22 Other information."
          ]
        },
        "23_5": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "personal pension"
            ]
          }
        },
        "23_6": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Occupational, work or employee's pension"
            ]
          }
        },
        "23_7": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Retirement annuity contract"
            ]
          }
        },
        "23_8": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Public service pension"
            ]
          }
        },
        "23_9": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Pension paid to you as a beneficiary"
            ]
          }
        },
        "24_1": {
          type: "string"
        },
        "24_2": fieldDefinitionService.phone.areaCode(),
        "24_3": fieldDefinitionService.phone.localNumber(),
        "24_4": {
          type: "string"
        },
        "24_5": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "24_6": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "24_7": {
          type: "string",
          'enum': [
            "weeks",
            "months",
            "years"
          ]
        },
        "27_7": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ]
        },
        "24_8": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "24_9": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "24_10": {
          type: "string"
        },
        "24_11": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "24_12": {
          type: "string"
        },
        "24_13": {
          type: "number",
          minimum: 0,
          validationMessage: positiveNumberValidationMessage
        },
        "24_14": {
          type: "string"
        },
        "24_15": {
          type: "string"
        },
        "24_16": {
          type: "string"
        },
        "24_17": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "24_19": {
          type: "string"
        },
        "24_20": positiveNumberValidation,
        "24_21": positiveNumberValidation,
        "24_22": {
          type: "string"
        },
        "24_23": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "24_25": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "24_27": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "23_10": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "personal pension"
            ]
          }
        },
        "23_11": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Occupational, work or employee's pension"
            ]
          }
        },
        "23_12": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Retirement annuity contract"
            ]
          }
        },
        "23_13": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Public service pension"
            ]
          }
        },
        "23_14": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Pension paid to you as a beneficiary"
            ]
          }
        },
        "24_29": {
          type: "string"
        },
        "24_30": fieldDefinitionService.phone.areaCode(),
        "24_31": fieldDefinitionService.phone.localNumber(),
        "24_32": {
          type: "string"
        },
        "24_33": positiveNumberValidation,
        "24_34": positiveNumberValidation,
        "24_35": {
          type: "string"
        },
        "24_36": positiveNumberValidation,
        "24_37": positiveNumberValidation,
        "24_38": {
          type: "string"
        },
        "24_39": positiveNumberValidation,
        "24_40": {
          type: "string"
        },
        "24_41": positiveNumberValidation,
        "24_42": {
          type: "string"
        },
        "24_43": {
          type: "string"
        },
        "24_44": {
          type: "string"
        },
        "24_45": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "24_47": {
          type: "string"
        },
        "24_48": positiveNumberValidation,
        "24_49": positiveNumberValidation,
        "24_50": {
          type: "string"
        },
        "24_51": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "24_53": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "24_55": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "25_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "25_5": {
          type: "string",
          'enum': [
            "no, go to part 11 education, training and apprenticeship",
            "yes"
          ]
        },
        "25_9": {
          type: "string"
        },
        "25_10": fieldDefinitionService.phone.areaCode(),
        "25_11": fieldDefinitionService.phone.localNumber(),
        "25_15": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "25_17": {
          type: "string"
        },
        "25_21": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "25_3": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "25_7": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "25_12": {
          type: "string"
        },
        "25_13": fieldDefinitionService.phone.areaCode(),
        "25_14": fieldDefinitionService.phone.localNumber(),
        "25_18": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "25_20": {
          type: "string"
        },
        "25_25": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "26_1": {
          type: "string",
          'enum': [
            "no, go to part 12 where you live",
            "yes, please tell us about this below"
          ]
        },
        "26_3": {
          type: "string",
          'enum': [
            "education",
            "training",
            "apprenticeship"
          ]
        },
        "26_6": {
          type: "string"
        },
        "26_7": {
          type: "string"
        },
        "26_8": positiveNumberValidation,
        "26_9": {
          type: "string"
        },
        "26_10": {
          type: "string"
        },
        "26_11": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "26_13": {
          type: "string"
        },
        "26_14": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "26_16": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "26_18": {
          type: "string"
        },
        "26_19": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "26_21": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Education"
            ]
          }
        },
        "26_22": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Training"
            ]
          }
        },
        "26_23": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "Apprenticeship"
            ]
          }
        },
        "26_24": {
          type: "string"
        },
        "26_25": {
          type: "string"
        },
        "26_26": positiveNumberValidation,
        "26_27": {
          type: "string"
        },
        "26_28": {
          type: "string"
        },
        "26_29": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "26_31": {
          type: "string"
        },
        "26_32": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "26_34": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "26_36": {
          type: "string"
        },
        "27_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "27_3": {
          type: "string"
        },
        "27_4": {
          type: "string"
        },
        "27_5": {
          type: "string"
        },
        "27_6": {
          type: "string"
        },
        "27_9": {
          type: "string"
        },
        "27_19": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ]
        },
        "27_21": {
          type: "string"
        },
        "27_25": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ]
        },
        "27_27": {
          type: "string"
        },
        "27_10": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ]
        },
        "27_12": {
          type: "string"
        },
        "27_13": {
          type: "string"
        },
        "27_14": {
          type: "string"
        },
        "27_15": {
          type: "string"
        },
        "27_16": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ]
        },
        "27_18": {
          type: "string"
        },
        "27_22": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ]
        },
        "27_24": {
          type: "string"
        },
        "27_28": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ]
        },
        "27_30": {
          type: "string"
        },
        "28_1": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ]
        },
        "28_3": positiveNumberValidation,
        "28_4": positiveNumberValidation,
        "28_5": {
          type: "string"
        },
        "28_11": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ]
        },
        "28_15": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ]
        },
        "28_23": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "28_25": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "28_6": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "28_8": positiveNumberValidation,
        "28_9": positiveNumberValidation,
        "28_10": {
          type: "string"
        },
        "28_13": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "28_17": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "28_27": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "28_29": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "30_1": {
          type: "string",
          'enum': [
            "No",
            "Yes"
          ]
        },
        "30_3": {
          type: "string"
        },
        "30_4": {
          type: "string"
        },
        "30_5": {
          type: "string"
        },
        "30_6": {
          type: "string",
          'enum': [
            "male",
            "female"
          ]
        },
        "30_8": {
          type: "string"
        },
        "30_9": {
          type: "string"
        },
        "30_10": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "30_12": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "30_14": {
          type: "string"
        },
        "30_15": {
          type: "string"
        },
        "30_16": {
          type: "string"
        },
        "30_17": {
          type: "string",
          'enum': [
            "male",
            "female"
          ]
        },
        "30_19": {
          type: "string"
        },
        "30_20": {
          type: "string"
        },
        "30_21": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "30_23": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_1": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_3": positiveNumberValidation,
        "31_4": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_6": positiveNumberValidation,
        "31_7": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_9": positiveNumberValidation,
        "31_10": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_12": positiveNumberValidation,
        "31_13": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_15": positiveNumberValidation,
        "31_16": positiveNumberValidation,
        "31_17": positiveNumberValidation,
        "31_18": positiveNumberValidation,
        "31_19": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_21": positiveNumberValidation,
        "31_22": positiveNumberValidation,
        "31_23": positiveNumberValidation,
        "31_24": positiveNumberValidation,
        "31_25": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_27": positiveNumberValidation,
        "31_28": positiveNumberValidation,
        "31_29": {
          type: "string"
        },
        "31_30": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_32": positiveNumberValidation,
        "31_33": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_35": positiveNumberValidation,
        "31_36": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_38": positiveNumberValidation,
        "31_39": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_41": positiveNumberValidation,
        "31_42": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_44": positiveNumberValidation,
        "31_45": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_47": positiveNumberValidation,
        "31_48": {
          type: "string"
        },
        "31_49": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_51": positiveNumberValidation,
        "31_52": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "31_54": positiveNumberValidation,
        "32_1": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "32_3": positiveNumberValidation,
        "32_4": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "32_6": positiveNumberValidation,
        "32_7": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "32_9": positiveNumberValidation,
        "32_10": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "32_12": positiveNumberValidation,
        "32_13": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "32_15": positiveNumberValidation,
        "32_16": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "32_18": positiveNumberValidation,
        "32_19": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "32_21": positiveNumberValidation,
        "32_22": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "32_24": positiveNumberValidation,
        "32_25": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "32_27": positiveNumberValidation,
        "32_28": positiveNumberValidation,
        "32_29": {
          type: "string"
        },
        "32_30": positiveNumberValidation,
        "32_31": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "32_33": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "33_1": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "33_3": {
          type: "string",
          'enum': [
            "If yes, please send us proof of your savings with this form. For example, a recent bank statement or a savings book updated within the last month.",
            "no"
          ]
        },
        "33_5": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "33_7": {
          type: "string",
          'enum': [
            "property",
            "land"
          ]
        },
        "33_9": {
          type: "string"
        },
        "33_10": {
          type: "string"
        },
        "33_11": {
          type: "string"
        },
        "33_12": {
          type: "string"
        },
        "33_13": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "33_15": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "34_1": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "34_3": {
          type: "string"
        },
        "34_4": {
          type: "string"
        },
        "34_5": positiveNumberValidation,
        "34_6": positiveNumberValidation,
        "34_7": {
          type: "string"
        },
        "34_8": weekOfDay,
        "34_9": {
          type: "string"
        },
        "34_10": {
          type: "string"
        },
        "34_11": positiveNumberValidation,
        "34_12": positiveNumberValidation,
        "34_13": {
          type: "string"
        },
        "34_14": weekOfDay,
        "35_1": {
          type: "string",
          'enum': [
            "yes, please tell us about this below.",
            "no"
          ]
        },
        "35_3": {
          type: "string"
        },
        "35_4": positiveNumberValidation,
        "35_5": {
          type: "string"
        },
        "35_6": {
          type: "string"
        },
        "35_7": {
          type: "string"
        },
        "35_8": positiveNumberValidation,
        "35_9": {
          type: "string"
        },
        "35_10": {
          type: "string"
        },
        "35_11": {
          type: "string",
          'enum': [
            "yes, please tell us about this below.",
            "no"
          ]
        },
        "35_13": {
          type: "string"
        },
        "35_14": {
          type: "string"
        },
        "35_15": {
          type: "string"
        },
        "35_16": positiveNumberValidation,
        "35_17": positiveNumberValidation,
        "35_18": {
          type: "string"
        },
        "35_19": weekOfDay,
        "35_20": {
          type: "string"
        },
        "35_21": {
          type: "string"
        },
        "35_22": {
          type: "string"
        },
        "35_23": positiveNumberValidation,
        "35_24": positiveNumberValidation,
        "35_25": {
          type: "string"
        },
        "35_26": weekOfDay,
        "36_1": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "36_3": {
          type: "string"
        },
        "36_4": {
          type: "string"
        },
        "36_5": {
          type: "string",
          'enum': [
            "direct to the supplier",
            "to you or your partner",
            "to the credit company"
          ]
        },
        "36_8": positiveNumberValidation,
        "36_9": positiveNumberValidation,
        "36_10": {
          type: "string"
        },
        "36_11": {
          type: "string"
        },
        "36_12": {
          type: "string"
        },
        "36_13": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "36_15": {
          type: "string",
          'enum': [
            "no",
            "If yes, please tell us about them in Part 22 Other information."
          ]
        },
        "37_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "37_3": {
          type: "string"
        },
        "37_4": {
          type: "string"
        },
        "37_5": positiveNumberValidation,
        "37_6": positiveNumberValidation,
        "37_7": {
          type: "string"
        },
        "37_8": weekOfDay,
        "37_9": {
          type: "string",
          'enum': [
            "heating",
            "meals"
          ]
        },
        "37_11": {
          type: "string"
        },
        "37_12": {
          type: "string"
        },
        "37_13": positiveNumberValidation,
        "37_14": positiveNumberValidation,
        "37_15": {
          type: "string"
        },
        "37_16": weekOfDay,
        "37_17": {
          type: "string",
          'enum': [
            "Heating",
            "Meals"
          ]
        },
        "38_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "38_3": {
          type: "string"
        },
        "38_4": {
          type: "string"
        },
        "38_5": {
          type: "string"
        },
        "38_6": {
          type: "string"
        },
        "38_7": {
          type: "string"
        },
        "38_8": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "38_10": {
          type: "string"
        },
        "38_11": {
          type: "string"
        },
        "38_12": {
          type: "string"
        },
        "38_13": {
          type: "string"
        },
        "38_14": {
          type: "string"
        },
        "38_15": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "38_17": {
          type: "string"
        },
        "38_18": {
          type: "string"
        },
        "38_19": {
          type: "string"
        },
        "38_20": {
          type: "string"
        },
        "38_21": {
          type: "string"
        },
        "38_22": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "38_24": {
          type: "string"
        },
        "38_25": {
          type: "string"
        },
        "38_26": {
          type: "string"
        },
        "38_27": {
          type: "string"
        },
        "38_28": {
          type: "string"
        },
        "38_29": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "39_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "39_3": {
          type: "string"
        },
        "39_4": positiveNumberValidation,
        "39_5": positiveNumberValidation,
        "39_6": {
          type: "string"
        },
        "39_7": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "39_8": {
          type: "string"
        },
        "39_9": {
          type: "string"
        },
        "39_10": {
          type: "string"
        },
        "39_11": {
          type: "string"
        },
        "39_12": {
          type: "string"
        },
        "page39_13": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "39_14": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "39_16": {
          type: "string"
        },
        "39_17": positiveNumberValidation,
        "39_18": positiveNumberValidation,
        "39_19": {
          type: "string"
        },
        "39_20": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "39_22": {
          type: "string"
        },
        "39_23": {
          type: "string"
        },
        "39_24": {
          type: "string"
        },
        "39_25": {
          type: "string"
        },
        "39_26": {
          type: "string"
        },
        "page39_27": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "40_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "40_3": {
          type: "string"
        },
        "40_4": positiveNumberValidation,
        "40_5": positiveNumberValidation,
        "40_6": {
          type: "string"
        },
        "40_7": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "40_8": {
          type: "string"
        },
        "40_9": {
          type: "string"
        },
        "40_10": {
          type: "string"
        },
        "40_11": {
          type: "string"
        },
        "40_12": {
          type: "string"
        },
        "page40_13": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "40_14": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "40_16": {
          type: "string"
        },
        "40_17": positiveNumberValidation,
        "40_18": positiveNumberValidation,
        "40_19": {
          type: "string"
        },
        "40_20": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "40_22": {
          type: "string"
        },
        "40_23": {
          type: "string"
        },
        "40_24": {
          type: "string"
        },
        "40_25": {
          type: "string"
        },
        "40_26": {
          type: "string"
        },
        "page40_27": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "40_15": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "page40_16": {
          type: "string"
        },
        "40_30": {
          type: "string"
        },
        "page40_17": {
          type: "string"
        },
        "page40_32": {
          type: "string"
        },
        "41_1": {
          type: "string",
          'enum': [
            "no, go to Part 18 Living in a care home.",
            "yes, make sure you fill in form HCTB1 to claim Council Tax Benefit and send it to the council."
          ]
        },
        "41_3": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "41_5": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "yours"
            ]
          }
        },
        "41_6": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "your partner's"
            ]
          }
        },
        "41_7": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "both"
            ]
          }
        },
        "41_10": {
          type: "string"
        },
        "41_11": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "41_13": {
          type: "string"
        },
        "41_14": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "41_16": {
          type: "string",
          'enum': [
            "yours",
            "your partner's",
            "both"
          ]
        },
        "41_21": {
          type: "string"
        },
        "41_22": {
          type: "string",
          'enum': [
            "no",
            "If yes, please tell us what it is for."
          ]
        },
        "41_24": {
          type: "string"
        },
        "41_25": {
          type: "string",
          'enum': [
            "no",
            "Yes"
          ]
        },
        "42_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "42_3": {
          type: "string"
        },
        "42_4": {
          type: "string"
        },
        "42_5": {
          type: "string"
        },
        "42_6": {
          type: "string",
          'enum': [
            "current address",
            "previous address"
          ]
        },
        "42_8": {
          type: "string"
        },
        "42_9": {
          type: "string"
        },
        "42_10": {
          type: "string"
        },
        "42_11": {
          type: "string",
          'enum': [
            "current address",
            "previous address"
          ]
        },
        "42_13": {
          type: "string"
        },
        "42_14": {
          type: "string"
        },
        "42_15": {
          type: "string"
        },
        "42_16": {
          type: "string",
          'enum': [
            "current address",
            "previous address"
          ]
        },
        "42_18": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "42_20": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "42_22": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "42_24": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "42_26": positiveNumberValidation,
        "42_27": positiveNumberValidation,
        "42_28": {
          type: "string"
        },
        "42_29": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "42_31": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "42_33": positiveNumberValidation,
        "43_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "43_5": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "43_9": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "43_13": {
          type: "string"
        },
        "43_15": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "43_18": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "43_3": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "43_7": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "43_11": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "43_14": {
          type: "string"
        },
        "43_19": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "43_20": {
          type: "string",
          'enum': [
            "you",
            "your partner",
            "both of you"
          ]
        },
        "43_23": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "43_25": {
          type: "string"
        },
        "43_26": positiveNumberValidation,
        "43_27": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "44_1": {
          type: "string",
          'enum': [
            "no, go to page 42",
            "yes, please tell us about this below."
          ]
        },
        "44_3": {
          type: "string"
        },
        "44_4": {
          type: "string"
        },
        "44_5": {
          type: "string"
        },
        "44_6": {
          type: "string"
        },
        "44_7": fieldDefinitionService.NINO.fullNumber(),
        "44_8": {
          type: "string"
        },
        "44_9": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "44_11": {
          type: "string"
        },
        "44_12": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "44_14": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "44_16": {
          type: "string",
          'enum': [
            "no, go to page 42",
            "yes, please tell us about this below."
          ]
        },
        "44_18": {
          type: "string"
        },
        "44_19": {
          type: "string"
        },
        "44_20": {
          type: "string"
        },
        "44_21": {
          type: "string"
        },
        "44_22": fieldDefinitionService.NINO.fullNumber(),
        "44_23": {
          type: "string"
        },
        "44_24": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "44_26": {
          type: "string"
        },
        "44_27": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "44_29": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "45_1": {
          type: "string",
          'enum': [
            "no",
            "yes"
          ]
        },
        "45_3": {
          type: "string"
        },
        "45_4": positiveNumberValidation,
        "45_5": {
          type: "string"
        },
        "45_6": {
          type: "string"
        },
        "45_7": {
          type: "string"
        },
        "45_8": positiveNumberValidation,
        "45_9": {
          type: "string"
        },
        "45_10": {
          type: "string"
        },
        "45_11": {
          type: "string"
        },
        "45_12": positiveNumberValidation,
        "45_13": {
          type: "string"
        },
        "45_14": {
          type: "string"
        },
        "45_15": {
          type: "string"
        },
        "45_16": positiveNumberValidation,
        "45_17": {
          type: "string"
        },
        "45_18": {
          type: "string"
        },
        "45_19": {
          type: "string",
          'enum': [
            "to you",
            "direct to your lender"
          ]
        },
        "45_21": {
          type: "string",
          'enum': [
            "yes, please tell us about the last payment you received.",
            "no"
          ]
        },
        "45_23": {
          type: "string"
        },
        "45_24": positiveNumberValidation,
        "45_25": {
          type: "string"
        },
        "46_1": {
          type: "string",
          'enum': [
            "Yes",
            "No"
          ]
        },
        "46_3": {
          type: "string",
          'enum': [
            "yes, please tell us about this below.",
            "no"
          ]
        },
        "46_5": {
          type: "string"
        },
        "46_6": {
          type: "string"
        },
        "46_7": {
          type: "string"
        },
        "46_10": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "46_12": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "46_15": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "47_1": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "47_3": {
          type: "string"
        },
        "47_4": {
          type: "string",
          'enum': [
            "yes",
            "No"
          ]
        },
        "47_6": {
          type: "string"
        },
        "47_7": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "47_9": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "47_11": {
          type: "string"
        },
        "47_12": {
          type: "string"
        },
        "47_13": {
          type: "string"
        },
        "47_14": {
          type: "string"
        },
        "47_15": {
          type: "string"
        },
        "48-1": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "48-5": {
          type: "string",
          'enum': [
            "yes, send us proof of the asylum application with this form. Or you can bring the proof to your local Jobcentre Plus.",
            "no, if you are still an asylum seeker, you will not usually be entitled to benefit. But you may be entitled to get help from the Home Office"
          ]
        },
        "48-9": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "47--11": {
          type: "string"
        },
        "48--9": {
          type: "string",
          'enum': [
            " No, please go to Part 20",
            "yes, send us details of any support given to you by the Home Office. For example, a leter from the Home Office which tells us about these things"
          ]
        },
        "48-3": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "48-7": {
          type: "string",
          'enum': [
            "yes, send us proof of the asylum application with this form. Or you can bring the proof to your local Jobcentre Plus",
            "no, if you are still an asylum seeker, you will not usually be entitled to benefit. But you may be entitled to get help from the Home Office."
          ]
        },
        "48--11": {
          type: "string",
          'enum': [
            "yes, send us proof of the decision with this form. Or you can bring the proof to your local Jobcentre Plus",
            "no"
          ]
        },
        "47--13": {
          type: "string"
        },
        "48-11": {
          type: "string",
          'enum': [
            "no, please go to Part 20",
            "Yes, send us details of any support given to you by the Home Office. For example, a letter from the Home Office which tells us about these things"
          ]
        },
        "50_1": {
          type: "string"
        },
        "50_2": {
          type: "string"
        },
        "50_3": {
          type: "number"
        },
        "50_4": {
          type: "number"
        },
        "50_5": {
          type: "number"
        },
        "50_6": {
          type: "string"
        },
        "50_7": {
          type: "string"
        },
        "50_8": {
          type: "array",
          items: {
            type: "string",
            'enum': [
              "1"
            ]
          }
        },
        "51_2": {
          type: "string"
        },
        "52_1": {
          type: "string",
          'enum': [
            "yes, please tell us about yourself below.",
            "no, please go to Part 23Declaration"
          ]
        },
        "52_3": {
          type: "string"
        },
        "52_4": {
          type: "string"
        },
        "52_5": {
          type: "string"
        },
        "52_6": {
          type: "string"
        },
        "52_7": {
          type: "string"
        },
        "52_8": {
          type: "string"
        },
        "52_9": fieldDefinitionService.NINO.fullNumber(),
        "52_10": {
          type: "string"
        },
        "52_11": {
          type: "string"
        },
        "52_13": {
          type: "string"
        },
        "52_15": {
          type: "string",
          'enum': [
            "work",
            "home",
            "mobile"
          ]
        },
        "53-1": {
          type: "string",
          'enum': [
            "yes",
            "no"
          ]
        },
        "53-3": {
          type: "boolean"
        },
        "53-4": {
          type: "boolean"
        },
        "53-5": {
          type: "boolean"
        },
        "53-6": {
          type: "boolean"
        },
        "53-7": {
          type: "boolean"
        },
        "53-8": {
          type: "string"
        },
        Signature: {
          type: "string"
        },
        "54_2": {
          type: "string"
        },
        "54_3": {
          type: "boolean"
        },
        "55_1": {
          type: "boolean",
          title: "Any passport or immigration documents we have asked for."
        },
        "55_3": {
          type: "boolean",
          title: "Any proof we have asked for about an asylum application."
        },
        "55_5": {
          type: "boolean",
          title: "Proof of savings over £5,500 including any share certificates."
        },
        "55_7": {
          type: "boolean",
          title: "Proof of savings over £9,500, if you or your partner live in a care home."
        },
        "55_9": {
          type: "boolean",
          title: "Proof of any pension income you have told us about."
        },
        "55_11": {
          type: "boolean",
          title: "Proof of any payments from a credit insurance policy."
        },
        "55_13": {
          type: "boolean",
          title: "Any proof we have asked for about any other money coming in."
        },
        "55_15": {
          type: "boolean",
          title: "Medical statements."
        },
        "55_17": {
          type: "boolean",
          title: "Form <b>SSP1.</b>"
        },
        "55_19": {
          type: "boolean",
          title: "<b>DS1500 Report</b>."
        },
        "55_21": {
          type: "boolean",
          title: "The last 5 weekly payslips or last 2 monthly payslips, if you or your partner are still working."
        },
        "55_23": {
          type: "boolean",
          title: "Form <b>P45.</b>"
        },
        "55_25": {
          type: "boolean",
          title: "Discharge papers if you have just left HM Forces."
        },
        "55_2": {
          type: "boolean",
          title: "Any passport or immigration documents we have asked for."
        },
        "55_4": {
          type: "boolean",
          title: "Any proof we have asked for about an asylum application."
        },
        "55_6": {
          type: "boolean",
          title: "Proof of savings over £5,500 including any share certificates."
        },
        "55_8": {
          type: "boolean",
          title: "Proof of savings over £9,500, if you or your partner live in a care home."
        },
        "55_10": {
          type: "boolean",
          title: "Proof of any pension income you have told us about."
        },
        "55_12": {
          type: "boolean",
          title: "Proof of any payments from a credit insurance policy."
        },
        "55_14": {
          type: "boolean",
          title: "Any proof we have asked for about any other money coming in."
        },
        "55_16": {
          type: "boolean",
          title: "Medical statements."
        },
        "55_18": {
          type: "boolean",
          title: "Form <b>SSP1.</b>"
        },
        "55_20": {
          type: "boolean",
          title: "<b>DS1500 Report</b>."
        },
        "55_22": {
          type: "boolean",
          title: "The last 5 weekly payslips or last 2 monthly payslips, if you or your partner are still working."
        },
        "55_24": {
          type: "boolean",
          title: "Form <b>P45.</b>"
        },
        "55_26": {
          type: "boolean",
          title: "Discharge papers if you have just left HM Forces."
        }
      },

      required: [
        '4_1',
        '4_3',
        '4_6',
        '5_1',
        '5_2',
        '5_4',
        '6_1',
        '6_4',
        '6_6',
        '6_7',
        '6_8',
        '6_15',
        '6_16',
        '6_19',
        '6_21',
        '6_22',
        '6_25',
        '6_30',
        '7_4a',
        '7_11',
        '7_20a'
      ]
    };

    return {
      getSchema: function () {
        return schema;
      }
    };
  }]);
